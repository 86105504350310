import { ActionTree } from 'vuex'
import { ReportsState, reportsMutationTypes } from '@/store/modules/reports/reportsTypes'
import { ModuleNamespaces, RootState } from '@/store/types/storeGlobalTypes'
import { ReportsService } from '@/services/ReportsService'
import { i18n } from '@/plugins/vue-i18n'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { adaptReports, adaptReportsList } from '@/store/modules/reports/reportsAdapters'
import { listItemsMutationTypes } from '@/store/modules/listItems/listItemsTypes'
import { MainService } from '@/services/MainService'

const spinnerModule = ModuleNamespaces.SPINNER
const alertModule = ModuleNamespaces.ALERTS

export const actions: ActionTree<ReportsState, RootState> = {
  async fetchListReports({ commit }) {
    try {
      const url = 'report'
      const { data } = await new ReportsService().getData(url)
      commit(reportsMutationTypes.FETCH_REPORTS, data)
    } catch (error) {}
  },
  async fetchReport({ commit, dispatch }, id: any) {
    try {
      const url = `report/${id}`
      const { data } = await new ReportsService().getData(url)
      commit(reportsMutationTypes.FETCH_REPORT, data)
    } catch (error) {
    } finally {
      await dispatch(`${spinnerModule}/hideSpinnerLayer`, null, { root: true })
    }
  },
  async fetchReportData({ commit, dispatch }, { id, filters }: { id: string; filters: object }) {
    try {
      const url = `report/${id}/data`
      const { data } = await new MainService().postData(url, filters)
      commit(reportsMutationTypes.FETCH_REPORT_DATA, data)
    } catch (error) {
      await dispatch(`${spinnerModule}/hideSpinner`, null, { root: true })
    }
  },
  async postReports({ commit, dispatch }, report: any) {
    report.name = report.reportName
    delete report.sourceData
    try {
      const url = `report`
      const { data } = await new MainService().postData(url, report)
      commit(reportsMutationTypes.POST_REPORT, data)
      await dispatch(
        `${alertModule}/showAlert`,
        {
          type: AlertsTypes.SUCCESS,
          message: i18n.t('components.alerts.report_save_success'),
          componentWhereIsRendered: ComponentWhereIsRendered.MAIN
        },
        { root: true }
      )
      return
    } catch (error) {
      await dispatch(`${spinnerModule}/hideSpinner`, null, { root: true })
      await dispatch(
        `${alertModule}/showAlert`,
        {
          type: AlertsTypes.ERROR,
          message: i18n.t('components.alerts.report_save_error'),
          componentWhereIsRendered: ComponentWhereIsRendered.MAIN
        },
        { root: true }
      )
    }
  },
  async deleteReport({ commit, dispatch }, id: any) {
    try {
      await dispatch(`${spinnerModule}/showSpinner`, null, { root: true })
      const url = `report/${id}`
      const { data } = await new ReportsService().deleteData(url)
      commit(reportsMutationTypes.DELETE_REPORT, data)
      await dispatch(`${spinnerModule}/hideSpinner`, null, { root: true })
    } catch (error) {
      await dispatch(`${spinnerModule}/hideSpinner`, null, { root: true })
    }
  },
  async fetchDashboardReports({ commit, dispatch }) {
    try {
      const url = 'reports/dashboard'
      const { data } = await new ReportsService().getData(url)
      commit(reportsMutationTypes.FETCH_DASHBOARD_REPORTS, data)
      await dispatch(`${spinnerModule}/hideSpinner`, null, { root: true })
    } catch (error) {
      await dispatch(`${spinnerModule}/hideSpinner`, null, { root: true })
    }
  },
  async fetchTotalNumberOfReports({ commit }) {
    try {
      const url = '/reports/total/dashboard'
      const { data } = await new ReportsService().getData(url)
      commit(reportsMutationTypes.FETCH_TOTAL_NUMBER_OF_REPORTS, data[0]['Total'])
    } catch (error) {}
  },
  async resetReports({ commit }) {
    commit(reportsMutationTypes.RESET_REPORT)
  },
  // NEW REPORTS LIST ENDPOINTS
  async fetchReportsList({ commit }) {
    try {
      const url = 'v2/report'
      const { data } = await new ReportsService().getData(url)
      adaptReportsList(data)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) {
      console.error('Error fetching or validating report list:', error)
    }
  },
  async fetchReportsListById({ commit }, id: string) {
    try {
      const { data } = await new ReportsService().getData(`v2/report/${id}`)
      adaptReports(data)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, [data], { root: true })
      commit(reportsMutationTypes.SET_SELECTED_REPORT_LIST, data)
    } catch (error) {
      console.error('Error fetching or validating report list:', error)
    }
  },
  async fetchOneReportInfo({ commit }, filters: any) {
    try {
      const jsonString = JSON.stringify(filters)
      const encodedJsonString = encodeURIComponent(jsonString)
      const url = `v2/report/data?filters=${encodedJsonString}`
      const { data } = await new ReportsService().getData(url)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) {}
  }
}
