<template>
  <section class="report-filter-container">
    <header class="report-filter-header">
      <LexonBreadcrumbsComponent :items="breadcrumbs" />
      <LfHeaderTitleComponent
        :title="props.title"
        class="header-title"
        :buttons="buttons"
        :executeAction="onCloseForm"
      />
    </header>
    <div class="filters-header">
      <span class="filters-text"> {{ text.filtersText }} </span>
      <div class="buttons-container">
        <button class="clear-button" :disabled="checkIfDisabledButton" @click="clearForm">
          {{ text.clearFilter }}
        </button>
        <LfLabelButtonComponent
          class="action-button"
          :text="text.applyFilters"
          :action="() => applyFilters()"
          :disabled="checkIfDisabledButton"
          :variant="LabelButtonVariant.OUTLINED"
        />
      </div>
    </div>
    <FormGeneratorComponent
      :key="componentKey"
      class="filters-form"
      :schema="formSchema"
      :fieldValues="formData"
      :context="context"
      @isValidForm="checkValidForm"
    />
  </section>
</template>

<script lang="ts" setup>
import { computed, ref, defineProps, onBeforeUnmount, PropType } from 'vue'
import store from '@/store/store'
import { Icons } from '@/icons/icons'
import { LabelButtonVariant } from '@/components/forms/buttons/types/ButtonTypes'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import useRouter from '@/composables/useRouter'
import { useI18n } from 'vue-i18n-composable'
import FormGeneratorComponent from '@/components/forms/FormGenerator/FormGeneratorComponent.vue'
import LexonBreadcrumbsComponent from '@/components/forms/fields/LexonBreadcrumbs/LexonBreadcrumbsComponent.vue'
import LfHeaderTitleComponent from '@/components/HeaderTitle/LfHeaderTitleComponent.vue'
import LfLabelButtonComponent from '@/components/forms/buttons/label/LfLabelButtonComponent.vue'
import useUser from '@/composables/useUser'
import { TIME_REPORT_WIDGET } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'
import { actualDate } from '@/helpers/dateTime'
import useFormData from '@/composables/useFormData'

interface Breadcrumb {
  text: string
  url: string
}

// PROPS
const props = defineProps({
  filters: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  comeFromDashboard: {
    type: Boolean
  },
  breadcrumbs: {
    type: Array as PropType<Breadcrumb[]>,
    required: true
  },
  goBackRouteName: {
    type: String,
    required: true
  }
})

const { goToRoute } = useRouter()
const { userId } = useUser()

// TRANSLATIONS
const { t } = useI18n()
const text = {
  clearFilter: t('components.report_filter.clear_filters'),
  applyFilters: t('components.report_filter.apply_filters'),
  filtersText: t('components.report_filter.filters_text'),
  closeBtn: t('action_buttons.close')
}

// DATA
const formData = ref<Record<string, unknown>>({})

const buttons = [
  {
    icon: Icons.CLOSE,
    tooltip: text.closeBtn,
    class: 'red-color',
    action: ActionName.CLOSE,
    enabled: true
  }
]

const formIsValid = ref(true)
const componentKey = ref(0)

// COMPUTED
const context = computed<string>(() => ContextName.REPORT_DETAIL)
const formSchema = computed(() => JSON.parse(props.filters))
const checkIfDisabledButton = computed(() => !formIsValid.value)

const { isValidFormData } = useFormData()

// EVENTS
const emit = defineEmits(['filter', 'clear-table'])

// HOOKS
onBeforeUnmount(async () => {
  await store.dispatch(`${ModuleNamespaces.DASHBOARD_WIDGETS}/toggleComeFromDashboardWidgetFlag`, false)
})

// METHODS
function onCloseForm() {
  goToRoute({
    name: props.goBackRouteName
  })
}

function checkValidForm(isValidFlag: boolean) {
  formIsValid.value = isValidFlag
}

function clearForm() {
  Object.keys(formData.value).forEach((key) => {
    formData.value[key] = ''
  })
  componentKey.value++
  formIsValid.value = isValidFormData(ContextName.REPORT_DETAIL).value
  emit('clear-table')
}

function applyFilters() {
  const filter: any = formData.value
  if (filter?.idUser && !Array.isArray(filter.idUser)) {
    filter.idUser = [filter.idUser]
  }
  emit('filter', filter)
}

async function init() {
  if (!props.comeFromDashboard) {
    return
  }
  const dmyToday = actualDate('DD-MM-YYYY')
  const ymdToday = actualDate('YYYY-MM-DD')
  formData.value = {
    endDate: dmyToday,
    startDate: dmyToday,
    idUser: userId.value
  }
  await store.dispatch(`${ModuleNamespaces.REPORTS}/fetchOneReportInfo`, {
    alias: TIME_REPORT_WIDGET.REPORT_ALIAS,
    endDate: ymdToday,
    startDate: ymdToday,
    idUser: [userId.value]
  })
}

// INIT
init()
</script>

<style lang="scss" scoped>
.report-filter-container {
  margin: 0 0 20px 0;

  .report-filter-header {
    @include flex($justify-content: space-between, $flex-direction: column);
    padding: 0 0 10px 0;
  }

  .filters-header {
    @include flex($justify-content: space-between);
    padding: 20px 20px 0 20px;
    background-color: $neutral-grey-100;
  }

  .filters-form {
    background-color: $neutral-grey-100;
    padding: 16px 20px 26px 20px;
    margin: 0;
  }

  .buttons-container {
    @include flex;

    .clear-button {
      @include milano-bold-16;
      color: $neutral-grey-500;
      margin-right: $spacing-lg;
    }
  }

  .filters-text {
    @include milano-bold-16;
    overflow: hidden;
    color: $neutral-grey-600;
    text-overflow: ellipsis;
  }

  ::v-deep .breadcrumb {
    &.to-lower-case {
      .breadcrumb-link:first-of-type {
        color: $corporate-color;
        margin-left: 4px;
      }

      .separator {
        display: block;
      }
    }

    .separator {
      display: none;
    }
  }

  ::v-deep .e-input-group,
  ::v-deep .v-input {
    background-color: $white-01;
  }
}
</style>
