import BillingDetailView from '@/views/billing/Billing/BillingDetailView.vue'
import BillingView from '@/views/billing/Billing/BillingView.vue'
import CustomerBankdraftsView from '@/views/billing/CustomerBankdrafts/CustomerBankdraftsView.vue'
import CustomerInvoicesView from '@/views/billing/CustomerInvoices/CustomerInvoicesView.vue'
import OrderFormsView from '@/views/billing/OrderForms/OrderFormsView.vue'
import ProformaInvoicesView from '@/views/billing/ProformaInvoices/ProformaInvoicesView.vue'
import ProviderBankdraftsView from '@/views/billing/ProviderBankdrafts/ProviderBankdraftsView.vue'
import ProviderInvoicesView from '@/views/billing/ProviderInvoices/ProviderInvoicesView.vue'
import ProvisionsView from '@/views/billing/Provisions/ProvisionsView.vue'
import { URLS } from '@/router/routes/urlRoutes'
import { ContextName } from '@/store/types/storeGlobalTypes'
import { ConfigurationAlias, ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { InvoiceEntityId } from '@/general/entityIds'

export const billlingRoutes = [
  {
    path: `/${URLS.BILLING}`,
    name: URLS.BILLING,
    component: BillingView,
    meta: {
      title: URLS.BILLING,
      requiresAuth: true
    },
    children: [
      {
        path: `${URLS.CUSTOMER_BAKDRAFTS}`,
        name: URLS.CUSTOMER_BAKDRAFTS,
        component: CustomerBankdraftsView,
        meta: {
          title: URLS.CUSTOMER_BAKDRAFTS,
          parentUrl: URLS.BILLING,
          entityType: InvoiceEntityId.CUSTOMER_BAKDRAFTS,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.CUSTOMER_BAKDRAFTS}/:selectedRegisterId`,
        name: `${URLS.BILLING}-${URLS.CUSTOMER_BAKDRAFTS}`,
        component: BillingDetailView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.BILLING,
          submenuName: URLS.CUSTOMER_BAKDRAFTS,
          context: ContextName.BILLING_CUSTOMER_BANKDRAFTS,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.BILLING_CUSTOMER_BANKDRAFTS
          },
          entityType: entity.invoices.customerBankdraft.type,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.BILLING,
          title: URLS.CUSTOMER_BAKDRAFTS,
          requiresAuth: true,
          entityType: InvoiceEntityId.CUSTOMER_BAKDRAFTS
        }
      },
      {
        path: `${URLS.CUSTOMER_INVOICES}`,
        name: URLS.CUSTOMER_INVOICES,
        component: CustomerInvoicesView,
        meta: {
          title: URLS.CUSTOMER_INVOICES,
          parentUrl: URLS.BILLING,
          requiresAuth: true,
          entityType: InvoiceEntityId.CUSTOMER_INVOICES
        }
      },
      {
        path: `${URLS.CUSTOMER_INVOICES}/:selectedRegisterId`,
        name: `${URLS.BILLING}-${URLS.CUSTOMER_INVOICES}`,
        component: BillingDetailView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.BILLING,
          submenuName: URLS.CUSTOMER_INVOICES,
          context: ContextName.BILLING_CUSTOMER_INVOICES,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.BILLING_CUSTOMER_INVOICES
          },
          entityType: entity.invoices.customers.type,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.BILLING,
          title: URLS.CUSTOMER_INVOICES,
          requiresAuth: true,
          entityType: InvoiceEntityId.CUSTOMER_INVOICES
        }
      },
      {
        path: `${URLS.PROFORMA_INVOICES}`,
        name: URLS.PROFORMA_INVOICES,
        component: ProformaInvoicesView,
        meta: {
          title: URLS.PROFORMA_INVOICES,
          parentUrl: URLS.BILLING,
          entityType: InvoiceEntityId.PROFORMA_INVOICES,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.PROFORMA_INVOICES}/:selectedRegisterId`,
        name: `${URLS.BILLING}-${URLS.PROFORMA_INVOICES}`,
        component: BillingDetailView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.BILLING,
          submenuName: URLS.PROFORMA_INVOICES,
          context: ContextName.BILLING_PROFORMA_INVOICES,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.BILLING_PROFORMA_INVOICES
          },
          entityType: entity.invoices.proforma.type,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.BILLING,
          title: URLS.PROFORMA_INVOICES,
          requiresAuth: true,
          entityType: InvoiceEntityId.PROFORMA_INVOICES
        }
      },
      {
        path: `${URLS.PROVISIONS}`,
        name: URLS.PROVISIONS,
        component: ProvisionsView,
        meta: {
          title: URLS.PROVISIONS,
          parentUrl: URLS.BILLING,
          entityType: InvoiceEntityId.PROVISIONS,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.PROVISIONS}/:selectedRegisterId`,
        name: `${URLS.BILLING}-${URLS.PROVISIONS}`,
        component: BillingDetailView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.BILLING,
          submenuName: URLS.PROVISIONS,
          context: ContextName.BILLING_PROVISIONS,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.BILLING_PROVISIONS
          },
          entityType: entity.invoices.provisions.type,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.BILLING,
          title: URLS.PROVISIONS,
          requiresAuth: true,
          entityType: InvoiceEntityId.PROVISIONS
        }
      },
      {
        path: `${URLS.PROVIDER_BANKDRAFTS}`,
        name: URLS.PROVIDER_BANKDRAFTS,
        component: ProviderBankdraftsView,
        meta: {
          title: URLS.PROVIDER_BANKDRAFTS,
          parentUrl: URLS.BILLING,
          entityType: InvoiceEntityId.PROVIDER_BANKDRAFTS,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.PROVIDER_BANKDRAFTS}/:selectedRegisterId`,
        name: `${URLS.BILLING}-${URLS.PROVIDER_BANKDRAFTS}`,
        component: BillingDetailView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.BILLING,
          submenuName: URLS.PROVIDER_BANKDRAFTS,
          context: ContextName.BILLING_PROVIDER_BANKDRAFTS,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.BILLING_PROVIDER_BANKDRAFTS
          },
          entityType: entity.invoices.providerBankdraft.type,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.BILLING,
          title: URLS.PROVIDER_BANKDRAFTS,
          requiresAuth: true,
          entityType: InvoiceEntityId.PROVIDER_BANKDRAFTS
        }
      },
      {
        path: `${URLS.PROVIDER_INVOICES}`,
        name: URLS.PROVIDER_INVOICES,
        component: ProviderInvoicesView,
        meta: {
          title: URLS.PROVIDER_INVOICES,
          parentUrl: URLS.BILLING,
          entityType: InvoiceEntityId.PROVIDER_INVOICES,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.PROVIDER_INVOICES}/:selectedRegisterId`,
        name: `${URLS.BILLING}-${URLS.PROVIDER_INVOICES}`,
        component: BillingDetailView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.BILLING,
          submenuName: URLS.PROVIDER_INVOICES,
          context: ContextName.BILLING_PROVIDER_INVOICES,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.BILLING_PROVIDER_INVOICES
          },
          entityType: entity.invoices.providers.type,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.BILLING,
          title: URLS.PROVIDER_INVOICES,
          requiresAuth: true,
          entityType: InvoiceEntityId.PROVIDER_INVOICES
        }
      },
      {
        path: `${URLS.ORDER_FORMS}`,
        name: URLS.ORDER_FORMS,
        component: OrderFormsView,
        meta: {
          title: URLS.ORDER_FORMS,
          parentUrl: URLS.BILLING,
          entityType: InvoiceEntityId.ORDER_FORMS,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.ORDER_FORMS}/:selectedRegisterId`,
        name: `${URLS.BILLING}-${URLS.ORDER_FORMS}`,
        component: BillingDetailView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.BILLING,
          submenuName: URLS.ORDER_FORMS,
          context: ContextName.BILLING_ORDER_FORMS,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.BILLING_ORDER_FORMS
          },
          entityType: entity.invoices.orderForms.type,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.BILLING,
          title: URLS.ORDER_FORMS,
          requiresAuth: true,
          entityType: InvoiceEntityId.ORDER_FORMS
        }
      }
    ]
  }
]
