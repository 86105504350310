import { Module } from 'vuex'
import { getters } from '@/store/modules/reports/reportsGetters'
import { actions } from '@/store/modules/reports/reportsActions'
import { mutations } from '@/store/modules/reports/reportsMutations'
import { ReportsState } from '@/store/modules/reports/reportsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: ReportsState = {
  reports: [],
  report: {
    id: null,
    idCategory: null,
    idType: null,
    name: null,
    reportName: null,
    category: null,
    config: null,
    description: null,
    date: null
  },
  reportData: null,
  dashboardReportsList: [],
  total: '',
  selectedReportList: null
}

const namespaced = true

export const reportsModule: Module<ReportsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
