<template>
  <div class="actions-bar">
    <div v-for="(button, index) in props.buttons" :key="index">
      <LfLabelButtonComponent
        v-if="!button.isContextMenuOption && button.hidden !== true"
        class="action-button"
        :text="button.tooltip"
        :action="() => executeAction(button.action, index)"
        :prependIcon="button.prependIcon"
        :disabled="disabledButtons || !button.enabled"
        :variant="button.variant || LabelButtonVariant.SOLID"
      />
    </div>
    <LfCustomContextMenu v-if="showKebabMenu" :buttons="customContextMenuButtons" @execute-action="executeAction" />
  </div>
</template>

<script lang="ts" setup>
import { ActionBarButton } from './types/ActionBarComponentTypes'
import LfCustomContextMenu from '@/components/CustomContextMenu/LfCustomContextMenu.vue'
import LfLabelButtonComponent from '@/components/forms/buttons/label/LfLabelButtonComponent.vue'
import { LabelButtonVariant } from '@/components/forms/buttons/types/ButtonTypes'
import { PropType, computed, ref } from 'vue'

// PROPS
const props = defineProps({
  buttons: {
    type: Array as PropType<ActionBarButton[]>,
    required: true
  },
  showKebabMenu: {
    type: Boolean as PropType<boolean>,
    default: true
  }
})

// DATA
const disabledButtons = ref(false)
const timeout = ref(0)

// COMPUTED
const saveButtonIndex = computed(() => props.buttons.length - 1)

const customContextMenuButtons = computed(() =>
  props.buttons.filter((button: ActionBarButton) => button.isContextMenuOption)
)

// EVENTS
const emit = defineEmits(['execute-action'])

// METHODS
function executeAction(action: string, index: number) {
  disabledButtons.value = true

  if (saveButtonIndex.value === index) {
    timeout.value = 4000
  } else {
    timeout.value = 1000
  }

  setTimeout(() => {
    disabledButtons.value = false
  }, timeout.value)

  emit('execute-action', action)
}
</script>

<style lang="scss" scoped>
.actions-bar {
  display: inline-flex;

  .action-button {
    margin-left: $spacing-sm;
    text-wrap: nowrap;
  }
}
</style>
