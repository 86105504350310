import '@/classComponentHooks'
import { Vue } from 'vue-property-decorator'
import App from '@/App.vue'
import { router } from '@/router/router'
import store from '@/store/store'
import vuetify from '@/plugins/vuetify'
import { i18n } from '@/plugins/vue-i18n'
import trackerPlugin from '@/plugins/tracker'
import '@/plugins/v-tooltip'
import '@/plugins/syncfusion'
import '@/plugins/vee-validate/vee-validate'
import moment from 'moment'
import '@/styles/main.scss'
import '@/helpers/string'
import '@/directives/Security/SecurityDirective'
import { createPinia, PiniaVuePlugin } from 'pinia'
import '@/plugins/hotjar'
import clarityPlugin from '@/plugins/clarity'
import cookiebotPlugin from '@/plugins/cookiebot'

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.prototype.$moment = moment
Vue.use(trackerPlugin)

Vue.config.productionTip = false

Vue.use(clarityPlugin)
Vue.use(cookiebotPlugin)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  pinia,
  render: (h) => h(App)
}).$mount('#app')
