import { i18n } from '@/plugins/vue-i18n'

export const URLS = {
  ACTIONS: i18n.t('routes.actions').toString(),
  ACTIONS_PANEL: i18n.t('routes.actions_panel').toString(),
  ACTION_ALL: i18n.t('routes.actions_all').toString(),
  ACTION_CALLS: i18n.t('routes.actions_calls').toString(),
  ACTION_EMAILS: i18n.t('routes.actions_emails').toString(),
  ACTION_INTERNAL_MANAGEMENT: i18n.t('routes.actions_internal_management').toString(),
  ACTION_LEXNET: i18n.t('routes.actions_lexnet').toString(),
  ACTION_MEETINGS: i18n.t('routes.actions_meetings').toString(),
  ACTION_OTHERS: i18n.t('routes.actions_others').toString(),
  ACTION_PROCEDURES: i18n.t('routes.actions_procedures').toString(),
  ACTION_TASKS: i18n.t('routes.actions_tasks').toString(),
  AGENDA: i18n.t('routes.agenda').toString(),
  ALERT_MESSAGES: i18n.t('routes.maintenance_alerts').toString(),
  ANOTHER_APPLICATIONS: i18n.t('routes.another_applications').toString(),
  AUDITS: i18n.t('routes.audits').toString(),
  BASE_TITLE: 'LexON',
  BILLING: i18n.t('routes.billing').toString(),
  CALLS: i18n.t('routes.calls').toString(),
  CONFIGURATION_PANEL: i18n.t('routes.configuration_panel').toString(),
  CONFIGURATION_PANEL_ACTIONS_CATEGORY: i18n.t('routes.configuration_panel_actions_category').toString(),
  CONFIGURATION_PANEL_ACTIONS_CODIFICATION: i18n.t('routes.configuration_panel_actions_codification').toString(),
  CONFIGURATION_PANEL_ACTIONS_CODIFICATION_DETAIL: i18n.t('routes.configuration_panel_actions_codification_detail').toString(),
  CONFIGURATION_PANEL_ACTIONS_COLOR_CODE: i18n.t('routes.configuration_panel_actions_color_code').toString(),
  CONFIGURATION_PANEL_BILLING_COST: i18n.t('routes.configuration_panel_billing_cost').toString(),
  CONFIGURATION_PANEL_BILLING_CUSTOMER_INVOICES_CONCEPTS: i18n.t('routes.configuration_panel_billing_customer_invoices_concepts').toString(),
  CONFIGURATION_PANEL_BILLING_PAYMENT_TYPES: i18n.t('routes.configuration_panel_billing_payment_types').toString(),
  CONFIGURATION_PANEL_BILLING_PROFESSIONALS: i18n.t('routes.configuration_panel_billing_professionals').toString(),
  CONFIGURATION_PANEL_BILLING_PROVIDER_INVOICES_CONCEPTS: i18n.t('routes.configuration_panel_billing_provider_invoices_concepts').toString(),
  CONFIGURATION_PANEL_BILLING_TARIFFS: i18n.t('routes.configuration_panel_billing_tariffs').toString(),
  CONFIGURATION_PANEL_BILLING_TAXES: i18n.t('routes.configuration_panel_billing_taxes').toString(),
  CONFIGURATION_PANEL_BILLING_TAXES_GROUPING: i18n.t('routes.configuration_panel_billing_taxes_grouping').toString(),
  CONFIGURATION_PANEL_CONTACTS_CATEGORY: i18n.t('routes.configuration_panel_contacts_category').toString(),
  CONFIGURATION_PANEL_DETAIL: i18n.t('routes.configuration_panel_detail').toString(),
  CONFIGURATION_PANEL_DOCUMENTS_CATEGORY: i18n.t('routes.configuration_panel_documents_category').toString(),
  CONFIGURATION_PANEL_DOCUMENTS_TEMPLATES: i18n.t('routes.configuration_panel_documents_templates').toString(),
  CONFIGURATION_PANEL_EXPEDIENTS_AUTOMATIC_NUMBERING: i18n.t('routes.configuration_panel_expedients_automatic_numbering').toString(),
  CONFIGURATION_PANEL_EXPEDIENTS_CATEGORY: i18n.t('routes.configuration_panel_expedients_category').toString(),
  CONFIGURATION_PANEL_EXPEDIENTS_LEGAL_AREAS: i18n.t('routes.configuration_panel_expedients_legal_areas').toString(),
  CONFIGURATION_PANEL_EXPEDIENTS_CUSTOM_FIELDS: i18n.t('routes.configuration_panel_expedients_custom_fields').toString(),
  CONFIGURATION_PANEL_EXPEDIENTS_PARTIES: i18n.t('routes.configuration_panel_expedients_parties').toString(),
  CONFIGURATION_PANEL_EXPEDIENTS_SITUATIONS: i18n.t('routes.configuration_panel_expedients_situations').toString(),
  CONFIGURATION_PANEL_EXPEDIENTS_STAGES: i18n.t('routes.configuration_panel_expedients_stages').toString(),
  CONFIGURATION_PANEL_EXPEDIENTS_SUBJECTS: i18n.t('routes.configuration_panel_expedients_subjects').toString(),
  CONFIGURATION_PANEL_EXPEDIENTS_TAGS: i18n.t('routes.configuration_panel_expedients_tags').toString(),
  CONFIGURATION_PANEL_EXPEDIENTS_TYPES: i18n.t('routes.configuration_panel_expedients_types').toString(),
  CONFIGURATION_PANEL_EXPEDIENTS_BUSSINESS_AREAS: i18n.t('routes.configuration_panel_expedients_bussiness_areas').toString(),
  CONFIGURATION_PANEL_EXPEDIENTS_MODULE: i18n.t('routes.configuration_panel_expedients_module').toString(),
  CONFIGURATION_PANEL_EXPEDIENTS_CONCEPT: i18n.t('routes.configuration_panel_expedients_concept').toString(),
  CONFIGURATION_PANEL_PORTAL_EMAIL_TEMPLATES: i18n.t('routes.configuration_panel_portal_email_templates').toString(),
  CONFIGURATION_PANEL_PORTAL_GENERAL: i18n.t('routes.configuration_panel_portal_general').toString(),
  CONFIGURATION_PANEL_USUARIOS: i18n.t('routes.configuration_panel_usuarios').toString(),
  CONTACT: i18n.t('routes.contact').toString(),
  CONTACTS: i18n.t('routes.contacts').toString(),
  COOKIES_POLICY: i18n.t('routes.cookies_policy').toString(),
  CUSTOMER_BAKDRAFTS: i18n.t('routes.customer_bankdrafts').toString(),
  CUSTOMER_INVOICES: i18n.t('routes.customer_invoices').toString(),
  DASHBOARD: i18n.t('routes.dashboard').toString(),
  DOUBLE_OPTIN: i18n.t('routes.double_optin').toString(),
  EDIT: i18n.t('routes.edit').toString(),
  EMAIL: i18n.t('routes.email').toString(),
  EXPEDIENT: i18n.t('routes.expedient').toString(),
  EXPEDIENTS: i18n.t('routes.expedients').toString(),
  EXTERNAL_LOGOUT: 'external-logout',
  FILE_MANAGER_GET: 'file-manager/get-data',
  LEXNET: 'lexnet-app',
  LOGIN: 'login',
  MAINTENANCE: i18n.t('routes.maintenance').toString(),
  MAINTENANCE_ACTIONS: i18n.t('routes.maintenance_actions').toString(),
  MAINTENANCE_ALERTS: i18n.t('routes.maintenance_alerts').toString(),
  MAINTENANCE_CONTACTS: i18n.t('routes.maintenance_contacts').toString(),
  MAINTENANCE_CUSTOMER_PORTAL: i18n.t('routes.maintenance_customer_portal').toString(),
  MAINTENANCE_DOCUMENTS: i18n.t('routes.maintenance_documents').toString(),
  MAINTENANCE_EXPEDIENTS: i18n.t('routes.maintenance_expedients').toString(),
  MAINTENANCE_INVOICES: i18n.t('routes.maintenance_invoices').toString(),
  MAINTENANCE_USERS: i18n.t('routes.maintenance_users').toString(),
  MEETINGS: i18n.t('routes.meetings').toString(),
  NAVISION_LOGIN: 'navision-login',
  NEW: i18n.t('routes.new').toString(),
  NOT_FOUND_PAGE: 'error',
  ONLINE: i18n.t('routes.online').toString(),
  ORDER_FORMS: i18n.t('routes.order_forms').toString(),
  OTHERS: i18n.t('routes.others').toString(),
  OWN_ATTORNEYS: i18n.t('routes.own_attorneys').toString(),
  OWN_LAWYERS: i18n.t('routes.own_lawyers').toString(),
  PERMISSIONS: i18n.t('routes.permissions').toString(),
  PROCEDURES: i18n.t('routes.procedures').toString(),
  PROFORMA_INVOICES: i18n.t('routes.proforma_invoices').toString(),
  PROVIDERS: i18n.t('routes.providers').toString(),
  PROVIDER_BANKDRAFTS: i18n.t('routes.provider_bankdrafts').toString(),
  PROVIDER_INVOICES: i18n.t('routes.provider_invoices').toString(),
  PROVISIONS: i18n.t('routes.provisions').toString(),
  REMEMBER_PASSWORD: i18n.t('routes.remember_password').toString(),
  REPORT: i18n.t('routes.report').toString(),
  REPORTS: i18n.t('routes.reports').toString(),
  REPORTS_LIST: i18n.t('routes.reports_list').toString(),
  REPORT_DETAIL: i18n.t('routes.report_detail').toString(),
  REPOSITORY: i18n.t('routes.repository').toString(),
  REPOSITORY_CLIENTS: 'documentos/clientes',
  SEARCH: i18n.t('routes.search').toString(),
  SELECT_COMPANY: i18n.t('routes.select_company').toString(),
  SSO_PAYMENTS: i18n.t('routes.sso_payments').toString(),
  SSO_UNPAID: i18n.t('routes.sso_unpaid').toString(),
  TABS_VIEW: 'tabs-view'
}

export const externalURLS = {
  cookies_policy: 'https://lefebvre.es/politica-de-cookies/',
  courses: 'https://centrodeayuda.lefebvre.es/cursos/?idEntrada=',
  facebook: 'https://www.facebook.com/lefebvreelderecho',
  faqs: 'https://centrodeayuda.lefebvre.es/preguntas-frecuentes/?idEntrada=',
  help_center: 'https://centrodeayuda.lefebvre.es/?idEntrada=',
  help_center_email: 'mailto:clientes@lefebvre.es',
  instagram: 'https://www.instagram.com/lefebvre_es/',
  legal_warning: 'https://lefebvre.es/aviso-legal/',
  linkedin: 'https://www.linkedin.com/company/lefebvre_es',
  manuals: 'https://centrodeayuda.lefebvre.es/manuales/?idEntrada=',
  news: 'http://centrodeayuda.elderecho.com/es/SitePages/Novedades.aspx?idEntrada=',
  privacy_policy: 'https://lefebvre.es/politica-privacidad#',
  shop: 'https://www.efl.es',
  twitter: 'https://twitter.com/Lefebvre_ES',
  videos: 'https://centrodeayuda.lefebvre.es/videos/?idEntrada=',
  youtube: 'https://www.youtube.com/channel/UCAxbOiNpUotcbZIRL3IxEDg'
}

export const configuracionPanelURLS = {
  DOCUMENTS_TEMPLATES: '/panel-configuracion/documentos/plantillas'
}
