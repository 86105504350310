<template>
  <PromptDialogComponent
    v-if="showDialog"
    :title="text.title"
    :isOpened="showDialog"
    context="statusForm"
    :formSchema="parsedFormSchema"
    :formFieldValues="formData"
    :width="modalWidth"
    @execute-action="onExecuteAction"
  ></PromptDialogComponent>
</template>
<script lang="ts" setup>
import PromptDialogComponent from '@/components/Dialog/PromptDialogComponent.vue'
import useDialog from '@/composables/useDialog'
import useFeedback from '@/composables/useFeedback'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { computed, ref, defineEmits } from 'vue'
import { useI18n } from 'vue-i18n-composable'

const emits = defineEmits(['close'])
const { t } = useI18n()
// DEFINE PROPS  ======================================================================================================
const props = defineProps({
  showDialog: {
    type: Boolean,
    default: false
  },
  selection: {
    type: Object,
    default: () => ({})
  }
})

const text = {
  title: t('components.context_menu.billable'),
  alert: {
    ok: t('components.alerts.register_save').toString()
  },
  dialog: {
    ko: t('views.actions_all.dialog_ko_message').toString(),
    multiple: (totalChanges: number) => t('views.actions_all.dialog_multiple_message', { totalChanges }).toString()
  }
}

const modalWidth = 940

const parsedFormSchema = computed(() => [
  {
    id: 'companyId',
    lg: 12,
    md: 12,
    xl: 12,
    name: 'companyId',
    type: 'text',
    label: 'Profesional',
    itemName: 'name',
    fieldType: 'LexonAutocompleteComponent',
    itemValue: 'id',
    attach: false,
    endPointEntity: {
      url: 'maintenance/manProfessionals/post',
      method: 'POST',
      params: [
        {
          name: 'idUser',
          value: 'idUser'
        }
      ]
    },
    validationRules: 'required'
  },
  ...(hasSubActions.value
    ? [
        {
          id: 'subActionAlertInfo',
          lg: 12,
          md: 12,
          xl: 12,
          name: 'subActionAlertInfo',
          alert: {
            type: 'info-text',
            message: 'Las subactuaciones no son facturables.',
            cancelButton: false
          },
          class: 'static-alert',
          hidden: false,
          fieldType: 'StaticAlertComponent',
          fullWidth: 'true'
        }
      ]
    : [])
])
const formData = ref<any>({})
// COMPOSABLES   ======================================================================================================
const { getDialog, getDialogError } = useDialog()
const { showFeedbackSuccess } = useFeedback()

// COMPUTEDS ==========================================================================================================
const selectedRowsId = computed(() => props.selection.selectedRows.map((row: any) => row.id).join(','))
const payload = computed(() => {
  // if (props.selection.selectAll) {
  //   return {
  //     idType:"ALL",
  //     billed: 1,
  //     idCompany: formData.value.companyId,
  //     filters: props.selection.filters,
  //     archived: props.selection.archived,
  //     search: props.selection.search
  //   }
  // }

  return {
    billed: 1,
    idCompany: formData.value.companyId,
    ids: selectedRowsId.value
  }
})

const isMultipleChanges = computed(() => props.selection.selectedRows.length > 1 || props.selection.selectAll)
const totalChanges = computed(() => {
  return props.selection.selectAll ? props.selection.totals : props.selection.selectedRows.length
})

const hasSubActions = computed(() => {
  return props.selection.selectedRows.some((action: any) => action.parentId > 0)
})
// EVENTS =============================================================================================================
const onExecuteAction = async (action: string) => {
  if (action === 'close') {
    close(false)
  }

  if (action === 'save') {
    save()
  }
}
// METHODS ============================================================================================================
function close(refresh = false) {
  formData.value = {}
  emits('close', { refresh })
}

async function save() {
  if (isMultipleChanges.value) {
    return getDialog({
      type: DialogTypes.INFO,
      message: text.dialog.multiple(totalChanges.value),
      action: async () => {
        const response = await saveMassiveActionBillable()
        close(Boolean(response))
      }
    })
  }

  const response = await saveMassiveActionBillable()
  close(Boolean(response))
}

async function saveMassiveActionBillable() {
  const { success } = await store.dispatch(`${ModuleNamespaces.ACTIONS}/massiveBillableAction`, payload.value)
  if (success) {
    showFeedbackSuccess({ message: text.alert.ok })
    return success
  }
  getDialogError({ message: text.dialog.ko })
}

</script>
