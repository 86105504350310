import { GetterTree } from 'vuex'
import { ActionsState, Action as ActionInterface } from '@/store/modules/actions/actionsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const getters: GetterTree<ActionsState, RootState> = {
  getAllActions(state) {
    return state.actions
  },
  getAction(state) {
    return state.action
  },
  getParentAction(state): ActionInterface | null {
    return state.parentAction
  },
  getSelectedActionType(state) {
    return state.selectedActionType
  },
  getChronometerNewActionInfo(state) {
    return state.chronometerNewActionInfo
  },
  getDuplicateActionId(state) {
    return state.duplicateActionId
  }

}
