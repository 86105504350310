import { ActionTree } from 'vuex'
import { RootState, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { i18n } from '@/plugins/vue-i18n'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { router } from '@/router/router'
import { FormsState, formsMutationTypes, customValidatorTypes } from '@/store/modules/forms/formsTypes'
import { FormsService } from '@/services/FormsService'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { listItemsMutationTypes } from '@/store/modules/listItems/listItemsTypes'
import { MainService } from '@/services/MainService'

const alertsModule: string = ModuleNamespaces.ALERTS
const dialogModule: string = ModuleNamespaces.DIALOG
const errorsModule: string = ModuleNamespaces.ERROR
const selectedRegisterModule: string = ModuleNamespaces.SELECTED_REGISTER

export const actions: ActionTree<FormsState, RootState> = {
  async saveSafeRegisterFormData(
    { state, dispatch },
    { endpoint, idSelectedRegister = 0, goToURL = null, context }
  ) {
    try {
      const formData = JSON.parse(JSON.stringify(state.registerFormData, (_k, v) => (v === undefined ? null : v)))
      const { data } = await new MainService().postData(endpoint, {
        ...formData,
        id: idSelectedRegister
      })
      if(data.errorId && data.errorId !== "0") {
        console.log(`saveRegisterFormData: endpoint "${endpoint}", response: ErrorId = ${data.errorId}`)
        return
      }
      const id = parseInt(data.id, 10)
      dispatch(`${selectedRegisterModule}/saveSelectedRegisterId`, { id, context }, { root: true })

      if (idSelectedRegister === 0 && isNaN(parseInt(router.currentRoute.params.selectedRegisterId, 10)) && goToURL !== -1) {
        await router.replace({
          name: router.currentRoute.name || '',
          params: {
            ...router.currentRoute.params,
            selectedRegisterId: id.toString()
          }
        })
      }

      if (goToURL === null) {
        dispatch(
          `${alertsModule}/showAlert`,
          {
            type: AlertsTypes.SUCCESS,
            message: i18n.t('components.alerts.register_save'),
            componentWhereIsRendered: ComponentWhereIsRendered.MAIN
          },
          { root: true }
        )
        return
      }

      if(goToURL === -1) {
        router.go(goToURL)
      } else {
        await router.push({ name: goToURL })
      }

      if (idSelectedRegister === 0) {
        dispatch(
          `${dialogModule}/showDialog`,
          {
            type: DialogTypes.SUCCESS,
            message: i18n.t('components.alerts.register_save')
          },
          { root: true }
        )
        return
      }

      dispatch(
        `${alertsModule}/showAlert`,
        {
          type: AlertsTypes.SUCCESS,
          message: i18n.t('components.alerts.register_save'),
          componentWhereIsRendered: ComponentWhereIsRendered.MAIN
        },
        { root: true }
      )
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.exceptionDesc
        dispatch(
          `${dialogModule}/showDialog`,
          {
            type: DialogTypes.ERROR,
            message: errorMessage
          },
          { root: true }
        )
      }
      dispatch(`${errorsModule}/existsEndpointError`, {}, { root: true })
    }
  },
  async saveRegisterFormData(
    { state, commit, dispatch },
    { endpoint, idSelectedRegister = 0, goToURL = null, context }
  ) {
    try {
      const formData = JSON.parse(JSON.stringify(state.registerFormData, (_k, v) => (v === undefined ? null : v)))
      const addIdToFormData = {
        ...formData,
        id: idSelectedRegister
      }

      const { data } = await new MainService().postData(endpoint, addIdToFormData)
      if(data.errorId && data.errorId !== "0") {
        console.log(`saveRegisterFormData: endpoint "${endpoint}", response: ErrorId = ${data.errorId}`)
        return
      }
      const id = parseInt(data.id, 10)
      dispatch(`${selectedRegisterModule}/saveSelectedRegisterId`, { id, context }, { root: true })

      if (idSelectedRegister === 0 && isNaN(parseInt(router.currentRoute.params.selectedRegisterId, 10)) && goToURL !== -1) {
        router.replace({
          name: router.currentRoute.name || '',
          params: {
            ...router.currentRoute.params,
            selectedRegisterId: id.toString()
          }
        })
      }

      commit(formsMutationTypes.RESET_REGISTER_FORM_DATA)

      if (goToURL !== null) {
        goToURL !== -1 ? router.push({ name: goToURL }) : router.go(-1)

        if (idSelectedRegister === 0) {
          dispatch(
            `${dialogModule}/showDialog`,
            {
              type: DialogTypes.SUCCESS,
              message: i18n.t('components.alerts.register_save')
            },
            { root: true }
          )
        } else {
          dispatch(
            `${alertsModule}/showAlert`,
            {
              type: AlertsTypes.SUCCESS,
              message: i18n.t('components.alerts.register_save'),
              componentWhereIsRendered: ComponentWhereIsRendered.MAIN
            },
            { root: true }
          )
        }
      } else {
        dispatch(
          `${alertsModule}/showAlert`,
          {
            type: AlertsTypes.SUCCESS,
            message: i18n.t('components.alerts.register_save'),
            componentWhereIsRendered: ComponentWhereIsRendered.MAIN
          },
          { root: true }
        )
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.exceptionDesc
        dispatch(
          `${dialogModule}/showDialog`,
          {
            type: DialogTypes.ERROR,
            message: errorMessage
          },
          { root: true }
        )
      }
      dispatch(`${errorsModule}/existsEndpointError`, {}, { root: true })
    }
  },
  async saveQuietRegisterFormData(
    { state, commit, dispatch },
    { endpoint, idSelectedRegister = 0, goToURL = null, context }
  ) {
    try {
      const formData = JSON.parse(JSON.stringify(state.registerFormData, (_k, v) => (v === undefined ? null : v)))
      const addIdToFormData = {
        ...formData,
        id: idSelectedRegister
      }
      const { data } = await new MainService().postData(endpoint, addIdToFormData)
      if(data.errorId && data.errorId !== "0") {
        console.log(`saveQuietRegisterFormData: endpoint "${endpoint}", response: ErrorId = ${data.errorId}`)
        return
      }
      const id = parseInt(data.id, 10)
      dispatch(`${selectedRegisterModule}/saveSelectedRegisterId`, { id, context }, { root: true })
      if (idSelectedRegister === 0 && isNaN(parseInt(router.currentRoute.params.selectedRegisterId, 10))) {
        router.replace({
          name: router.currentRoute.name || '',
          params: {
            ...router.currentRoute.params,
            selectedRegisterId: id.toString()
          }
        })
      }
      commit(formsMutationTypes.RESET_REGISTER_FORM_DATA)
      switch(goToURL) {
        case null:
          break
        case -1:
          router.go(-1)
          break
        default:
          router.push({ name: goToURL })
      }

    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.exceptionDesc
        dispatch(
          `${dialogModule}/showDialog`,
          {
            type: DialogTypes.ERROR,
            message: errorMessage
          },
          { root: true }
        )
      }
      dispatch(`${errorsModule}/existsEndpointError`, {}, { root: true })
    }
  },
  prepareRegisterFormDataToSave({ commit }, formData) {
    commit(formsMutationTypes.PREPARE_REGISTER_FORM_DATA, formData)
  },
  async saveRelatedForm({ dispatch }, { url, data }) {
    try {
      await new MainService().postData(url, data)
      return true
    } catch (error) {
      let errorMessage = i18n.t('errors.generic_error')
      if (error.response.data.exceptionDesc && error.response.data.exceptionDesc !== '') {
        errorMessage = error.response.data.exceptionDesc
      }
      dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.ERROR,
          message: errorMessage
        },
        { root: true }
      )
    }
  },
  async listRelatedForm({ commit }, { url, filterData }) {
    try {
      if (filterData) {
        const { data } = await new MainService().postData(url, filterData)
        commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
      } else {
        const { data } = await new FormsService().getData(url)
        commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
      }
    } catch (error) {}
  },
  async removeRelatedFormItem({ dispatch }, { url }) {
    try {
      await new FormsService().deleteData(url)
    } catch (error) {
      const errorMessage = error.response.data.detail ? error.response.data.detail : error.response.data.exceptionDesc
      dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.ERROR,
          message: errorMessage
        },
        { root: true }
      )
      dispatch(`${errorsModule}/existsEndpointError`, {}, { root: true })
    }
  },
  async contactsValidate({ commit, dispatch }, { params }) {
    try {
      commit(formsMutationTypes.SET_CUSTOM_VALIDATE_FIELD, {
        field: customValidatorTypes.DNI,
        value: params.dni
      })
      const url = 'contact/check'
      const { data } = await new MainService().postData(url, params)
      return data
    } catch (error) {
      const errorMessage = error.response.data.detail ? error.response.data.detail : error.response.data.exceptionDesc
      dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.ERROR,
          message: errorMessage
        },
        { root: true }
      )
      dispatch(`${errorsModule}/existsEndpointError`, {}, { root: true })
    }
  },
  async iDNumberProfessionalValidate({ commit, dispatch }, { params }) {
    try {
      commit(formsMutationTypes.SET_CUSTOM_VALIDATE_FIELD, {
        field: customValidatorTypes.DNI,
        value: params.dni
      })
      const url: string = 'professional/check'
      const { data } = await new MainService().postData(url, params)
      return data
    } catch (error) {
      const errorMessage = error.response.data.detail ? error.response.data.detail : error.response.data.exceptionDesc
      dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.ERROR,
          message: errorMessage
        },
        { root: true }
      )
      dispatch(`${errorsModule}/existsEndpointError`, {}, { root: true })
    }
  },
  async setSelectParentFieldValue({ commit }, data) {
    try {
      commit(formsMutationTypes.SET_SELECTED_PARENT_FIELD_DATA, data)
    } catch (error) { }
  },
  async setParentFieldValues({ commit }, data) {
    try {
      commit(formsMutationTypes.SET_PARENT_FIELDS_DATA, data)
    } catch (error) { }
  },
  async setParentFormContext({ commit }, context) {
    try {
      commit(formsMutationTypes.SET_PARENT_FORM_CONTEXT, context)
    } catch (error) { }
  }
}
