import { URLS } from '@/router/routes/urlRoutes'
import CookiesPolicyView from '@/views/LegalTexts/CookiesPolicyView.vue'

export const footerRoutes = [
  {
    path: `/${URLS.COOKIES_POLICY}`,
    name: URLS.COOKIES_POLICY,
    component: CookiesPolicyView,
    meta: {
      title: URLS.COOKIES_POLICY,
      requiresAuth: true
    }
  }
]
