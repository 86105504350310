import { setInteractionMode } from 'vee-validate'

setInteractionMode('onlyInput', (_context) => {
  return { on: ['input'] }
})

setInteractionMode('onlyBlur', (_context) => {
  return { on: ['blur'] }
})

setInteractionMode('aggressive')
