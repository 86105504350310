import { MutationTree } from 'vuex'
import { AuthState, UserType, LogoutArgs, UserInfo, AuthData, UserTool } from '@/store/modules/auth/authTypes'
import { router } from '@/router/router'
import { URLS } from '@/router/routes/urlRoutes'
import userPermission from '@/directives/Security/security.json'
import store, { initialStateCopy, resetInitStateProperties, resetState } from '@/store/store'
import { MainService } from '@/services/MainService'
import { trackUser } from '@/plugins/tracker'
import { http } from '@/plugins/axios'
import { userflowLogin, userflowLogout } from '@/plugins/userflow'


export const mutations: MutationTree<AuthState> = {
  SET_PRE_AUTH_DATA(state, preAuthData) {
    state.preAuthData = preAuthData
    localStorage.setItem('pre-auth-data', JSON.stringify(preAuthData))
  },
  SET_AUTH_DATA(state, authData: AuthData) {
    state.authData = authData
    state.companyName = authData.user.company.name
    const userType: string = UserType[state.authData!.user.type]
    if (userType) {
      state.authData!.user.permissions = (userPermission as any)[userType]
    }
    trackUser()
    userflowLogin()
    localStorage.setItem('auth-data', JSON.stringify(authData))
    localStorage.setItem('company-name', state.companyName)
  },
  RESET_PRE_AUTH_DATA(state) {
    state.preAuthData = null
    localStorage.removeItem('pre-auth-data')
  },
  async LOGOUT(state, args: LogoutArgs) {
    const isNavision: boolean = typeof args !== 'undefined' ? args.isNavision : false
    const userInfo: UserInfo | null = state && state.authData ? state.authData.user : null
    const isEmailValid = userInfo && userInfo.email !== null && userInfo.email !== ''
    const isIdProUserValid = userInfo && userInfo.idProUser !== null && userInfo.idProUser !== ''
    try {
      if (userInfo && isEmailValid && isIdProUserValid) {
        new MainService().postData('/lexonsso/logout', {
          email: userInfo.email,
          idProUser: userInfo.idProUser
        })
      }
      if (userInfo) {
        new MainService().getData('/audits/logout')
      }
    } catch (error) {
    }
    localStorage.removeItem('auth-data')
    localStorage.removeItem('pre-auth-data')
    localStorage.removeItem('company-name')
    localStorage.removeItem('lex-on-storage')
    delete http.defaults.headers.common['Authorization']
    state.authData = null
    state.companyName = ''
    state.preAuthData = null
    resetInitStateProperties()
    userflowLogout()
    if (isNavision) {
      store.replaceState(initialStateCopy)
    } else {
      resetState(store)
      router.push({ name: URLS.LOGIN, params: { nextUrl: 'ignore' } })
    }
  },
  ADD_CANCEL_TOKEN(state, token) {
    state.cancelTokens.push(token)
  },
  CLEAR_CANCEL_TOKENS(state) {
    state.cancelTokens = []
  },
  ENCRYPT_ID_PRO_USER(state, encryptedIdProUser) {
    if (encryptedIdProUser.ENTRADA_ENCRIPTADA) {
      state.encryptIdProUser = encryptedIdProUser.ENTRADA_ENCRIPTADA
    }
  },
  SET_LAST_REFRESH_TOKEN_TIME(state, dateTime: number) {
    state.lastRefreshTokenTime = dateTime
  },
  SET_USER_NIF(state, nif: string) {
    if (state.authData && state.authData.user) {
      state.authData.user.nif = nif
    }
  },
  SET_USER_TOOLS(state, toolList: UserTool[]) {
    state.tools = toolList
  }
}
