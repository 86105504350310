<template></template>
<script lang="ts" setup>
import useDialog from '@/composables/useDialog'
import useFeedback from '@/composables/useFeedback'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n-composable'

// DEFINE PROPS  ======================================================================================================
const emits = defineEmits(['close'])
const { t } = useI18n()
// DEFINE PROPS  ======================================================================================================
const props = defineProps({
  showDialog: {
    type: Boolean,
    default: false
  },
  selection: {
    type: Object,
    default: () => ({})
  }
})

const text = {
  message: (totalChanges: number) => t('views.actions_all.billable_diaglog_message', { totalChanges }).toString(),
  alert: {
    ok: t('components.alerts.register_save').toString()
  },
  dialog: {
    ko: t('views.actions_all.dialog_ko_message').toString()
  }
}

// COMPOSABLES   ======================================================================================================
const { getDialogInfo, getDialogError } = useDialog()
const { showFeedbackSuccess } = useFeedback()

// COMPUTEDS ==========================================================================================================
const selectedRowsId = computed(() => props.selection.selectedRows.map((row: any) => row.id).join(','))
const payload = computed(() => {
  // if (props.selection.selectAll) {
  //   return {
  //     idType: 'ALL',
  //     billed: 0,
  //     filters: props.selection.filters,
  //     archived: props.selection.archived,
  //     search: props.selection.search
  //   }
  // }
  return {
    ids: selectedRowsId.value,
    billed: 0
  }
})
const totalChanges = computed(() => {
  return props.selection.selectAll ? props.selection.totals : props.selection.selectedRows.length
})
// METHODS ============================================================================================================
async function massiveNoBilled() {
  const { success } = await store.dispatch(`${ModuleNamespaces.ACTIONS}/massiveBillableAction`, payload.value)
  if (success) {
    showFeedbackSuccess({ message: text.alert.ok })
    return success
  }
  getDialogError({ message: text.dialog.ko })
}

function close(refresh = false) {
  emits('close', { refresh })
}

// HOOKS =============================================================================================================
props.showDialog &&
  getDialogInfo({
    message: text.message(totalChanges.value),
    action: async () => {
      const response = await massiveNoBilled()
      close(Boolean(response))
    }
  })
</script>
