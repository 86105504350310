<template lang="pug">

ValidationProvider(
  :rules="validationRules"
  v-slot="{ errors }"
  tag="div"
)
  div(
    :class="[{ 'error-styles': errors[0] }, { 'disabled-field': disabled },  'lf-file-upload-container']"
  )
    span(:class="[{ 'required-field': requiredField }, 'label-text']") {{ $attrs.label }}
    div(class="lf-file-upload")
      v-file-input(
        id="fileUpload"
        :label="innerValue"
        :disabled="disabled"
        :placeholder="placeHolder"
        :clearable="false"
        :value="fileValue"
        :class="[{ 'error-styles': errors[0] }, 'lf-file-upload-input']"
        @change="onFileChange"
        single-line
      )
    div(class="messages-container")
      div(class="messages")
        span(v-if="errors.length" class="alert-message") {{ errors[0] }}
        span(v-else class="help-message") {{ helpText }}
  div(v-if="showPreview && fileValue" class="lf-file-preview")
    v-img(:src="previewImage")
  div(v-if="showPreview && fileValue" class="clear-field-button" @click="clearField")
    span(:class="`${removeIcon} clear-field-icon`")
    span(class="clear-field-label") {{ clearFieldText }}
</template>

<script lang="ts">
import { Action, Getter } from 'vuex-class'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { MaxFileUploadSize } from '@/store/modules/fileManager/fileManagerTypes'
import { Icons } from '@/icons/icons'

const fileManagerModule = ModuleNamespaces.FILE_MANAGER

@Component({
  components: {
    ValidationProvider
  }
})
export default class LexonFileUploadComponent extends Vue {
  @Prop({
    type: String
  })
  validationRules!: string

  @Prop({
    type: [String, File]
  })
  value!: string | File

  @Prop({
    type: Boolean,
    default: false
  })
  disabled!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  showPreview!: boolean

  @Action('fetchMaxFileUploadSize', { namespace: fileManagerModule }) fetchMaxFileUploadSize: () => void
  @Action('fetchAllowedExtensions', { namespace: fileManagerModule }) fetchAllowedExtensions: () => void

  @Getter('getMaxFileUploadSize', { namespace: fileManagerModule }) maxFileUploadSize: MaxFileUploadSize
  @Getter('getAllowedExtensions', { namespace: fileManagerModule }) allowedExtensions: string

  helpText: string = ''
  innerValue = ''
  fileValue: any = null
  preview: any = null

  clearFieldText: any = this.$t('components.file_manager.uploader.clear_field_image')
  removeIcon: any = Icons.REMOVE

  @Watch('value')
  changeInnerValue(newVal: File) {
    if (newVal) {
      this.innerValue = newVal.name
    }
  }

  get requiredField() {
    return this.validationRules && this.validationRules.includes('fileRequired') ? true : false
  }

  get placeHolder() {
    if (this.innerValue) {
      return this.innerValue
    }
    return this.$t('components.lexon_file_upload.file_placeholder')
  }

  onFileChange(file: any) {
    if (file && file.name) {
      this.fileValue = file
      this.$emit('input', file)
    }
  }

  get previewImage() {
    this.preview = URL.createObjectURL(this.fileValue)
    return this.preview
  }

  clearField() {
    this.fileValue = null
    this.innerValue = ''
    this.preview = null
  }

  async created() {
    if (!this.maxFileUploadSize) {
      await this.fetchMaxFileUploadSize()
    }
    if (!this.allowedExtensions) {
      await this.fetchAllowedExtensions()
    }
    await this.$nextTick()
    this.helpText = this.$t('components.file_manager.advises.max_file_upload_size', {
      size: this.maxFileUploadSize.sizeFormatted
    }) as string

    if (this.value) {
      this.innerValue = (this as any).value.name || this.value
    }
  }
}
</script>

<style lang="scss">
.form {
  #logo {
    flex-direction: column !important;
  }
}
</style>

<style lang="scss" scoped>
.lf-file-upload-container {
  @include flex($flex-direction: column, $align-items: flex-start);
  @include textfield-messages;
  position: relative;
  width: 100%;

  &.disabled-field {
    .label-text,
    .messages-container .help-message,
    .messages-container .counter {
      color: $gray-02;
    }

    &::after {
      color: $gray-02;
    }
  }

  &.error-styles {
    .label-text {
      color: $error-color;
    }

    .clean-icon {
      color: $error-color;
    }
  }
  .lf-file-upload {
    width: 100%;
    height: 40px;
    &:hover .lf-file-upload {
      border-color: $corporate-color;
      background-color: $blue-07;
    }

    .lf-file-upload-input {
      @include borders($color: $gray-03);
      @include textfield-styles;
      @include flex($justify-content: flex-start);
      position: relative;
      width: 100%;
      height: 40px;
      font-family: $secondary-font;
      font-size: 14px;
      color: $gray-01;
      padding-right: 0;
      padding-left: 0;
      flex-direction: row-reverse;
      align-items: center;

      &:focus ~ .clean-icon {
        display: block;
      }
    }
  }

  .label-text {
    @include label-text;
    &.required-field::after {
      right: -6px;
      top: -2px;
    }
  }

  .clean-icon {
    position: absolute;
    top: 30px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: $corporate-color;
    display: none;
  }

  .messages-container {
    @include flex($justify-content: space-between);
    width: 100%;

    .counter {
      color: $blue-04;
      font-size: 11px;

      &.limit-reached {
        color: $error-color;
      }
    }
  }
  ::v-deep .v-text-field {
    margin: 0;
  }

  ::v-deep .v-text-field > .v-input__control > .v-input__slot:before {
    border: none;
  }

  ::v-deep .v-text-field > .v-input__control > .v-input__slot:before,
  ::v-deep .v-text-field > .v-input__control > .v-input__slot:after {
    width: 0;
  }

  ::v-deep .v-file-input .v-file-input__text {
    padding-left: 10px;
  }

  ::v-deep .v-text-field__details {
    display: none;
  }

  ::v-deep .lf-icon-up-file {
    padding-bottom: 0;
  }

  ::v-deep .v-text-field__slot > .v-label {
    display: block;
    position: relative;
    width: 100%;
    font-family: $secondary-font;
    font-size: 14px;
    color: $gray-01;
    padding-right: 36px;
    padding-left: 10px;
  }

  ::v-deep .v-input__slot {
    margin-bottom: 0;
  }

  ::v-deep .v-input--is-disabled {
    border-top: 1px solid $gray-03;
    border-bottom: 1px solid $gray-03;
    background-color: $gray-04;
  }

  &.disabled-field {
    pointer-events: none;

    .label-container .required-field,
    .label-container .label-text,
    .messages-container .help-message {
      color: $gray-02;
    }
  }
}

::-webkit-resizer {
  display: none;
}

.lf-file-u-container::after {
  @include flex;
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 17px;
  right: 2px;
  font-size: 15px;
  font-family: $lf-icons-font;
  color: $gray-02;
  background-color: transparent;
  content: '\e942';
}

.lf-file-u-container.error-styles::after {
  color: $error-color;
}

.lf-file-preview {
  width: 100%;
  border: 1px solid $gray-02;
  padding: 15px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .v-image {
    width: 100%;
    max-width: 750px;
  }
}

.clear-field-button {
  color: $blue-01;
  align-self: flex-end;
  margin-top: 10px;

  &:hover {
    cursor: pointer;
  }

  .clear-field-label {
    margin-left: 5px;
    font-size: 0.9em;
    font-weight: bold;
  }
}
</style>
