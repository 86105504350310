import { Icons } from '@/icons/icons'
import { TranslateResult } from 'vue-i18n'

export interface AlertsState {
  showAlert: boolean
  alertMode: AlertMode | null
  alertData: AlertsData | null
}

export interface AlertsData {
  type: AlertsTypes
  message: string | null | TranslateResult
  subMessage?: string | null
  acceptLink?: AlertLink | null
  cancelLink?: AlertLink | null
  cancelButton?: boolean
  componentWhereIsRendered?: ComponentWhereIsRendered
}

export interface AlertLink {
  tooltip: string | TranslateResult
  text: string | TranslateResult
  callback: Function
}

export enum AlertMode {
  FULL= 'full',
  ICON = 'icon',
  MESSAGE = 'message',
  SUBMESSAGE = 'submessage'
}

export enum AlertsTypes {
  ERROR = 'error-text',
  INFO = 'info-text',
  SUCCESS = 'success-text',
  WARNING = 'warning-text'
}

export const AlertsIcons: { [key in AlertsTypes]: Icons } = {
  [AlertsTypes.INFO]: Icons.INFO_FULL,
  [AlertsTypes.SUCCESS]: Icons.CHECK_ROUND_FULL,
  [AlertsTypes.ERROR]: Icons.CLOSE_ROUND_FULL,
  [AlertsTypes.WARNING]: Icons.WARNING_FULL,
}

export enum ComponentWhereIsRendered {
  GRID_TABLE = 'GridTableComponent',
  LF_HEADER_TITLE = 'LFHeaderTitleComponent',
  LOGIN_VIEW = 'LoginView',
  PERMISSIONS = 'LexonPermissions',
  SIMPLE_GRID_TABLE = 'SimpleGridTableComponent',
  TABS_COMPONENT = 'TabsComponent',
  TABS_VIEW = 'TabsView',
  MAIN = 'main'
}

export const alertsMutationTypes = {
  SHOW_ALERT: 'SHOW_ALERT',
  CLOSE_ALERT: 'CLOSE_ALERT'
}
