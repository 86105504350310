<template lang="pug">
div
  LfHeaderTitleComponent(:title="texts.headerTitle" class="header-title")
  LfTotalsComponent(
    v-if="totals && totals.length"
    :items="totals"
  )
  GridTable(
    v-if="renderGrid && !showSpinnerLayer"
    ref="grid"
    :itemsData="isPortalUser ? listItems : serverSideData"
    :gridConfiguration="gridConfiguration"
    :toolbarOptions="toolbarOptionsCustom"
    :contextMenuItems="isPortalUser ? [printButton] : invoiceContextMenuItems"
    :listName="listConfiguration['Alias']"
    :context="context"
    :archivedFilter="archivedSelectedOption"
    :disabledGridCellClicked="getDisableGridProperty"
    :commandClick="onCommandClick"
    @contextMenuClicked="onContextMenuClicked"
    @gridTableFilter="onGridTableFilter"
    @toolbarClicked="onToolbarClicked"
    @customToolbarContextMenuClick="onCustomToolbarContextMenuClick"
    excelExportedFileName="hojas de encargo"
    @gridActionChange="gridActionChange"
    @gridExportData="gridExportData"
    :useServerPagination="useServerPagination"
    :itemsDataExport="serverSideExportData"
  )

  ejs-contextmenu(
    :id="idContextMenu"
    class="context-menu-component e-contextmenu-archived"
    :items="invoicesArchivedContextMenuItemsParsed"
    :select="onInvoicesArchivedClick"
  )

  TemplateSelectorComponent(
    :showDialog="showTemplateDialog"
    :idEntityType="idEntityType"
    @closeTemplateDialog="closeTemplateDialog"
    @generateTemplateDialog="generateTemplateDialog"
  )

</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import GridTable from '@/components/grids/GridTable/GridTableComponent.vue'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import ListViewMixin from '@/mixins/ListViewMixin.vue'
import ArchivedFilterMixin from '@/mixins/ArchivedFilterMixin.vue'
import PortalUserMixin from '@/mixins/PortalUserMixin.vue'
import { Action, Getter } from 'vuex-class'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import { BillingTypes, TotalItem } from '@/store/modules/billing/billingTypes'
import PrintInvoiceContextMenuMixin from '@/mixins/PrintInvoiceContextMenuMixin.vue'
import { entity } from '@/store/modules/entities/entitiesTypes'
import TemplateSelectorComponent from '@/components/template/TemplateSelectorComponent/TemplateSelectorComponent.vue'
import { UserType } from '@/store/modules/auth/authTypes'
import { ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { Icons } from '@/icons/icons'
import LfHeaderTitleComponent from '@/components/HeaderTitle/LfHeaderTitleComponent.vue'
import { ActionName, CommandClickEventArgs, CommandModel } from '@/components/grids/LfGrid/LfGridTypes'
import { URLS } from '@/router/routes/urlRoutes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import LfTotalsComponent from '@/components/LfTotals/LfTotalsComponent.vue'
import { gridConfigurationWithActionColumn } from '@/helpers/grid'
import {
  columnsChooserToolbarItem,
  filterToolbarItem,
  searchToolbarItem
} from '@/components/grids/LfGrid/components/ToolbarContextMenu/ToolbarContextMenuType'
import { ContextMenuClickEventArgs, DataResult } from '@syncfusion/ej2-vue-grids'
import { formatFieldsTypeDateEPOCH } from '@/helpers/dateTime'
import useGridSaveUserConf from '@/composables/useGridSaveUserConf'

const authModule = ModuleNamespaces.AUTH
const billingModule: string = ModuleNamespaces.BILLING
const invoicesModule = ModuleNamespaces.INVOICES
const configurationModule: string = ModuleNamespaces.CONFIGURATION

const { parseUserConfig } = useGridSaveUserConf()

@Component({
  components: {
    GridTable,
    LfHeaderTitleComponent,
    LfTotalsComponent,
    TemplateSelectorComponent
  }
})
export default class OrderFormsView extends mixins(
  ListViewMixin,
  ArchivedFilterMixin,
  PrintInvoiceContextMenuMixin,
  PortalUserMixin
) {
  @Action('fetchOrderFormList', { namespace: billingModule })
  fetchOrderFormList: (filter: {}) => Promise<void>

  @Action('fetchOrderFormTotals', { namespace: billingModule })
  fetchOrderFormTotals: (filter: {}) => Promise<void>

  @Action('fetchInvoices', { namespace: invoicesModule })
  fetchPortalInvoices: (filter: {}) => []

  @Action('removeOrderForm', { namespace: billingModule })
  removeOrderForm: (id: string) => { success: boolean; message: string }

  @Action('rememberUserCustomConfigurationList', { namespace: configurationModule })
  rememberUserCustomConfigurationList: (flag: boolean) => {}

  @Getter('getTotals', { namespace: billingModule })
  totals: TotalItem[]

  @Getter('getCompanyTemplateInvoices', { namespace: authModule })
  hasCompanyTemplateInvoice: boolean

  @Getter('getBillingData', { namespace: billingModule })
  invoiceData: DataResult

  useServerPagination: boolean = true

  serverSideData: DataResult = {} as DataResult
  serverSideExportData = []

  idContextMenu: string = 'invoicesArchivedContextMenuComponent'

  idEntity: number | string = 0
  idEntityType: number = entity.invoices.orderForms.type
  gridFilters: any = { page: 0, pageSize: 10, columnOrder: [] }

  texts = {
    headerTitle: this.$t('views.order_forms.title').toString(),
    openNewWindow: this.$t('components.context_menu.open_window'),
    printButton: this.$t('components.context_menu.generate_order_form')
  }

  get context() {
    return ContextName.BILLING_ORDER_FORMS
  }

  async getFilterParams() {
    const listConfig = JSON.parse((this as any).listConfiguration['Config'])
    this.archivedSelectedOption = listConfig.archived || 0
    const params = await parseUserConfig(listConfig)
    this.gridFilters = params
  }

  /** @override **/
  get printButton() {
    return {
      iconCss: this.isPortalUser ? Icons.OPEN_WINDOW : Icons.DOCUMENT_VALIDATE,
      text: this.isPortalUser ? this.texts.openNewWindow : this.texts.printButton
    }
  }

  get toolbarOptionsCustom() {
    const { filters } = this.toolbarOptionsTooltipTexts
    return [
      searchToolbarItem,
      filterToolbarItem,
      ...(!this.isPortalUser
        ? [
            {
              id: 'add',
              text: this.$t('components.grid_table.tooltip.new_order_form'),
              tooltipText: this.$t('components.grid_table.tooltip.new_order_form'),
              cssClass: 'lf-btn-model2',
              align: 'Right'
            }
          ]
        : []),
      columnsChooserToolbarItem,
      {
        id: ActionName.CUSTOM_TOOLBAR_CONTEXT_MENU,
        align: 'Right',
        cssClass: 'lf-btn-kebak',
        prefixIcon: Icons.KEBAB,
        contextMenuItems: [
          ...(!this.isPortalUser
            ? [
                {
                  id: 'archived',
                  iconCss: Icons.FILTER,
                  text: filters,
                  items: this.selectArchivedOption
                }
              ]
            : []),
          {
            id: ActionName.PRINT,
            iconCss: Icons.PRINT,
            text: this.$t('components.grid_table.tooltip.print').toString()
          },
          {
            id: ActionName.EXPORT,
            iconCss: Icons.DOWNLOAD,
            text: this.$t('components.grid_table.tooltip.export').toString()
          }
        ]
      }
    ]
  }

  get invoiceContextMenuItems() {
    const items = this.contextMenuItemsDefault
    return this.getPrintContextMenuItems(items)
  }

  get gridConfiguration() {
    const config = JSON.parse(this.listConfiguration['Config'])
    const commandButtons: CommandModel[] = [
      {
        id: ActionName.OPEN_KEBAK_MENU,
        type: 'None',
        title: 'Open',
        buttonOption: {
          iconCss: Icons.KEBAB,
          cssClass: 'custombutton'
        }
      }
    ]
    const gridConfiguration = gridConfigurationWithActionColumn(config, commandButtons)
    return JSON.stringify(gridConfiguration)
  }

  get filters() {
    const filters = {
      ...this.gridFilters,
      archived: this.archivedSelectedOption
    }

    return this.isPortalUser ? { ...filters, ...this.portalFilters } : filters
  }

  async created() {
    await this.rememberUserCustomConfigurationList(true)
    await this.fetchCurrentListConfiguration(ListNames.ORDER_FORMS)
    await this.getFilterParams()
    await this.initializeArchivedSelectedOption({ selectedOption: 1, contextMenu: this.idContextMenu })
    await this.fetchGridItems()
    await this.fetchOrderFormTotals(this.filters)
    this.saveSelectedEntityName(BillingTypes.ORDER_FORMS)
    this.renderGrid = true
    this.hideSpinnerLayerAction()
  }

  async fetchGridItems() {
    if (this.isPortalUser) {
      await this.fetchPortalInvoices({
        archived: this.invoicesDefaulArchivedOption,
        userType: UserType.PORTAL,
        selectedRegisterId: this.portalCustomerId,
        listName: ListNames.ORDER_FORMS
      })
    } else {
      await this.fetchOrderFormList({
        archived: this.archivedSelectedOption,
        ...this.filters
      })
      this.serverSideData = this.formatDataPaginated(this.invoiceData as any)
    }
  }

  onContextMenuClicked(args: any, selectedRegister: any) {
    if (
      args.item.text === this.texts.printButton ||
      (this.isPortalUser && args.item.text === this.texts.openNewWindow)
    ) {
      if (this.hasCompanyTemplateInvoice) {
        this.idEntity = selectedRegister.id
        this.showTemplateDialog = this.checkIfOnlyOneInvoice(
          this.idEntity,
          this.idEntityType,
          ComponentWhereIsRendered.GRID_TABLE
        )
      } else {
        this.printInvoiceOld(selectedRegister.id, entity.invoices.provisions.alias)
      }
    }
  }

  generateTemplateDialog(templateSelected: any) {
    this.printInvoice(templateSelected, this.idEntity, this.idEntityType, ComponentWhereIsRendered.GRID_TABLE)
  }

  async onGridTableFilter(filters: any) {
    this.gridFilters = filters
  }

  onToolbarClicked(args: any) {
    const target = args.originalEvent.target.closest('button')
    if (target && target.id === 'archived') {
      this.toggleArchivedContextMenu(args.originalEvent, this.idContextMenu)
      args.cancel = true
    }
  }

  async onInvoicesArchivedClick(args: any) {
    this.archivedSelectedOption = args.item.actionType
    this.changeContextMenuIcons(args.item.actionType, this.idContextMenu)
    await this.saveConfig(this.archivedSelectedOption)
    await this.fetchGridItems()
    await this.fetchOrderFormTotals(this.filters)
  }

  onCommandClick({ commandColumn, rowData }: CommandClickEventArgs): void {
    const action = commandColumn?.id
    const { id, description } = rowData as any
    switch (action) {
      case ActionName.REMOVE:
        this.confirmDelete(id, description)
        break
      case ActionName.PRINT:
        this.print(id)
        break
      default:
        this.open(id, '_blank')
        break
    }
  }

  open(id: string, target = '_self') {
    const routeData = this.$router.resolve({
      name: `${URLS.BILLING}-${URLS.ORDER_FORMS}`,
      params: { selectedRegisterId: id }
    })

    if (routeData && target === '_blank') {
      window.open((routeData as any).href, '_blank')
    } else {
      this.$router.push((routeData as any).resolved)
    }
  }

  confirmDelete(id: string, name: string) {
    this.showDialogAction({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.remove_register_text', {
        register: name,
        text: this.$t('components.dialog.order_form_literal')
      }),
      action: async () => {
        await this.remove(id, name)
        ;(this as any).$refs.grid.refresh()
      },
      mainButtonText: this.$t('action_buttons.remove'),
      secondaryButtonText: this.$t('action_buttons.cancel')
    })
  }

  async remove(id: string, name: string) {
    const { success } = await this.removeOrderForm(id)
    if (success) {
      this.showDialogAction({
        type: DialogTypes.SUCCESS,
        message: this.$t('components.dialog.success_removed_register', {
          text: this.$t('components.dialog.order_form_literal'),
          register: name
        })
      })
    } else {
      this.showDialogAction({
        type: DialogTypes.ERROR,
        message: this.$t('components.dialog.error_remove_order_form')
      })
    }
  }

  print(id: string) {
    if (!this.hasCompanyTemplateInvoice) {
      this.idEntity = id
      this.showTemplateDialog = this.checkIfOnlyOneInvoice(
        this.idEntity,
        this.idEntityType,
        ComponentWhereIsRendered.GRID_TABLE
      )
    } else {
      this.printInvoiceOld(id, entity.invoices.provisions.alias)
    }
  }

  onCustomToolbarContextMenuClick(args: ContextMenuClickEventArgs) {
    const { item } = args
    const { id } = item

    if (id?.includes(ActionName.ARCHIVED) && !this.isArchiveContextMenuFirstLevel(id)) {
      this.onInvoicesArchivedClick(args)
      return
    }
  }

  formatDataPaginated(data: any) {
    const items = formatFieldsTypeDateEPOCH(data.data)
    const gridResult: DataResult = { result: items, count: parseInt(data.pagination.totalRecs.toString(), 10) }
    return gridResult
  }

  gridActionChange(serverSideParams: string) {
    const { page, pageSize, columnOrder, search, filter } = JSON.parse(serverSideParams)
    this.gridFilters = {
      ...this.filters,
      page,
      pageSize,
      columnOrder,
      filter,
      search
    }
    this.fetchGridItems()
    this.fetchOrderFormTotals(this.filters)
  }

  async gridExportData(serverSideParams: any) {
    const ssp = JSON.parse(serverSideParams)
    ssp['archived'] = this.archivedSelectedOption
    ssp['paginated'] = true
    await this.fetchOrderFormList(ssp)
    this.serverSideExportData = this.formatData(this.billingData)
  }
}
</script>
