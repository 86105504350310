<template lang="pug">

  div(v-if="showCustomDialog" class="custom-dialog-container")
    div(class="custom-dialog-background")
    div(class="custom-dialog-content" :style="customDialogData.styles")
      component(:is="customDialogData.name" :componentProps="customDialogData.props")

  </template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { CustomDialogData } from '@/store/modules/dialog/dialogTypes'
import AssociateActionDialogComponent from '@/components/Dialog/customDialogs/AssociateActionDialogComponent.vue'
import CertifyDocumentFirmaDialogComponent from '@/components/Dialog/customDialogs/firma/CertifyDocumentFirmaDialogComponent.vue'
import ContactMiniFormComponent from '@/components/Dialog/customDialogs/ContactMiniFormComponent.vue'
import DuplicateActionDialogComponent from '@/components/Dialog/customDialogs/DuplicateActionDialogComponent.vue'
import DuplicateExpedientDialogComponent from '@/components/Dialog/customDialogs/DuplicateExpedientDialogComponent.vue'
import GlobalVisionProfitabilityDialogComponent from '@/components/Dialog/customDialogs/globalVisionProfitability/GlobalVisionProfitabilityDialogComponent.vue'
import LoadingDocsIADialogComponent from '@/components/Dialog/customDialogs/LoadingDocsIADialogComponent.vue'
import MonitoringAndAuditsFirmaDialogComponent from '@/components/Dialog/customDialogs/firma/MonitoringAndAuditsFirmaDialogComponent.vue'
import MoveActionDialogComponent from '@/components/Dialog/customDialogs/MoveActionDialogComponent.vue'
import SendCertifiedEmailFirmaDialogComponent from '@/components/Dialog/customDialogs/firma/SendCertifiedEmailFirmaDialogComponent.vue'
import SendCertifiedSMSFirmaDialogComponent from '@/components/Dialog/customDialogs/firma/SendCertifiedSMSFirmaDialogComponent.vue'
import SendNotificationMailInternComponent from '@/components/Dialog/customDialogs/SendNotificationMailInternComponent.vue'
import SendToFirmaDialogComponent from '@/components/Dialog/customDialogs/firma/SendToFirmaDialogComponent.vue'
import SubActionSelectorDialogComponent from '@/components/Dialog/customDialogs/SubActionSelectorDialogComponent.vue'
import ViewEconomicDataActionsDetailDialogComponent from '@/components/Dialog/customDialogs/ViewEconomicDataActionsDetailDialogComponent.vue'
import ViewInvoiceActionsDetailDialogComponent from '@/components/Dialog/customDialogs/ViewInvoiceActionsDetailDialogComponent.vue'

const dialogModule: string = ModuleNamespaces.DIALOG

@Component({
  components: {
    AssociateActionDialogComponent,
    CertifyDocumentFirmaDialogComponent,
    ContactMiniFormComponent,
    DuplicateActionDialogComponent,
    DuplicateExpedientDialogComponent,
    GlobalVisionProfitabilityDialogComponent,
    LoadingDocsIADialogComponent,
    MonitoringAndAuditsFirmaDialogComponent,
    MoveActionDialogComponent,
    SendCertifiedEmailFirmaDialogComponent,
    SendCertifiedSMSFirmaDialogComponent,
    SendNotificationMailInternComponent,
    SendToFirmaDialogComponent,
    SubActionSelectorDialogComponent,
    ViewEconomicDataActionsDetailDialogComponent,
    ViewInvoiceActionsDetailDialogComponent
  }
})
export default class CustomDialogComponent extends Vue {
  @Getter('showCustomDialog', { namespace: dialogModule })
  showCustomDialog: boolean

  @Getter('getCustomDialogData', { namespace: dialogModule })
  customDialogData: CustomDialogData

  @Action('closeCustomDialog', { namespace: dialogModule })
  closeCustomDialog: () => {}
}
</script>

<style lang="scss" scoped>
.custom-dialog-container {
  @include flex;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 99999;

  .custom-dialog-background {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: $dialog-background-color;
  }

  .custom-dialog-content {
    position: absolute;
    width: 80%;
    min-width: 300px;
    min-height: auto;
    font-family: $corporate-font;
    display: flex;
    max-width: 940px;
    max-height: 90%;
    padding: $spacing-xl;
    flex-direction: column;
    align-items: center;
    gap: $spacing-xl;
    border-radius: $cornerRadius-md;
    border: 1px solid $main-200;
    background: $neutral-white-000;
    box-shadow: $shadow-md;
  }
}
</style>

<style lang="scss">
.associate-action-dialog-container,
.move-action-dialog-container {
  width: 100%;
}
</style>
