<template>
  <div class="close-preview-container">
    <img
      class="close-icon"
      :title="texts.close"
      src="@/assets/images/svg/close-icon.svg"
      alt="close icon"
      @click="closeViewerEvent"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n-composable'

// TRANSLATIONS
const { t } = useI18n()

const texts = {
  close: t('components.pdf_viewer.close_document') as string
}

// EVENTS
const emit = defineEmits(['close-viewer-event'])

// METHODS
function closeViewerEvent() {
  emit('close-viewer-event')
}
</script>

<style lang="scss">
.close-preview-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 49px;
  background: $neutral-grey-050;
  padding: $spacing-xs;
  margin-bottom: 10px;

  .close-icon {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}
</style>
