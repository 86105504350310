<template lang="pug">
div
  GridTableComponent(
    v-if="Object.keys(listConfiguration).length && Object.keys(serverSideData).length > 0 && !showSpinnerLayer"
    ref="grid"
    :itemsData="serverSideData"
    :itemsDataExport="serverSideDataExport"
    :gridConfiguration="gridConfiguration"
    :title="$t('views.actions.title')"
    :toolbarOptions="toolbarOptionsCustom"
    :contextMenuItems="contextMenu"
    :listName="listConfiguration['Alias']"
    :useServerPagination="useServerPagination"
    @selectedRecords="onSelectedRecords"
    :recordClick="onCellClicked"
    @cellClicked="onCellClicked"
    @contextMenuBeforeOpen="onCustomContextMenuBeforeOpen"
    @contextMenuClicked="onContextMenuClicked"
    @gridActionChange="gridActionChange"
    @gridExportData="gridExportData"
    @toolbarClicked="onToolbarClicked"
    :context="actionContext"
    :archivedFilter="archivedSelectedOption"
    :customToolbarContextMenuItems="actionsContextMenu"
    @customToolbarContextMenuClick="onActionAddClick"
    :commandClick="commandClick"
    :usePersistSelection="true"
    @gridTableFilter="onGridTableFilter"
  )

  ejs-contextmenu(
    :id="idActionsArchivedContextMenu"
    class="context-menu-component e-contextmenu-archived"
    :items="actionsArchivedContextMenuItems"
    :select="onActionsArchivedClick"
  )

  ChangeStatusDialogComponent(
    v-if="changeStatusDialog"
    :showDialog="changeStatusDialog"
    :selection="gridSelection"
    @close="onStatusDialogClose"
  )

  BillableDialogComponent(
    v-if="billableDialog"
    :showDialog="billableDialog"
    :selection="gridSelection"
    @close="onBillableDialogClose"
  )

  NoBillableDialogComponent(
    v-if="noBillableDialog"
    :showDialog="noBillableDialog"
    :selection="gridSelection"
    @close="onNoBillableDialogClose"
  )

</template>

<script lang="ts">
import { Action, Getter } from 'vuex-class'
import { Action as ActionInterface, ActionTypes, ActionMenu } from '@/store/modules/actions/actionsTypes'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DataResult } from '@syncfusion/ej2-vue-grids'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { URLS } from '@/router/routes/urlRoutes'
import ActionsMixin from '@/mixins/ActionsMixin.vue'
import GridTableComponent from '@/components/grids/GridTable/GridTableComponent.vue'
import LexnetMixin from '@/mixins/LexnetMixin.vue'
import ListViewMixin from '@/mixins/ListViewMixin.vue'
import ArchivedFilterMixin from '@/mixins/ArchivedFilterMixin.vue'
import RemoveActionMixin from '@/mixins/RemoveActionMixin.vue'
import { Icons } from '@/icons/icons'
import { ActionName, CommandClickEventArgs, CommandModel } from '@/components/grids/LfGrid/LfGridTypes'
import {
  columnsChooserToolbarItem,
  filterToolbarItem,
  searchToolbarItem
} from '@/components/grids/LfGrid/components/ToolbarContextMenu/ToolbarContextMenuType'
import { TrackerEvents, trackEvent } from '@/plugins/tracker'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { gridConfigurationWithActionColumn } from '@/helpers/grid'
import { commandButtons, commandButtonsCantDelete } from './components/commandButtons'
import ChangeStatusDialogComponent from '@/components/actions/ActionDialog/ChangeStatusDialogComponent.vue'
import BillableDialogComponent from '@/components/actions/ActionDialog/BillableDialogComponent.vue'
import NoBillableDialogComponent from '@/components/actions/ActionDialog/NoBillableDialogComponent.vue'

const actionsModule: string = ModuleNamespaces.ACTIONS
const configurationModule: string = ModuleNamespaces.CONFIGURATION

@Component({
  components: {
    GridTableComponent,
    ChangeStatusDialogComponent,
    BillableDialogComponent,
    NoBillableDialogComponent
  }
})
export default class AllActionsView extends Mixins(
  ActionsMixin,
  ListViewMixin,
  LexnetMixin,
  ArchivedFilterMixin,
  RemoveActionMixin
) {
  @Prop({
    type: String
  })
  listNames!: ListNames

  @Prop({
    type: String
  })
  actionMenu!: ActionMenu

  @Action('fetchActions', { namespace: actionsModule })
  fetchActions: ({}) => []

  @Action('saveSelectedActionType', { namespace: actionsModule })
  selectedActionType: (type: string) => void

  @Action('rememberUserCustomConfigurationList', { namespace: configurationModule })
  rememberUserCustomConfigurationList: (flag: boolean) => {}

  @Getter('getLastListConfiguration', { namespace: configurationModule })
  lastListConfiguration: object

  actionDescription = ''

  idActionsArchivedContextMenu = 'actionsArchivedContextMenuComponent'

  useServerPagination = true

  serverSideData: DataResult | object = []

  serverSideDataExport: object = []

  gridSelection = { selectedRows: [] }

  changeStatusDialog = false
  billableDialog = false
  noBillableDialog = false

  get toolbarOptionsCustom() {
    const { canSave } = this.viewPermission
    const { filters } = this.toolbarOptionsTooltipTexts
    return [
      searchToolbarItem,
      filterToolbarItem,
      ...(canSave
        ? [
            {
              id: 'customToolbarContextMenu',
              text: this.$t('components.grid_table.tooltip.new_action'),
              align: 'right',
              tooltipText: this.$t('components.grid_table.tooltip.new_action'),
              cssClass: 'lf-btn-model2',
              contextMenuItems: this.actionsContextMenu
            }
          ]
        : [{}]),
      columnsChooserToolbarItem,
      {
        id: ActionName.CUSTOM_TOOLBAR_CONTEXT_MENU,
        align: 'Right',
        cssClass: 'lf-btn-kebak',
        prefixIcon: Icons.KEBAB,
        contextMenuItems: [
          ...(!this.isPortalUser
            ? [
                {
                  id: ActionName.ARCHIVED,
                  iconCss: Icons.FILTER,
                  text: filters,
                  items: this.selectActionsArchiveOptions
                }
              ]
            : []),
          {
            id: ActionName.PRINT,
            iconCss: Icons.PRINT,
            text: this.$t('components.grid_table.tooltip.print').toString()
          },
          {
            id: ActionName.EXPORT,
            iconCss: Icons.DOWNLOAD,
            text: this.$t('components.grid_table.tooltip.export').toString()
          }
        ]
      }
    ]
  }

  get gridConfiguration() {
    const config = JSON.parse(this.listConfiguration['Config'])
    const { canDelete } = this.viewPermission
    const commandBtns: CommandModel[] = canDelete
      ? commandButtons
      : commandButtonsCantDelete
    const gridConfig = gridConfigurationWithActionColumn(config, commandBtns)
    return JSON.stringify(gridConfig)
  }

  async created() {
    try {
      await this.fetchCurrentListConfiguration(this.listNames)
      await this.loadActionsData()
      this.initializeArchivedSelectedOption({ selectedOption: 0, contextMenu: this.idActionsArchivedContextMenu })
      this.selectedActionType(this.actionType)
      this.saveSelectedEntityName(this.actionMenu)
      this.hideSpinnerLayerAction()
    } catch (error) {}
  }

  async loadActionsData() {
    if (this.useServerPagination) {
      const jsonConf = this.parseUserConfig(this.actionType)
      await this.fetchActions({ ActionType: this.actionType, json: jsonConf })
      this.serverSideData = this.formatDataPaginated(this.actionsData)
    } else {
      const jsonConf = {
        idType: this.actionType,
        archived: this.archivedSelectedOption,
        search: (this as any).lastListConfiguration.searchSettings
      }
      await this.fetchActions({ ActionType: this.actionType, json: JSON.stringify(jsonConf) })
      this.serverSideData = this.formatData(this.actionsData)
    }
  }

  parseUserConfig(idType: string) {
    let pageSettings
    let sortSettings
    let searchSettings
    let filterSettings
    const sortColumns: any = []
    const jsonConfig: any = {}

    if (Object.keys(this.lastListConfiguration).length) {
      pageSettings = (this as any).lastListConfiguration.pageSettings
      sortSettings = (this as any).lastListConfiguration.sortSettings
      searchSettings = (this as any).lastListConfiguration.searchSettings
      filterSettings = (this as any).lastListConfiguration.filterSettings
      jsonConfig['search'] = searchSettings
    } else {
      pageSettings = JSON.parse((this as any).listConfiguration['Config'])['pageSettings']
      sortSettings = JSON.parse((this as any).listConfiguration['Config'])['sortSettings']
      searchSettings = JSON.parse((this as any).listConfiguration['Config'])['searchSettings']
      filterSettings = JSON.parse((this as any).listConfiguration['Config'])['filterSettings']
    }
    jsonConfig['idType'] = idType
    jsonConfig['archived'] = this.archivedSelectedOption
    jsonConfig['pageSize'] = pageSettings?.pageSize ? pageSettings.pageSize : 10
    jsonConfig['page'] = this.rememberUserConfig && pageSettings ? pageSettings.currentPage : 0
    jsonConfig['filter'] = this.rememberUserConfig ? filterSettings : []
    if (sortSettings && sortSettings.length > 0) {
      sortSettings.forEach((sortColumn: any) => {
        const column: any = {}
        column['column'] = sortColumn.field
        column['order'] = sortColumn.direction === 'Ascending' ? 'asc' : 'desc'
        sortColumns.push(column)
      })
    }
    jsonConfig['columnOrder'] = sortColumns

    if (searchSettings !== undefined && this.rememberUserConfig) {
      jsonConfig['search'] = searchSettings
    }

    return JSON.stringify(jsonConfig)
  }

  async gridActionChange(serverSideParams: any) {
    const ssp = JSON.parse(serverSideParams)
    ssp['idType'] = this.actionType
    ssp['archived'] = this.archivedSelectedOption
    await this.fetchActions({ ActionType: this.actionType, json: JSON.stringify(ssp) })
    this.serverSideData = this.formatDataPaginated(this.actionsData)
  }

  async gridExportData(serverSideParams: any) {
    const ssp = JSON.parse(serverSideParams)
    ssp['idType'] = this.actionType
    ssp['archived'] = this.archivedSelectedOption
    await this.fetchActions({ ActionType: this.actionType, json: JSON.stringify(ssp) })
    this.serverSideDataExport = this.formatData(this.actionsData)
  }

  async onToolbarClicked(args: any) {
    const target = args.originalEvent.target.closest('button')
    if (target && target.id === 'add') {
      this.trackClickAdd()
      args.cancel = true
      if (this.actionType === ActionTypes.ALL) {
        this.toggleContextMenu(args.originalEvent)
      } else if (this.actionType === ActionTypes.LEXNET) {
        this.openLexnetInNewWindow()
      } else {
        await this.openAction(this.actionType as ActionTypes)
      }
    } else if (target && target.id === ActionName.ARCHIVED) {
      args.cancel = true
      this.toggleArchivedContextMenu(args.originalEvent, this.idActionsArchivedContextMenu)
    }
  }

  trackClickAdd() {
    trackEvent(TrackerEvents.CREATE_ACTION)
    if (this.actionType === ActionTypes.EMAILS) {
      trackEvent(TrackerEvents.CREATE_MAIL_ACTION)
    }
  }

  async onActionsArchivedClick(args: any) {
    this.archivedSelectedOption = args.item.actionType
    this.changeContextMenuIcons(args.item.actionType, this.idActionsArchivedContextMenu)
    this.rememberUserCustomConfigurationList(true)
    await this.saveConfig(this.archivedSelectedOption)
    await this.loadActionsData()
  }

  async onActionAddClick(args: any) {
    const { item } = args
    const { id } = item

    if (args.item.actionType === ActionTypes.EMAILS) {
      trackEvent(TrackerEvents.CREATE_MAIL_ACTION)
    }
    if (args.item.actionType === ActionTypes.LEXNET) {
      this.openLexnetInNewWindow()
      return
    }
    if (id?.includes(ActionName.ARCHIVED)) {
      if (!this.isArchiveContextMenuFirstLevel(id)) {
        this.onActionsArchivedClick(args)
      }
      return
    }
    trackEvent(TrackerEvents.CREATE_ACTION)
    await this.openAction(args.item.actionType)
  }

  async openAction(actionType: ActionTypes, selectedRegisterId = '', target = '_self') {
    let routeData: {} | null = null
    const selectedRegisterParam = selectedRegisterId || this.$t('views.selected_register.new').toString()
    this.selectedActionType(actionType)
    switch (actionType) {
      case ActionTypes.CALLS:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.CALLS}`,
          params: { selectedRegisterId: selectedRegisterParam, from: URLS.ACTION_ALL }
        })
        break
      case ActionTypes.MEETINGS:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.MEETINGS}`,
          params: { selectedRegisterId: selectedRegisterParam, from: URLS.ACTION_ALL }
        })
        break
      case ActionTypes.PROCEDURES:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.PROCEDURES}`,
          params: { selectedRegisterId: selectedRegisterParam, from: URLS.ACTION_ALL }
        })
        break
      case ActionTypes.TASK:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.ACTION_TASKS}`,
          params: { selectedRegisterId: selectedRegisterParam, from: URLS.ACTION_ALL }
        })
        break
      case ActionTypes.INTERNAL_MANAGEMENT:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.ACTION_INTERNAL_MANAGEMENT}`,
          params: { selectedRegisterId: selectedRegisterParam, from: URLS.ACTION_ALL }
        })
        break
      case ActionTypes.OTHERS:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.ACTION_OTHERS}`,
          params: { selectedRegisterId: selectedRegisterParam, from: URLS.ACTION_ALL }
        })
        break
      case ActionTypes.LEXNET:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.ACTION_LEXNET}`,
          params: { selectedRegisterId: selectedRegisterParam, from: URLS.ACTION_ALL }
        })
        break
      case ActionTypes.EMAILS:
        if (selectedRegisterId) {
          routeData = this.$router.resolve({
            name: `${URLS.ACTIONS}-${URLS.ACTION_EMAILS}`,
            params: { selectedRegisterId: selectedRegisterParam, from: URLS.ACTION_ALL }
          })
        } else {
          routeData = this.$router.resolve({ name: `${URLS.EMAIL}-${URLS.NEW}` })
          target = '_blank'
        }
        break
    }
    if (routeData && target === '_blank') {
      window.open((routeData as any).href, '_blank')
    } else {
      this.$router.push((routeData as any).resolved)
    }
  }

  async onContextMenuClicked(args: any, selectedRegister: any) {
    if (!args.item) {
      return
    }
    const { item } = args
    switch(item.text) {
      case this.$t('components.context_menu.remove'):
        trackEvent(TrackerEvents.REMOVE_ACTION)
        this.confirmRemove(selectedRegister)
        args.cancel = true
        break
      case this.$t('components.context_menu.edit'):
      case this.$t('components.context_menu.look'):
        trackEvent(TrackerEvents.EDIT_ACTION)
        args.cancel = true
        await this.openAction(selectedRegister.typeId, selectedRegister.actionId)
        break
      case this.$t('components.context_menu.open_window'):
        trackEvent(TrackerEvents.EDIT_ACTION)
        args.cancel = true
        await this.openAction(selectedRegister.typeId, selectedRegister.actionId, '_blank')
        break
      case this.$t('components.context_menu.open'):
      case this.$t('components.context_menu.open_lf_mail'):
        trackEvent(TrackerEvents.EDIT_ACTION)
        trackEvent(TrackerEvents.OPEN_LEFEBVRE_MAIL)
        args.cancel = true
        let url = `/${URLS.EMAIL}/new/action`
        const target = '_blank'
        if (this.contextMenuSelectedRegister) {
          url = `/${URLS.EMAIL}/edit/${this.contextMenuSelectedRegister.actionId}`
        }
        window.open(`${url}`, target)
        break
      case this.$t('components.context_menu.open_lexnet'):
        trackEvent(TrackerEvents.EDIT_ACTION)
        args.cancel = true
        this.openInLexnet(selectedRegister)
        break
      case this.contextMenuOptionsTexts.changeStatus:
        this.changeStatus()
        break
      case this.contextMenuOptionsTexts.billable:
        if (item.items.length === 0) {
          this.billAction()
        }
        break
      case this.contextMenuOptionsTexts.noBillable:
        this.billAction(false)
        break
    }
  }

  changeStatus() {
    this.changeStatusDialog = true
  }

  billAction(billable = true) {
    if (billable) {
      this.billableDialog = true
      return
    }
    this.noBillableDialog = true
  }

  async onCellClicked(selectedRegister: any, cancel: boolean) {
    if (!cancel) {
      trackEvent(TrackerEvents.EDIT_ACTION)
      cancel = true
      await this.openAction(selectedRegister.typeId, selectedRegister.actionId)
    }
  }

  commandClick({ commandColumn, rowData }: CommandClickEventArgs) {
    const { id } = commandColumn
    const action = rowData as ActionInterface
    if (id === ActionName.OPEN_NEW_TAB) {
      trackEvent(TrackerEvents.EDIT_ACTION)
      this.openAction(action.typeId, action.actionId, '_blank')
      return
    }
    if (id === ActionName.REMOVE) {
      trackEvent(TrackerEvents.REMOVE_ACTION)
      this.confirmRemove(action)
    }
  }

  confirmRemove(action: ActionInterface) {
    this.showDialog({
      type: DialogTypes.INFO,
      message: this.getRemoveConfirmMessageFromAction(action),
      action: async () => {
        await this.removingAction(action)
        this.refreshGrid()
      },
      mainButtonText: this.$t('action_buttons.remove'),
      secondaryButtonText: this.$t('action_buttons.cancel')
    })
  }

  onSelectedRecords(selectedRecords: any) {
    this.gridSelection.selectedRows = selectedRecords
  }

  onStatusDialogClose({refresh}: {refresh: boolean}) {
    this.changeStatusDialog = false
    if (refresh) {
      this.refreshGrid()
    }
  }

  onBillableDialogClose({refresh}: {refresh: boolean}) {
    this.billableDialog = false
    if (refresh) {
      this.refreshGrid()
    }
  }

  onNoBillableDialogClose({refresh}: {refresh: boolean}) {
    this.noBillableDialog = false
    if (refresh) {
      this.refreshGrid()
    }
  }

  refreshGrid() {
    ;(this as any).$refs.grid.refresh()
  }

  onCustomContextMenuBeforeOpen(contextMenuModule: any, contextMenuGridObject: any, selectedRegister: any) {
    this.permissionContextMenuItems(contextMenuModule, contextMenuGridObject, selectedRegister)
    this.hideLexnetContextMenuItems(contextMenuModule, contextMenuGridObject, selectedRegister)
    this.hideSubActionBillableContextMenuItems(
      false,
      contextMenuModule,
      contextMenuGridObject,
      selectedRegister
    )
  }

}
</script>
<style lang="scss">
.e-menu-hide {
  display: none !important;
}
</style>
