// https://learn.microsoft.com/en-us/clarity/setup-and-installation/cookie-consent
import Clarity from '@microsoft/clarity'
import { Clarity as ClarityInterface } from './types'
import Vue, { VueConstructor } from 'vue'

const clarity: ClarityInterface  = {
  init: () => {
    if (clarity.isset()) {
      return
    }

    const id = process.env.VUE_APP_CLARITY_ID
    if (id) {
      Clarity.init(id)
    }
  },
  consent: () => {
    if (clarity.isset()) {
      Clarity.consent()
    }
  },
  isset: () => {
    return (window as any).clarity ? true : false
  } 
}

const clarityPlugin = {
  install: (vue: VueConstructor) => {
    // INFO: Uncomment if you need to initialise on page load
    // clarity.init()
    vue.prototype.$clarity = clarity
  }
}

export const init = () => {
  Vue.prototype.$clarity.init()
}

export const consent = () => {
  Vue.prototype.$clarity.consent()
}

export default clarityPlugin
