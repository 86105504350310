import { URLS } from '@/router/routes/urlRoutes'
import SsoPaymentsView from '@/views/anotherApplications/SsoPaymentsView.vue'
import SsoUnpaidView from '@/views/anotherApplications/SsoUnpaidView.vue'

export const externalApplicationsRoutes = [
  {
    path: `/${URLS.ANOTHER_APPLICATIONS}/${URLS.SSO_PAYMENTS}`,
    name: `${URLS.ANOTHER_APPLICATIONS}-${URLS.SSO_PAYMENTS}`,
    component: SsoPaymentsView,
    meta: {
      requiresAuth: true,
      title: URLS.SSO_PAYMENTS
    }
  },
  {
    path: `/${URLS.ANOTHER_APPLICATIONS}/${URLS.SSO_UNPAID}`,
    name: `${URLS.ANOTHER_APPLICATIONS}-${URLS.SSO_UNPAID}`,
    component: SsoUnpaidView,
    meta: {
      requiresAuth: true,
      title: URLS.SSO_UNPAID
    }
  }
]
