// https://www.cookiebot.com/en/developer/
import { addInlineJS, addExternalCSS } from '@/helpers/html'
import { Cookiebot, CookiebotConsents } from './types'
import { consent as clarityConsent, init as clarityInit} from '@/plugins/clarity'

const cookiebot: Cookiebot = {
  init: (Vue: any, options: any = {}) => {
    if (process.env.VUE_APP_COOKIEBOT_ID) {
      addExternalCSS(process.env.VUE_APP_COOKIEBOT_CSS)

      addInlineJS(process.env.VUE_APP_COOKIEBOT_URL, '', {
        id: 'Cookiebot',
        'data-cbid': process.env.VUE_APP_COOKIEBOT_ID,
        'type': 'text/javascript',
        async: true
      })

      Vue.prototype.$cookiebot = options
    }
  },
  getConsents: () => {
    const cookiebotConsents: CookiebotConsents = (window as any).Cookiebot || {}
    return cookiebotConsents
  },
  events: () => {
    // Accept dialog button
    window.addEventListener('CookiebotOnAccept', function () {
        // Do something
    }, false)

    // The event is triggered at the same time as the window.onload event when the user’s consent has been loaded
    window.addEventListener("CookiebotOnLoad", () => {
        // Do something
    }, false)

    // The event is triggered when the user’s consent state is ready
    window.addEventListener("CookiebotOnConsentReady", () => {
        if (cookiebot.checkStatisticsConsent()) {
          clarityInit()
          clarityConsent()
        } 
    }, false)
  },
  checkStatisticsConsent: () => {
    const cookiebotConsents = cookiebot.getConsents()
    return cookiebotConsents.consent && cookiebotConsents.consent.statistics
  }
}

const cookiebotPlugin = {
  install: (Vue: any, options: any = {}) => {
    cookiebot.init(Vue, options)
    cookiebot.events()
  }
}

export default cookiebotPlugin
