<template lang="pug">
  div(class="file-manager-container")
    DocsIAToastLauncherComponent(v-if="addDocsIALogic")
    SpinnerLayerComponent(
        v-if="fileManagerSpinner"
        class="spinner-layer-file-manager-component"
        :loadingText="spinnerText.actual"
      )
    div(class="wrapper" v-if="!isOpeningFile")
      ejs-filemanager(
        ref="fileManager"
        id="file-manager"
        v-show="!showDocumentDetail && !showFolderDetail"
        :locale="locale"
        :view="view"
        :allowDragAndDrop="true"
        :ajaxSettings="ajaxSettings"
        :uploadSettings="uploadSettings"
        :detailsViewSettings="detailsViewSettings"
        :contextMenuSettings="contextMenuSettings"
        :toolbarSettings="toolbarSettings"
        :created="onFileManagerCreated"
        :beforeSend="onFileManagerBeforeSend"
        :beforeImageLoad="onFileManagerBeforeImageLoad"
        :beforePopupOpen="onFileManagerBeforeDialogOpen"
        :success="onFileManagerAfterSuccess"
        :failure="onFileManagerFailure"
        :fileDragStart="onFileManagerDragStart"
        :fileDragStop="onFileManagerDragStop"
        :fileDragging="onFileManagerDragging"
        :fileLoad="onFileLoad"
        :fileSelect="onFileSelect"
        :fileOpen="onFileOpen"
        :menuClick="onContextMenuClick"
        :menuOpen="onContextMenuOpen"
        :toolbarCreate="onToolbarCreate"
        :toolbarClick="onToolbarClick"
        :popupOpen="onPopupOpen",
        :popupClose="onPopUpClose"
        :height="height"
        :path="path"
        :selectedItems="selectedItems"
        :sortBy="sortBy"
        :sortOrder="sortOrder"
      )

      DocumentOnline

      DropFolderComponent(
        v-if="canDropFolder"
        :fileManagerInstance="fileManagerInstance"
        :maxFileUploadSize="maxFileUploadSize"
        :allowedExtensions="allowedExtensions"
        :basePath="currentBasePath"
        :idEntity="idEntity"
        :idEntityType="idEntityType"
        :idStage="idStage"
        :cwd="cwd || {}"
        @cantDropFolders="onCantDropFolders"
        @cantDropFiles="onCantDropFiles"
        @cantUploadsFilesHere="onCantUploadsFilesHere"
        @cantUploadsFoldersHere="onCantUploadsFoldersHere"
        @droppingFolders="droppingFolders=true"
        @droppingFiles="droppingFolders=true"
        @cancelUpload="onCancelUploadFolders"
        @uploadOk="onFoldersUploadOk"
        @dropToZipNotSupported="onDropToZipNotSupported"
      )

      TemplateSelectorComponent(
        v-if="!isTemplateMode && idEntityType"
        :showDialog="showTemplateDialog"
        :idEntityType="idEntityType"
        @closeTemplateDialog="closeTemplateDialog"
        @generateTemplateDialog="generateTemplateDialog"
      )

      FileManagerEditDocument(
        v-if="showDocumentDetail && !isTemplateMode"
        :idDocument="idDocument"
        :idEntity="idEntity"
        :idEntityType="idEntityType"
        :idEntityTypeOld="idEntityTypeOld"
        :source="documentSource"
        :firmaStatus="firmaStatusDoc"
        :basePath="currentDocumentFolderPath"
        :editDocumentMode="editMode"
        :allDataLoaded="everythingLoaded"
        :permissionsEntity="permissionsEntity"
        @close="onCloseDocumentDetail"
        @on-save-document-ok="onSaveDocumentOk"
        @on-save-document-fail="onSaveDocumentFail"
        @on-save-intervener-ok="onSaveIntervenerOk"
        @on-save-v-fail="onSaveIntervenerFail"
        @on-remove-document="onDocumentRemoveAction"
        @showForm="onShowForm"
        @documentInitForm="onDocumentInitForm"
      )

      FileManagerEditFolder(
        v-if="showFolderDetail"
        :idDocument="idDocument"
        :idEntity="idEntity"
        :idEntityType="idEntityType"
        :nameDocument="documentName"
        :basePath="currentDocumentFolderPath"
        :editDocumentMode="editMode"
        :allDataLoaded="everythingLoaded"
        :permissionsEntity="permissionsEntity"
        @close="onCloseDocumentDetail"
        @on-save-document-ok="onSaveFolderOk"
        @on-save-document-fail="onSaveDocumentFail"
        @showForm="onShowForm"
      )

      FileManagerEditTemplate(
        v-if="showDocumentDetail && isTemplateMode"
        :idDocument="idDocument"
        :idEntity="idEntity"
        :idEntityType="idEntityType"
        :templateType="idEntityTypeAdditional"
        :basePath="currentBasePath"
        :id="templateId"
        :context="templateModeContext"
        @close="onCloseDocumentDetail"
        @on-save-document-ok="onSaveDocumentOk"
        @on-save-document-fail="onSaveDocumentFail"
        @on-save-intervener-ok="onSaveIntervenerOk"
        @on-save-v-fail="onSaveIntervenerFail"
        @on-remove-document="onDocumentRemoveAction"
        @showForm="onShowForm"
        @documentInitForm="onDocumentInitForm"
        )

      ejs-contextmenu(
        ref="documentContextMenu"
        id="action-add-document-context-menu"
        v-show="!showDocumentDetail"
        :items="actionAddContextMenuDocumentItems"
        :select="onActionAddClick"
        :beforeOpen="onBeforeOpenContextMenu"
      )

      ejs-contextmenu(
        ref="templateContextMenu"
        id="action-add-template-context-menu"
        v-show="!showDocumentDetail"
        :items="actionAddContextMenuTemplateItems"
        :select="onActionAddClick"
        :beforeOpen="onBeforeOpenContextMenu"
      )

      ejs-contextmenu(
        ref="msOfficeConfigigurationContextMenu"
        id="action-msoffice-configuration-context-menu"
        class="lf-context-menu-options"
        v-show="!showDocumentDetail"
        :items="msOfficeConfigurationContextMenuItems"
        :select="onOpenMSOfficeConfigClick"
        :beforeOpen="onBeforeOpenMSOfficeConfigContextMenu"
      )

      PromptDialogComponent(
        :title="saveAsZipText"
        :isOpened="openedSaveAsZipDialog"
        :formSchema="saveAsZipDialogFormSchema"
        :context="context"
        :formFieldValues="zipFormData"
        @execute-action="executeSaveAsZipDialogAction"
      )

      PreviewComponent(
        ref="preview"
        :documentPreview="{ id: 0, type: null }"
        :fileManagerInstance="fileManagerInstance"
      )

    div(v-else)
      div(class="file-manager-message") {{openingFileText}}
  </template>
<script lang="ts">
import Vue from 'vue'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import {
  DetailsView,
  FileManagerPlugin,
  NavigationPane,
  Toolbar,
  ContextMenuSettingsModel,
  ColumnModel,
  ToolbarCreateEventArgs,
  FileManagerComponent as FileManager,
  sortComparer
} from '@syncfusion/ej2-vue-filemanager'
import {
  DownloadZipResult,
  EditDocumentMode,
  File,
  fileManagerFields,
  fileManagerFolderType,
  fileManagerMode,
  fileManagerOpers,
  MaxFileUploadSize
} from '@/store/modules/fileManager/fileManagerTypes'
import store from '@/store/store'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import DocsIAToastLauncherComponent from './DocsIAToastLauncherComponent.vue'
import DocumentOnline from '@/components/DocumentOnline/DocumentOnlineComponent.vue'
import DropFolderComponent from '@/components/FileManager/DropFolderComponent.vue'
import FileManagerEditDocument from '@/components/FileManager/FileManagerEditDocumentComponent.vue'
import FileManagerEditFolder from '@/components/FileManager/FileManagerEditFolderComponent.vue'
import FileManagerEditTemplate from '@/components/FileManager/FileManagerEditTemplateComponent.vue'
import TemplateSelectorComponent from '@/components/template/TemplateSelectorComponent/TemplateSelectorComponent.vue'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { documentOnlineActions } from '@/store/modules/documentOnline/documentOnlineTypes'
import { URLS } from '@/router/routes/urlRoutes'
import { authLexonPartnerId } from '@/store/modules/auth/authTypes'
import PromptDialogComponent from '@/components/Dialog/PromptDialogComponent.vue'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { Icons } from '@/icons/icons'
import { ContextMenuComponent, MenuItemModel } from '@syncfusion/ej2-vue-navigations'
import { CustomDialogComponentName, CustomDialogData, DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { SyncfusionService } from '@/services/SyncfusionService'
import { isSafari } from '@/helpers/navigator'
import { createLink } from '@/helpers/blob'
import { openLefebvrePdfViewer } from '@/helpers/pdfViewer'
import { ITemplate } from '@/store/modules/template/templateTypes'
import PreviewComponent from '@/components/FileManager/Preview/PreviewComponent.vue'
import { DocumentPreview, DocumentPreviewType } from '@/components/FileManager/Preview/types/PreviewTypes'
import {
  FirmaContextMenuId,
  OpenMSOffice,
  DetailsViewSettings,
  EditContextMenuId,
  SendContextMenuId,
  DownloadContextMenuId,
  HeaderColumn,
  PublishData,
  FinishUploadData
} from '@/components/FileManager/types/FileManagerTypes'
import { EmailProvider, emailEndpoints, emailProviderName } from '@/store/modules/lexbox/lexboxTypes'
import { LexnetContextMenuId } from '@/components/Lexnet/types/LexnetTypes'
import {
  allowedFirmaExtensions,
  FirmaRequestStatus,
  FirmaRequestTypes,
  FirmaSignInfo
} from '@/store/modules/firma/firmaTypes'
import { MainService } from '@/services/MainService'
import LexnetMixin from '@/mixins/LexnetMixin.vue'
import { RepositoryService } from '@/services/RepositoryService'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'
import { TranslateResult } from 'vue-i18n'
import OpenMSOfficeFiles from '@/mixins/OpenMSOfficeFilesMixin.vue'
import { entityToHtml } from '@/helpers/html'
import { securityHeaders } from '@/plugins/axios/http'
import { getListStringByProp } from '@/helpers/array'
import { GridConfiguration } from '../grids/LfGrid/LfGridTypes'
import { TrackerEvents, trackEvent } from '@/plugins/tracker'

const authModule = ModuleNamespaces.AUTH
const configurationModule = ModuleNamespaces.CONFIGURATION
const dialogModule: string = ModuleNamespaces.DIALOG
const documentOnlineModule = ModuleNamespaces.DOCUMENT_ONLINE
const entitiesModule: string = ModuleNamespaces.ENTITIES
const expedientsModule: string = ModuleNamespaces.EXPEDIENTS
const fileManagerModule = ModuleNamespaces.FILE_MANAGER
const firmaModule: string = ModuleNamespaces.FIRMA
const formsModule: string = ModuleNamespaces.FORMS
const lexboxModule: string = ModuleNamespaces.LEXBOX
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER
const spinnerModule: string = ModuleNamespaces.SPINNER
const templateModule: string = ModuleNamespaces.TEMPLATE

Vue.use(FileManagerPlugin)

@Component({
  components: {
    DocsIAToastLauncherComponent,
    DocumentOnline,
    DropFolderComponent,
    FileManagerEditDocument,
    FileManagerEditFolder,
    FileManagerEditTemplate,
    PreviewComponent,
    PromptDialogComponent,
    SpinnerLayerComponent,
    TemplateSelectorComponent
  },
  provide: {
    filemanager: [DetailsView, NavigationPane, Toolbar]
  }
})
export default class FileManagerComponent extends Mixins(LexnetMixin, PermissionsMixin, OpenMSOfficeFiles) {
  @Prop({
    type: String
  })
  basePath!: string

  @Prop({
    type: String,
    default: ''
  })
  initialBasePath: string

  @Prop({
    type: String,
    default: ''
  })
  initialPath: string

  @Prop({
    type: String
  })
  mainFolderDescription!: string

  @Prop({
    type: Number
  })
  idMainEntity!: number

  @Prop({
    type: Number
  })
  idMainEntityType!: number

  @Prop({
    type: String,
    default: ComponentWhereIsRendered.TABS_VIEW
  })
  alertWhereIsReview!: string

  @Prop({
    type: String,
    default: '650'
  })
  height: string

  @Prop({
    type: Number,
    default: 0
  })
  idEntityTypeBase: number

  @Prop({
    type: String,
    required: true
  })
  context!: string

  @Prop({
    type: Boolean,
    required: true
  })
  canDropFilesOnMainFolder: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  canCreateFoldersOnMainFolder: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  canDropFolder: boolean

  @Prop({
    type: Number,
    default: fileManagerMode.DOCUMENT
  })
  mode: number

  @Prop({
    type: Number,
    default: 14
  })
  permissionsEntity: number

  @Getter('getLastRefreshTokenTime', { namespace: authModule }) lastRefreshTokenTime: number
  @Getter('getAuthToken', { namespace: authModule }) authToken: string
  @Getter('getCompanyTemplate', { namespace: authModule }) hasCompanyTemplate: boolean
  @Getter('getCompanyId', { namespace: authModule }) selectedCompanyId: number
  @Getter('getFile', { namespace: fileManagerModule }) blobFile: Blob
  @Getter('getFileToken', { namespace: documentOnlineModule }) fileToken: string
  @Getter('getRequestToken', { namespace: documentOnlineModule }) requestToken: string
  @Getter('getRequestTokenTtl', { namespace: documentOnlineModule }) requestTokenTtl: string
  @Getter('getFileAction', { namespace: documentOnlineModule }) fileAction: string
  @Getter('getMaxFileUploadSize', { namespace: fileManagerModule }) maxFileUploadSize: MaxFileUploadSize
  @Getter('getAllowedExtensions', { namespace: fileManagerModule }) allowedExtensions: string
  @Getter('getDocument', { namespace: fileManagerModule }) document: any
  @Getter('getExpedientStageState', { namespace: expedientsModule }) expedientStageState: string | number
  @Getter('getCurrentListConfigurationContext', { namespace: configurationModule }) listConfigurationContext: (
    context: string
  ) => GridConfiguration
  @Getter('getEmailProvider', { namespace: lexboxModule }) emailProvider: EmailProvider
  @Getter('getTemplateId', { namespace: templateModule })
  selectedTemplateId: number
  @Getter('getZipResult', { namespace: fileManagerModule }) zipResult: DownloadZipResult
  @Getter('getTemplateTree', { namespace: templateModule })
  tree: ITemplate[]
  @Getter('getIsPortalUser', { namespace: authModule })
  isPortalUser: boolean
  @Getter('getIsLexonDmsUser', { namespace: authModule })
  isDMSUser: boolean
  @Getter('userHasFirma', { namespace: authModule })
  userHasFirma: boolean
  @Getter('userHasDocsIA', { namespace: authModule })
  userHasDocsIA: boolean
  @Getter('refreshFileManagerCounter', { namespace: fileManagerModule })
  refreshFileManagerCounterGetter: number
  @Getter('getHasStages', { namespace: expedientsModule })
  expedientHasStagesGetter: Boolean
  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterIdGetter: (context: string) => number

  @Mutation('SAVE_ACTION', { namespace: documentOnlineModule }) setDocumentOnlineAction: (action: string) => void
  @Mutation('FETCH_SELECTED_REGISTER_DATA', { namespace: selectedRegisterModule })
  saveSelectedRegisterData: ({}) => void
  @Mutation('SET_LOADING_FORM_DATA', { namespace: formsModule })
  setLoadingFormData: (loading: boolean) => void
  @Mutation('SAVE_DOCUMENT', { namespace: fileManagerModule })
  saveDocument: (document: any) => void
  @Mutation('SHOW_HIDE_TEMPLATE_GENERATOR', { namespace: templateModule })
  showHideTemplateGenerator: (show: boolean) => void
  @Mutation('SAVE_TEMPLATE_ID', { namespace: templateModule })
  saveTemplateId: (id: number) => void
  @Mutation('SAVE_CURRENT_PATH', { namespace: fileManagerModule })
  saveCurrentBasePath: (basePath: string) => void
  @Mutation('SAVE_PATH', { namespace: fileManagerModule })
  saveCurrentPath: (path: string) => void
  @Mutation('SAVE_FILE_MANAGER_ITEM', { namespace: fileManagerModule })
  saveFileManagerItem: (fileManagerItem: any) => void
  @Mutation('RESET_PARENT_REGISTER_ID', { namespace: selectedRegisterModule })
  resetParentRegisterId: () => void
  @Mutation('SET_SIGN_INFO', { namespace: firmaModule })
  setSignInfo: (info: FirmaSignInfo) => void

  @Action('fetchExpedientsIntervenerFiltered', { namespace: expedientsModule })
  fetchExpedientsIntervener: (idExpedient: {}) => {}
  @Action('downloadFile', { namespace: fileManagerModule }) downloadFile: (id: string) => Blob
  @Action('insertDocument', { namespace: fileManagerModule }) insertDocument: ({}) => void
  @Action('downloadZipResult', { namespace: fileManagerModule }) downloadZipResult: ({}) => void
  @Action('downloadAsZip', { namespace: fileManagerModule }) downloadAsZip: (zipTmpName: string) => void
  @Action('openFile', { namespace: fileManagerModule }) openFile: (id: string) => Blob
  @Action('fetchRequestToken', { namespace: documentOnlineModule }) fetchRequestToken: (params: any) => string
  @Action('fetchAppType', { namespace: documentOnlineModule }) fetchAppType: (params: any) => string
  @Action('saveFileUrl', { namespace: documentOnlineModule }) openOnlineEditor: (fileUrl: string) => void
  @Action('saveFileName', { namespace: documentOnlineModule }) saveFileName: (fileName: string) => void
  @Action('saveFileVersion', { namespace: documentOnlineModule }) saveFileVersion: (fileVersion: string) => void
  @Action('saveFileAction', { namespace: documentOnlineModule }) saveFileAction: (fileAction: string) => void
  @Action('saveFileSize', { namespace: documentOnlineModule }) saveFileSize: (fileSize: number) => void
  @Action('fetchFileToken', { namespace: documentOnlineModule }) fetchFileToken: (idFile: string) => void
  @Action('fetchMaxFileUploadSize', { namespace: fileManagerModule }) fetchMaxFileUploadSize: () => void
  @Action('fetchAllowedExtensions', { namespace: fileManagerModule }) fetchAllowedExtensions: (mode: number) => void
  @Action('fetchDocument', { namespace: fileManagerModule }) fetchDocument: (idDocument: string) => void
  @Action('fetchFolder', { namespace: fileManagerModule }) fetchFolder: (idFolder: number) => void
  @Action('saveSelectedRegisterId', { namespace: selectedRegisterModule }) saveSelectedRegisterId: ({}) => void
  @Action('generateTemplate', { namespace: templateModule }) generateTemplate: ({}) => void
  @Action('fetchCurrentListConfiguration', { namespace: configurationModule })
  fetchCurrentListConfiguration: (alias: string) => {}
  @Action('saveUserCustomConfigurationList', { namespace: configurationModule }) saveListConfiguration: ({}) => void
  @Action('getRootFolder', { namespace: fileManagerModule }) getRootFolder: (basePath: string) => File | null
  @Action('showDialog', { namespace: dialogModule }) showDialog: ({}) => {}
  @Action('saveSelectedIdEntityType', { namespace: entitiesModule })
  saveSelectedIdEntityType: (idEntityType: number) => void
  @Action('saveParentdRegisterId', { namespace: selectedRegisterModule }) saveParentRegisterId: (id: number) => void
  @Action('showCustomDialog', { namespace: dialogModule }) showCustomDialogAction: ({}: CustomDialogData) => {}
  @Action('closeCustomDialog', { namespace: dialogModule }) closeCustomDialog: () => {}
  @Action('fetchSignaturitCredits', { namespace: firmaModule }) checkFirmaCredits: (idDocument: number) => number
  @Action('hideSpinnerLayer', { namespace: spinnerModule }) hideSpinnerLayerAction: () => void
  @Action('fetchEmailProvider', { namespace: lexboxModule }) fetchEmailProvider: () => void
  @Action('closeDialog', { namespace: dialogModule }) closeDialog: () => {}

  urlBase = process.env.VUE_APP_API_URL
  fileManagerSpinner = false
  spinnerText = {
    actual: this.$t('components.spinner.updating_data'),
    download: this.$t('components.spinner.download'),
    receiving: this.$t('components.spinner.receiving_data'),
    sending: this.$t('components.spinner.sending_data'),
    updating: this.$t('components.spinner.updating_data')
  }
  publicStatusText = {
    ok: this.$t('components.file_manager.grid_columns.public_ok'),
    ko: this.$t('components.file_manager.grid_columns.public_ko')
  }
  fileManagerInstance: any = null
  files: File[] = []
  cwd: File | null = null
  showDocumentDetail = false
  showFolderDetail = false
  documentBasePath = ''
  currentBasePath: string = this.basePath
  currentPath = ''
  idDocument = 0
  editMode: EditDocumentMode = EditDocumentMode.uploadEdit

  locale = 'es'
  view = 'Details'

  $refs!: {
    fileManager: InstanceType<typeof FileManager>
    documentContextMenu: InstanceType<typeof ContextMenuComponent>
    templateContextMenu: InstanceType<typeof ContextMenuComponent>
    preview: InstanceType<typeof PreviewComponent>
  }

  refreshTokenInterval = 0

  isRefreshingAccessToken = false

  droppingFolders = false

  selectedItems: string[] = []
  filenameSelected: string | null = null

  editContextMenu: any = []

  ajaxSettings = {
    url: `${this.urlBase}repository/folders/get`,
    getImageUrl: `${this.urlBase}repository/file/%1/get`,
    uploadUrl: `${this.urlBase}repository/file/put`,
    downloadUrl: `${this.urlBase}repository/file/get`
  }

  detailsViewSettingsDocument: DetailsViewSettings | null = null

  detailsViewSettingsAll: DetailsViewSettings | null = null

  contextMenuSettingsItems = {
    file: [
      'Open',
      'Cut',
      'Copy',
      'Edit_Multiple',
      'Paste',
      'Rename',
      'Edit',
      'Send',
      'Lexnet',
      'Firma',
      'DocsIA',
      'CustomDownload',
      'Download_Zip',
      'Delete',
      'Details'
    ],
    folder: [
      'Open',
      'Cut',
      'Copy',
      'Edit_Multiple',
      'Paste',
      'Rename',
      'Edit',
      'Permissions',
      'Send',
      'Download_Zip',
      'Delete'
    ],
    layout: [
      'Paste',
      'Edit_Layout',
      'NewFolder',
      'new_word',
      'new_excel',
      'new_ppoint',
      'upload_document',
      'upload_external_layout'
    ],
    visible: true
  }

  contextMenuSettingsData: ContextMenuSettingsModel = this.contextMenuSettingsItems

  actionAddContextMenuTemplateItems = [
    {
      text: this.$t('components.file_manager.actions.new_folder'),
      iconCss: Icons.FOLDER
    },
    {
      text: this.$t('components.file_manager.actions.new_template'),
      iconCss: Icons.DOCUMENTATION
    }
  ]

  msOfficeConfigurationContextMenuItems = [
    {
      text: this.$t('components.file_manager.actions.config.local'),
      iconCss: Icons.CHECK
    },
    {
      text: this.$t('components.file_manager.actions.config.wopi'),
      iconCss: Icons.CHECK
    }
  ]

  flagActionInProgress = false

  everythingLoaded = false

  lastFileManagerOper = ''

  idEntity: number = this.idMainEntity ? this.idMainEntity : 0
  idEntityType: number = this.idMainEntityType ? this.idMainEntityType : 0
  idEntityTypeAdditional = 0

  foundFolder: any

  minimSearchCharacters = 3

  isOpeningFile = false
  openingFileText: string = this.$t('views.repository.openingFile').toString()

  initializedBasePath = false

  templateModeContext: string = ContextName.TEMPLATE
  templateDocumentId = 0

  openedSaveAsZipDialog = false
  saveAsZipText = this.$t('components.file_manager.dialogs.save_as_zip_title')
  zipFormData = {}
  zipFolderSelected: any = []

  saveAsZipDialogFormSchema = [
    {
      id: 'name',
      name: 'name',
      type: 'text',
      label: 'Nombre',
      fieldType: 'LexonTextFieldComponent',
      validationRules: 'required|max:255',
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
      'offset-xs': 0,
      'offset-sm': 0,
      'offset-md': 0,
      'offset-lg': 0,
      'offset-xl': 0
    }
  ]

  showTemplateDialog = false

  folderType: number = fileManagerFolderType.DOCUMENT

  fileManagerPath = '/'

  signPending: string[] = []
  inSignaturit: string[] = []
  firmaStatusDoc: number | null = null

  openMSOfficeConfigMode: OpenMSOffice = OpenMSOffice.WOPI

  sortBy = 'name'
  sortOrder = 'Ascending'
  updatedHeaderColumns: HeaderColumn[] = []
  sortComparer: any = sortComparer

  get path() {
    return this.fileManagerPath
  }
  set path(value: string) {
    this.fileManagerPath = value
  }

  get selectedExpedientId() {
    return this.selectedRegisterIdGetter(ContextName.EXPEDIENTS)
  }

  get toolbarSettings() {
    const { canSave } = this.checkEntityPermissionsGetter(this.permissionsEntity)

    if (canSave) {
      return {
        items: ['Details', 'Refresh', 'View', 'Configuration', 'Preview', 'Open', 'Selection', 'Actions'],
        visible: true
      }
    }
    return {
      items: ['Details', 'Refresh', 'View', 'Preview', 'Open', 'Selection'],
      visible: true
    }
  }

  get actionAddContextMenuDocumentItems() {
    return this.hasCompanyTemplate
      ? [
          {
            text: this.$t('components.file_manager.actions.new_folder'),
            iconCss: Icons.FOLDER
          },
          {
            text: this.$t('components.file_manager.actions.upload_lexon_document'),
            iconCss: Icons.UPLOAD
          },
          {
            text: this.$t('components.file_manager.actions.upload_external_link'),
            iconCss: Icons.LINK
          },
          {
            text: this.$t('components.file_manager.actions.new_word_document'),
            iconCss: Icons.WORD
          },
          {
            text: this.$t('components.file_manager.actions.new_excel_document'),
            iconCss: Icons.EXCEL
          },
          {
            text: this.$t('components.file_manager.actions.new_ppoint_document'),
            iconCss: Icons.PPT
          },
          ...(!this.isDMSUser
            ? [
                {
                  text: this.$t('components.file_manager.actions.from_template_document'),
                  iconCss: 'lf-icon-document'
                }
              ]
            : [])
        ]
      : [
          {
            text: this.$t('components.file_manager.actions.new_folder'),
            iconCss: Icons.FOLDER
          },
          {
            text: this.$t('components.file_manager.actions.upload_lexon_document'),
            iconCss: Icons.UPLOAD
          },
          {
            text: this.$t('components.file_manager.actions.upload_external_link'),
            iconCss: Icons.LINK
          },
          {
            text: this.$t('components.file_manager.actions.new_word_document'),
            iconCss: Icons.WORD
          },
          {
            text: this.$t('components.file_manager.actions.new_excel_document'),
            iconCss: Icons.EXCEL
          },
          {
            text: this.$t('components.file_manager.actions.new_ppoint_document'),
            iconCss: Icons.PPT
          }
        ]
  }

  get listName(): string {
    if (this.mode === fileManagerMode.DOCUMENT) {
      if (this.folderType === fileManagerFolderType.DOCUMENT) {
        return ListNames.FILE_MANAGER
      } else {
        return ListNames.FILE_MANAGER_ALL
      }
    } else if (this.mode === fileManagerMode.TEMPLATE) {
      return ListNames.FILE_MANAGER_TEMPLATE
    } else {
      return ''
    }
  }

  get detailsViewSettings() {
    const setting: any =
      this.folderType === fileManagerFolderType.DOCUMENT
        ? this.detailsViewSettingsDocument
        : this.detailsViewSettingsAll
    return this.setSortComparer(setting)
  }

  get templateId() {
    return this.templateDocumentId
  }

  set templateId(id: number) {
    this.templateDocumentId = id
  }

  get isDocumentMode() {
    return fileManagerMode.DOCUMENT === this.mode
  }

  get isTemplateMode() {
    return fileManagerMode.TEMPLATE === this.mode
  }

  get uploadSettings() {
    return {
      autoUpload: true,
      minFileSize: 0,
      maxFileSize: this.maxFileUploadSize ? this.maxFileUploadSize.size : 0,
      allowedExtensions: this.allowedExtensions,
      autoClose: false
    }
  }

  get contextMenuSettings(): ContextMenuSettingsModel {
    if (this.hasCompanyTemplate) {
      this.contextMenuSettingsData.layout = this.isTemplateMode
        ? ['Paste', 'Edit_Layout', 'NewFolder', 'new_template']
        : [
            'Paste',
            'Edit_Layout',
            'NewFolder',
            'new_word',
            'new_excel',
            'new_ppoint',
            'from_template',
            'upload_document',
            'upload_external_link'
          ]
    } else {
      this.contextMenuSettingsData.layout = [
        'Paste',
        'Edit_Layout',
        'NewFolder',
        'new_word',
        'new_excel',
        'new_ppoint',
        'upload_document',
        'upload_external_link'
      ]
    }
    return this.contextMenuSettingsData
  }

  set contextMenuSettings(value: ContextMenuSettingsModel) {
    this.contextMenuSettingsData = value
  }

  get contextMenu(): ContextMenuComponent | null {
    switch (this.mode) {
      case fileManagerMode.DOCUMENT:
        // TODO: Revision 25.1.*
        // return this.$refs.documentContextMenu as ContextMenuComponent
        return (this as any).$refs.documentContextMenu
      case fileManagerMode.TEMPLATE:
        // TODO: Revision 25.1.*
        // return this.$refs.templateContextMenu as ContextMenuComponent
        return (this as any).$refs.templateContextMenu
    }
    return null
  }

  get checkIfTablet() {
    return /Android|webOS|iPhone|Mac|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  }

  get openMSOfficeMode() {
    const config = this.gridConfiguration
    if (config.openMSOffice && config.openMSOffice.length) {
      return config.openMSOffice
    }
    return OpenMSOffice.WOPI
  }

  get idEntityTypeOld(): number {
    return this.document.IdEntityTypeOld ? Number(this.document.IdEntityTypeOld) : 0
  }

  get documentSource(): string {
    return this.document.IdTipoDoc ?? ''
  }

  get documentName(): string {
    return this.document.name ?? ''
  }

  get gridConfiguration() {
    return this.listConfigurationContext(this.listName)
  }

  get addDocsIALogic() {
    return this.userHasDocsIA && !this.isPortalUser && !this.isTemplateMode
  }

  @Watch('maxFileUploadSize')
  setMaxFileUploadSize(maxFileUploadSize: MaxFileUploadSize) {
    this.fileManagerInstance.uploadObj.maxFileSize = maxFileUploadSize.size
    this.fileManagerInstance.uploadObj.l10n.currentLocale.invalidMaxFileSize = this.$t(
      'components.file_manager.advises.max_file_upload_size',
      { size: maxFileUploadSize.sizeFormatted }
    )
  }

  @Watch('allowedExtensions')
  setFileAllowedExtensions(allowedExtensions: string) {
    this.fileManagerInstance.uploadObj.allowedExtensions = allowedExtensions
  }

  @Watch('expedientStageState')
  changeStage() {
    this.toggleStageField()
    if (this.fileManagerInstance && !this.showDocumentDetail) {
      this.refresh()
    }
  }

  @Watch('currentPath')
  changePath(path: string) {
    const file: File | undefined = this.files.find((file: File) => path === `${file.filterPath}${file.name}/`)
    if (file !== undefined) {
      this.idEntity = Number(file.idEntity)
      this.idEntityType = Number(file.idEntityType)
    } else {
      if (this.idMainEntity) {
        this.idEntity = this.idMainEntity
        this.idEntityType = this.idMainEntityType
      } else {
        this.idEntity = this.cwd!.idEntity
        this.idEntityType = this.cwd!.idEntityType
      }
    }
  }

  @Watch('refreshFileManagerCounterGetter')
  refreshFileManager() {
    this.refresh()
  }

  get currentDocumentFolderPath() {
    return this.documentBasePath
  }

  get idStage(): number | null {
    if ([ContextName.EXPEDIENTS.toString(), ContextName.EXPEDIENT_ACTIONS.toString()].indexOf(this.context) >= 0) {
      const stageId: any = this.expedientStageState
      if (stageId) {
        return Number(stageId)
      }
    }
    return null
  }

  isNeedRefreshToken(dateTime: number): boolean {
    const HOUR = 1000 * 55 * 60
    const anHourAgo = Date.now() - HOUR
    return dateTime < anHourAgo
  }

  async onFileManagerBeforeSend(args: any) {
    if (args.ajaxSettings) {
      if (fileManagerOpers.DETAILS === args.action) {
        args.cancel = true
        return
      }

      if (this.authToken) {
        args.ajaxSettings.beforeSend = (arg: any) => {
          arg.httpRequest.setRequestHeader('Authorization', `Bearer ${this.authToken}`)
          arg.httpRequest.setRequestHeader('accept', 'application/json')
          arg.httpRequest.setRequestHeader(authLexonPartnerId, process.env.VUE_APP_LEXON_PARTNER_ID)
          Object.entries(securityHeaders).map(([key, value]) => arg.httpRequest.setRequestHeader(key, value))
        }
      }

      if (this.isNeedRefreshToken(this.lastRefreshTokenTime)) {
        store.dispatch(`${ModuleNamespaces.AUTH}/refreshToken`)
      }

      const data = JSON.parse(args.ajaxSettings.data)

      if (fileManagerOpers.SEARCH === args.action) {
        if (data.searchString.length < this.minimSearchCharacters + 2) {
          args.cancel = true
          return
        }
      }

      if (fileManagerOpers.UPLOAD !== args.action && (!data.data || null === data.data[0])) {
        args.cancel = true
        return
      }

      if (fileManagerOpers.READ === args.action || fileManagerOpers.CREATE === args.action) {
        args.ajaxSettings.url = `${this.urlBase}repository/folders/get`

        if (this.fileManagerInstance) {
          this.folderType = fileManagerFolderType.DOCUMENT
          if (!this.detailsViewSettingsDocument) {
            await this.fetchFileManagerGridConfig()
          }

          this.fileManagerInstance.detailsViewSettings = this.detailsViewSettings
        }

        data.isRootFolder = false

        if (data.path === '/') {
          data.basePath = this.basePath
          data.isRootFolder = true
          data.rootBasePath = this.basePath
          data.data = []
          data.idEntity = this.idMainEntity
          data.idEntityType = this.idMainEntityType
          data.idEntityTypeAdditional = this.idEntityTypeAdditional
          data.checkCreateFolder = this.fileManagerInstance === null
        } else if (data.data && !data.data.basePath && data.data.length && null !== data.data[0]) {
          if ('' !== data.data[0].filterPath) {
            const file = this.files.find((x) => x.uid === data.data[0].uid)
            if (file) {
              data.data[0].basePath = file.basePath
            }
          }
        }

        if (
          fileManagerOpers.READ === args.action &&
          data &&
          data.data &&
          data.data[0] &&
          ('-999' === data.data[0].idFolder || data.data[0].basePath.includes('/ver todos:'))
        ) {
          if (this.fileManagerInstance) {
            this.folderType = fileManagerFolderType.VIEW_ALL
            if (!this.detailsViewSettingsAll) {
              setTimeout(() => {
                this.fetchFileManagerGridConfig()
              })
            }
            this.fileManagerInstance.detailsViewSettings = this.detailsViewSettings
          }
        }
      }

      if (fileManagerOpers.SEARCH === this.lastFileManagerOper) {
        data.foundFolder = this.foundFolder
      }

      if (fileManagerOpers.UPLOAD === args.action) {
        const subData: File | null = this.cwd

        if (this.droppingFolders) {
          this.fileManagerInstance.uploadObj.removing()
          args.cancel = true
          return
        }

        const found = this.files.find((x) => x.basePath === subData!.basePath && x.name === subData!.name)
        if (found !== undefined && !found.isDroppable) {
          if ('' !== subData!.filterPath || ('' === subData!.filterPath && !this.canDropFilesOnMainFolder)) {
            args.cancel = true
            this.$emit('cantUploadsFilesHere')
            return
          }
        }

        if (data[0].path === '/') {
          data.push({ basePath: subData!.basePath })
          data.push({ idEntity: this.idMainEntity ? this.idMainEntity : this.cwd!.idEntity })
          data.push({ idEntityType: this.idMainEntityType ? this.idMainEntityType : this.cwd!.idEntityType })
          data.push({ idEntityTypeAdditional: this.idEntityTypeAdditional })
        } else {
          if (found) {
            data.push({ basePath: found.basePath })
            data.push({ idEntity: found.idEntity })
            data.push({ idEntityType: found.idEntityType })
            data.push({ idEntityTypeAdditional: found.idEntityTypeAdditional })
          }
        }
        data.push({ idStage: this.idStage })
      }

      if (fileManagerOpers.DELETE === args.action) {
        args.ajaxSettings.url = `${this.urlBase}repository/file/delete`
      }

      if (fileManagerOpers.CREATE === args.action && !this.flagActionInProgress) {
        if (data.path && !data.path.toString().includes(':')) {
          if (data.data && data.data.length) {
            data.basePath = data.data[0].basePath
          }
          data.data = []
        }
        this.flagActionInProgress = true
        args.ajaxSettings.url = `${this.urlBase}repository/folder/create`
      }

      if (fileManagerOpers.SEARCH === args.action) {
        data.idEntityTypeBase = this.idEntityTypeBase
      }

      if (fileManagerOpers.MOVE === args.action) {
        args.ajaxSettings.url = `${this.urlBase}repository/files/folders/move`
      }

      if (fileManagerOpers.COPY === args.action) {
        args.ajaxSettings.url = `${this.urlBase}repository/files/folders/copy`
      }

      if (fileManagerOpers.RENAME === args.action) {
        if (data.data[0].isFile) {
          args.ajaxSettings.url = `${this.urlBase}repository/file/update`
        } else {
          args.ajaxSettings.url = `${this.urlBase}repository/folder/update`
        }
      }

      data.idStage = this.idStage

      data.mainFolderDescription = this.mainFolderDescription

      args.ajaxSettings.data = JSON.stringify(data)

      this.lastFileManagerOper = args.action
    }
  }

  async onFileManagerAfterSuccess(data: any) {
    await this.onFileManagerSuccess(data).then(() => {
      setTimeout(() => {
        this.checkIfRenderDescendingIcon(this.sortOrder)
        if (this.addDocsIALogic) {
          this.renderDocsIA()
        }
      }, 250)
    })
  }

  addClickHandlerToDocsIAIcon() {
    const icons = document.querySelectorAll('.docs-ia-icon')
    icons.forEach((icon: Element) => {
      icon.addEventListener('click', () => {
        const documentId = icon.getAttribute('data-attr')
        if (documentId) {
          this.openDocIA(documentId)
        }
      })
    })
  }

  async onFileManagerSuccess(data: any) {
    let basePath
    let allFiles
    let hideSearch = false

    if (this.fileManagerInstance.detailsviewModule.gridObj) {
      this.fileManagerInstance.detailsviewModule.gridObj.element.addEventListener('pointerup', this.onGridEvent)
    }

    if (data.result) {
      let numFiles = 1
      if (data.result.files) {
        numFiles = data.result.files.length
      }

      if (data.result.error && data.result.error.code) {
        this.showErrorAlert(data.action, numFiles)
        return
      } else {
        if (data.action === fileManagerOpers.MOVE) {
          allFiles = data.result.files
            .map((item: any) => {
              return item.isFile
            })
            .every((e: any) => e === true)
          if (!allFiles) {
            data.action = fileManagerOpers.MOVEFOLDERS
          }
        }

        this.showSuccessAlert(data.action, numFiles, data.result.files)
      }

      if (data.result.cwd) {
        basePath = data.result.cwd.basePath
        this.currentBasePath = basePath
        this.cwd = data.result.cwd
      }

      if (data.result.files) {
        if (numFiles && !this.files.length) {
          const file: File = {
            uid: 0,
            name: this.mainFolderDescription,
            basePath,
            filterPath: '/',
            idEntity: this.idMainEntity,
            idEntityType: this.idMainEntityType,
            canSearch: true,
            isFile: false,
            isDroppable: this.cwd!.isDroppable,
            canCreateDocument: this.cwd!.canCreateDocument,
            canCreateFolder: this.cwd!.canCreateFolder,
            canCreateTemplate: this.cwd!.canCreateTemplate
          }
          this.files.push(file)
        }

        if (data.result.cwd && data.result.cwd.idEntityType > 0) {
          this.idEntity = Number(data.result.cwd.idEntity)
          this.idEntityType = Number(data.result.cwd.idEntityType)
          this.idEntityTypeAdditional = Number(data.result.cwd.idEntityTypeAdditional)
        } else if (data.result.files.length) {
          this.idEntity = parseInt(data.result.files[0].idEntity, 10)
          this.idEntityType = parseInt(data.result.files[0].idEntityType, 10)
          this.idEntityTypeAdditional = parseInt(data.result.files[0].idEntityTypeAdditional, 10)
        }

        this.saveSelectedIdEntityType(this.idEntityType)
        this.saveParentRegisterId(this.idEntity)

        this.currentPath = this.fileManagerInstance.path

        for (let i = 0; i < numFiles; i++) {
          const file: File = data.result.files[i]
          const found = this.files.find((x) => x.uid === file.uid)
          if (typeof found === 'undefined') {
            this.files.push(file)
          }
          if (data.action !== fileManagerOpers.SEARCH && !file.isFile) {
            hideSearch = !file.canSearch
          }
        }
      }
      const search: HTMLElement | null = document.getElementById('file-manager_search')
      if (search) {
        ;(search as any).ej2_instances[0].enabled = !hideSearch
      }
    }
    this.fileManagerInstance.uploadObj.selected = this.uploaderSelected

    this.refreshPreview({ id: 0, type: DocumentPreviewType.Unknown })
  }

  onFileManagerFailure(data: any) {
    const { error } = data
    if (error && error.file) {
      data.error.statusText = this.$t('components.file_manager.uploader.fail')
      return
    }

    if (this.isRefreshingAccessToken) {
      store.dispatch(`${ModuleNamespaces.AUTH}/logout`)
      return
    }

    if (['404', '500'].includes(error.code)) {
      this.isRefreshingAccessToken = true
      store.dispatch(`${ModuleNamespaces.AUTH}/refreshToken`).then(() => {
        this.isRefreshingAccessToken = false
        this.fileManagerInstance.refresh()
      })
      return false
    }
  }

  onFileManagerBeforeImageLoad(data: any) {
    const idDocument = data.fileDetails[0].id
    const url = data.imageUrl.replace('%1', idDocument).split('?')[0]
    data.imageUrl = `${url}?authToken=${this.authToken}`
  }

  async onContextMenuClick(args: any) {
    if (args.item.id === this.fileManagerInstance.element.id + '_cm_upload_document') {
      this.editMode = EditDocumentMode.uploadNew
      this.openDocumentDetail()
    }
    if (args.item.id === this.fileManagerInstance.element.id + '_cm_upload_external_link') {
      this.editMode = EditDocumentMode.linkNew
      this.openDocumentDetail()
    }
    if (args.item.id === this.fileManagerInstance.element.id + '_cm_new_word') {
      this.editMode = EditDocumentMode.wordOnlineNew
      this.setDocumentOnlineAction(documentOnlineActions.NEW)
      this.openDocumentDetail()
    }
    if (args.item.id === this.fileManagerInstance.element.id + '_cm_new_excel') {
      this.editMode = EditDocumentMode.excelOnlineNew
      this.setDocumentOnlineAction(documentOnlineActions.NEW)
      this.openDocumentDetail()
    }
    if (args.item.id === this.fileManagerInstance.element.id + '_cm_new_ppoint') {
      this.editMode = EditDocumentMode.powerPointOnlineNew
      this.setDocumentOnlineAction(documentOnlineActions.NEW)
      this.openDocumentDetail()
    }
    if (args.item.id === this.fileManagerInstance.element.id + '_cm_from_template' && this.hasCompanyTemplate) {
      this.showTemplateDialog = this.checkIfOnlyTemplate()
    }
    if (args.item.id === this.fileManagerInstance.element.id + '_cm_details') {
      if (!this.isTemplateMode) {
        this.editMode = args.fileDetails[0].source === 'URL' ? EditDocumentMode.linkEdit : EditDocumentMode.uploadEdit
        this.openDocumentDetail(args.fileDetails[0].id)
      } else {
        this.saveTemplate(args.fileDetails[0])
        this.openTemplateForm()
      }
    }
    if (args.item.id === this.fileManagerInstance.element.id + '_cm_permissions') {
      this.openFolderDetail(args.fileDetails[0].idFolder)
    }
    if (args.item.id === this.fileManagerInstance.element.id + '_cm_docsia') {
      args.cancel = true
      const selectedFileId = args.fileDetails[0].id
      this.openDocIA(selectedFileId)
    }
    if (args.item.id === DownloadContextMenuId.DOWNLOAD_ORIGINAL) {
      args.cancel = true
      this.customDownload(args.fileDetails)
    }
    if (args.item.id === this.fileManagerInstance.element.id + '_cm_new_template' && this.hasCompanyTemplate) {
      this.openTemplateForm(false)
    }
    if (
      args.item.id === this.fileManagerInstance.element.id + '_cm_download_zip' ||
      args.item.id === this.fileManagerInstance.element.id + '_cm_download_zip_portal'
    ) {
      this.downloadAsZipPrompt(args.fileDetails[0])
    }
    if (args.item.id === DownloadContextMenuId.DOWNLOAD_AS_PDF) {
      this.downloadAsPdf(args.fileDetails[0])
    }
    // *** FIRMA SUBMENU *** //
    if (args.item.id === FirmaContextMenuId.CERTIFY_DOCUMENT) {
      this.openFirmaDialog(
        {
          selectedFileName: args.fileDetails[0].name,
          selectedFileId: args.fileDetails[0].id,
          selectedRequestType: FirmaRequestTypes.CERTIFIED_DOCUMENT
        },
        CustomDialogComponentName.CERTIFY_DOCUMENT
      )
    }
    if (args.item.id === FirmaContextMenuId.SEND_FIRMA) {
      this.openFirmaDialog(
        {
          selectedFileName: args.fileDetails[0].name,
          selectedFileId: args.fileDetails[0].id,
          selectedRequestType: FirmaRequestTypes.FIRMA_REQUEST
        },
        CustomDialogComponentName.SEND_TO_FIRMA
      )
    }
    if (args.item.id === FirmaContextMenuId.SEND_CERTIFIED_EMAIL) {
      this.openFirmaDialog(
        {
          selectedFileName: args.fileDetails[0].name,
          selectedFileId: args.fileDetails[0].id,
          selectedRequestType: FirmaRequestTypes.CERTIFIED_EMAIL
        },
        CustomDialogComponentName.SEND_CERTIFIED_EMAIL
      )
    }
    if (args.item.id === FirmaContextMenuId.MONITORING_REVIEW) {
      this.setSignInfo({
        selectedFileName: args.fileDetails[0].name,
        selectedFileId: args.fileDetails[0].id
      })
      this.showCustomDialogAction({
        name: CustomDialogComponentName.MONITORING_AND_AUDITS,
        styles: {
          maxWidth: '1150px'
        }
      })
    }
    if (args.item.id === FirmaContextMenuId.SEND_CERTIFIED_SMS) {
      this.openFirmaDialog(
        {
          selectedFileName: args.fileDetails[0].name,
          selectedFileId: args.fileDetails[0].id,
          selectedRequestType: FirmaRequestTypes.CERTIFIED_SMS
        },
        CustomDialogComponentName.SEND_CERTIFIED_SMS
      )
    }
    // *** FIN FIRMA SUBMENU *** //
    // *** EDIT SUBMENU *** //
    if (args.item.id === EditContextMenuId.CUT) {
      ;(document as any).getElementById('file-manager_cm_cut').click()
    }
    if (args.item.id === EditContextMenuId.COPY) {
      ;(document as any).getElementById('file-manager_cm_copy').click()
    }
    if (args.item.id === EditContextMenuId.PASTE) {
      ;(document as any).getElementById('file-manager_cm_paste').click()
    }
    if (args.item.id === EditContextMenuId.RENAME) {
      ;(document as any).getElementById('file-manager_cm_rename').click()
    }
    // *** FIN EDIT SUBMENU *** //
    // *** SEND SUBMENU *** //
    if (args.item.id === SendContextMenuId.EMAIL) {
      this.sendDocMail(args.fileDetails)
    }
    if (args.item.id === SendContextMenuId.PUBLISH) {
      this.publishActionsModal(args.fileDetails, true)
    }
    if (args.item.id === SendContextMenuId.UNPUBLISH) {
      this.publishActionsModal(args.fileDetails, false)
    }
    // *** FIN SEND SUBMENU *** //
    // *** LEXNET SUBMENU *** //
    if (
      args.item.id === LexnetContextMenuId.PROCEDURE ||
      args.item.id === LexnetContextMenuId.SUBJECT ||
      args.item.id === LexnetContextMenuId.EXECUTION ||
      args.item.id === LexnetContextMenuId.DEFENSE ||
      args.item.id === LexnetContextMenuId.PERSONATION ||
      args.item.id === LexnetContextMenuId.APPEAL
    ) {
      this.showFileManagerSpinner(this.spinnerText.sending)
      await this.getLexNetToken()
      this.openNewEscritoLexnetApp(
        (this as any).getLexnetIdFromName(args.item.id),
        args.fileDetails[0].id,
        args.fileDetails[0].idEntity,
        args.fileDetails[0].idEntityType,
        this.selectedCompanyId
      )
      this.hideFileManagerSpinner()
    }
    // *** FIN LEXNET SUBMENU *** //
  }

  async openFirmaDialog(setSignInfoData: any, name: string) {
    this.showFileManagerSpinner(this.spinnerText.sending)
    try {
      if (await this.checkFirmaCredits(setSignInfoData.selectedFileId)) {
        this.setSignInfo(setSignInfoData)
        this.showCustomDialogAction({
          name
        })
      }
    } catch (error) {
      this.showErrorAlert(fileManagerOpers.OPEN_FIRMA_SERVICE, 0)
    }
    this.hideFileManagerSpinner()
  }

  async actionOpenFile(file: any) {
    this.showFileManagerSpinner(this.spinnerText.receiving)
    if (file.isOnlineEditable) {
      if (!this.isTemplateMode) {
        if (this.openMSOfficeConfigMode === OpenMSOffice.WOPI) {
          this.setDocumentOnlineAction(documentOnlineActions.EDIT)
          await this.editDocumentOnline(file)
        } else {
          await this.openMSOfficeLocal(file)
        }
      } else {
        this.saveTemplate(file)
        this.saveCurrentBasePath(this.currentBasePath)
        this.saveCurrentPath(this.currentPath)
        this.showHideTemplateGenerator(true)
      }
    } else if (file._fm_iconClass === 'e-fe-pdf') {
      const { pdfViewerEnabled } = navigator as any
      if (pdfViewerEnabled) {
        await openLefebvrePdfViewer(file.id)
        this.hideFileManagerSpinner()
        return
      }
      await this.customOpenFile(file)
    } else if (file.source === 'URL') {
      if (!file.url.match(/^https?:\/\//i)) {
        file.url = 'http://' + file.url
      }
      window.open(file.url, '_blank')
    } else {
      if (file._fm_iconClass !== 'e-fe-image' || ['.tif', '.tiff'].includes(file.type.toString().toLowerCase())) {
        await this.customOpenFile(file)
      }
    }
    this.hideFileManagerSpinner()
  }

  openTemplateForm(edit = true) {
    this.saveCurrentBasePath(this.currentBasePath)
    this.saveCurrentPath(this.currentPath)
    this.templateId = edit ? this.selectedTemplateId : 0
    this.showDocumentDetail = true
    this.$emit('openForm')
  }

  async openDocIA(documentId: string) {
    trackEvent(TrackerEvents.DOCS_IA)
    this.showCustomDialogAction({
      name: CustomDialogComponentName.LOADING_DOCS_IA,
      styles: {
        maxWidth: '648px',
        padding: '12px'
      }
    })
    const url = `v2/document/${documentId}/doc-ia-url`
    try {
      const { data } = await new MainService().getData(url)
      const { redirectUrl } = data
      window.open(redirectUrl, '_blank')
    } catch (error) {
      this.showDialog({
        type: DialogTypes.ERROR,
        message: this.$t('components.dialog.file_manager.docs_ia_error'),
        hideSecondaryButton: true
      })
    } finally {
      this.closeCustomDialog()
    }
  }

  saveTemplate(file: any) {
    this.saveTemplateId(Number(file.idEntity))
  }

  async sendDocMail(item: any) {
    await this.fetchEmailProvider()
    if (this.emailProvider !== null) {
      const idDocs: string[] = []
      item.forEach((i: any) => {
        idDocs.push(i.id)
      })
      this.showFileManagerSpinner(this.spinnerText.sending)
      let interveners: object | null = null
      if (this.context === ContextName.EXPEDIENTS) {
        interveners = await this.fetchExpedientsIntervener({
          selectedRegisterId: this.selectedExpedientId
        })
      }
      const url = '/mail/doc/send'
      const jsonData = {
        idDocument: idDocs,
        env: process.env.VUE_APP_LEXBOX_ENV ? process.env.VUE_APP_LEXBOX_ENV : '',
        interveners
      }
      try {
        const { data } = await new MainService().postData(url, jsonData)
        let urlMail = `${(emailEndpoints as any)[this.emailProvider.provider].url}access/${data.token}`
        switch (this.emailProvider.provider) {
          case emailProviderName.IMAP:
            urlMail += `?prov=IM0&account=${btoa(this.emailProvider.email)}`
            break
          case emailProviderName.GOOGLE:
            urlMail += `?prov=GO0&email=${this.emailProvider.email}`
            break
          case emailProviderName.OUTLOOK:
            urlMail += '?prov=OU0'
            break
        }
        window.open(urlMail, '_blank')
      } catch (error) {
        this.showErrorAlert(fileManagerOpers.OPEN_MAIL_SERVICE, 0)
      }
      this.hideFileManagerSpinner()
    } else {
      this.showDialogAction(fileManagerOpers.SEND_EMAIL, 0)
    }
  }

  publishActionsModal(items: any, publish: boolean) {
    const itemsLength: number = items.length
    const allFolders: boolean = items.every((item: any) => item.isFile === false)
    const publishText: string = publish ? SendContextMenuId.PUBLISH : SendContextMenuId.UNPUBLISH
    const foldersPrefixText: string = allFolders ? '_folder' : ''
    const multipleFilesPrefixText: string = itemsLength === 1 ? '' : '_plural'
    const dialogText: string = `components.dialog.file_manager.${publishText}${foldersPrefixText}${multipleFilesPrefixText}`
    const dialogSuccessText: string = `components.file_manager.success_messages.${publishText}${foldersPrefixText}${multipleFilesPrefixText}`
    const dialogButtonText: string = `action_buttons.${publishText}`
    const publishActionFile: string = publish ? fileManagerOpers.PUBLISH : fileManagerOpers.UNPUBLISH
    const publishActionFolder: string = publish ? fileManagerOpers.PUBLISHFOLDER : fileManagerOpers.UNPUBLISHFOLDER
    const publishAction: string = allFolders ? publishActionFolder : publishActionFile

    const docsString: string = itemsLength === 1 ? getListStringByProp(items, 'name') : itemsLength.toString()

    const data: PublishData = {
      items,
      publish,
      docsString,
      allFolders,
      publishAction,
      itemsLength,
      dialogText,
      dialogSuccessText,
      dialogButtonText
    }

    this.showDialogAction(publishAction, data)
  }

  async publishActions(_data: any) {
    const jsonAll: any = []
    let result = false
    _data.items.forEach((item: any) => {
      const json: any = {}
      json.public = _data.publish ? 1 : 0
      if (item.isFile) {
        json.Id = item.id
        json.isFolder = false
        json.idEntity = item.idEntity
        json.idEntityType = item.idEntityType
      } else {
        json.id = item.idFolder
        json.isFolder = true
      }
      jsonAll.push(json)
    })

    const formData = new FormData()
    formData.append('json', JSON.stringify(jsonAll))
    const { data } = await new RepositoryService().updateFolderPublic(formData)
    result = data.result
    if (result) {
      this.showSuccessAlert(_data.publishAction, _data.itemsLength, _data)
    } else {
      this.showErrorAlert(_data.publishAction, _data.itemsLength)
    }
    this.refresh()
  }

  async editDocumentOnline(file: any | null) {
    this.saveFileName(file.originalFileName)

    const formData = new FormData()
    formData.set('FileName', file.originalFileName)
    formData.set('FileVersion', Date.now().toString())
    formData.set('wopiUserId', this.idUser.toString())

    await this.fetchRequestToken(formData)

    formData.set('AccessToken', this.requestToken)
    formData.set('AccessTokenTtl', this.requestTokenTtl)

    await this.fetchAppType(formData)

    await this.fetchFileToken(file.id)

    if (this.fileAction === documentOnlineActions.NEW) {
      this.saveFileSize(0)
    }

    this.openOnlineEditor(`${process.env.VUE_APP_FILE_EDIT_URL}${this.fileToken}`)
  }

  openBlobFile(id: number, newPage = true) {
    this.isOpeningFile = true
    this.customOpenFile({ id }, true, newPage)
  }

  async customOpenFile(file: any, openDirectly = false, newPage = true) {
    const { id, originalFileName } = file

    if (openDirectly || this.fileManagerInstance.selectedItems.length !== 0) {
      await this.openFile(id)

      if (isSafari() && !openDirectly) {
        const url = `/${URLS.REPOSITORY}?fileId=${id}`
        createLink(url, '', true)
        return
      }

      const blobUrl = this.getBlobUrl()
      createLink(blobUrl, originalFileName, newPage)
    }
  }

  getBlobUrl() {
    if (this.blobFile.type === 'text/plain') {
      const blobFileParsed = this.blobFile.slice(0, this.blobFile.size, 'text/plain; charset=UTF-8')
      return URL.createObjectURL(blobFileParsed)
    }
    return URL.createObjectURL(this.blobFile)
  }

  async customDownload(files: any[]) {
    this.showFileManagerSpinner(this.spinnerText.download)
    try {
      const flag: boolean = this.fileManagerInstance.selectedItems.length !== 0
      const numFiles = files.length
      if (numFiles !== 0 || flag) {
        for (let i = 0; i < numFiles; i++) {
          const file = files[i]

          await this.downloadFile(file.id)

          const blobUrl = URL.createObjectURL(this.blobFile)
          createLink(blobUrl, file.originalFileName)
        }
      }
    } catch (error) {
      console.error(error)
    }
    this.hideFileManagerSpinner()
  }

  togglePreview(documentPreview: DocumentPreview) {
    const preview: PreviewComponent = this.$refs.preview as PreviewComponent

    if (preview) {
      preview.toggle(documentPreview)
      return
    }
  }

  hidePreview() {
    const preview: PreviewComponent = this.$refs.preview as PreviewComponent

    if (preview) {
      preview.hide()
      return
    }
  }

  refreshPreview(documentPreview: DocumentPreview) {
    const preview: PreviewComponent = this.$refs.preview as PreviewComponent

    if (this.fileManagerInstance.selectedItems.length > 1) {
      documentPreview.type = DocumentPreviewType.Unknown
    }

    if (preview) {
      preview.refresh(documentPreview)
      return
    }
  }

  prepareDetailsViewTableForPreview() {
    const table1 = document.querySelector('#file-manager .e-headercontent colgroup')
    if (table1) {
      ;(table1.childNodes[1] as any).style.width = '50px'
    }
    const table2 = document.querySelector('#file-manager .e-gridcontent colgroup')
    if (table2) {
      ;(table2.childNodes[1] as any).style.width = '50px'
    }
  }

  createPreviewButtonOnDetailsViewTable(detailsViewElement: any, documentPreview: DocumentPreview) {
    const elem = detailsViewElement.querySelector('.e-fe-grid-icon .e-fe-icon')
    if (elem) {
      const buttonPreview = document.createElement('span')
      buttonPreview.id = `preview-item-${documentPreview.id}`
      buttonPreview.classList.add('e-fe-icon')
      buttonPreview.classList.add(Icons.VISIBLE)
      buttonPreview.classList.add('icon-preview')
      // buttonPreview.style.cursor = 'pointer'
      buttonPreview.style.paddingLeft = '5px'
      // buttonPreview.addEventListener('click', this.previewAction);
      ;(buttonPreview as any).documentPreview = JSON.stringify({
        id: documentPreview.id,
        type: documentPreview.type
      })
      elem.parentNode.appendChild(buttonPreview)
    }
  }

  onFileLoad(args: any) {
    if ('NavigationPane' === args.module) {
      if (args.fileDetails) {
        const fullRow: HTMLElement | null = args.element.querySelector('.e-fullrow')
        if (fullRow) {
          let droppable = args.fileDetails.isDroppable === true ? 'true' : 'false'

          if (this.canDropFilesOnMainFolder && '' === args.fileDetails.filterPath) {
            droppable = 'true'
          }

          fullRow.setAttribute('droppable', droppable)
        }
      }
    }

    if ('DetailsView' === args.module) {
      const previewExtensions = (this.$refs.preview as any).getPreviewExtensions()
      if (
        args.fileDetails &&
        args.fileDetails.type &&
        previewExtensions.includes(args.fileDetails.type.toLowerCase())
      ) {
        this.prepareDetailsViewTableForPreview()
        // Ejemplo button en el grid
        /*this.createPreviewButtonOnDetailsViewTable(args.element, {
          id: args.fileDetails.id,
          type: args.fileDetails.type as DocumentPreviewType
        })*/
      }
    }
  }

  onFileManagerDragStart(fileDragEventArgs: any) {
    const items = fileDragEventArgs.fileDetails
    if (items.length) {
      for (let i = 0; i < items.length; i++) {
        if (!fileDragEventArgs.fileDetails[i].isDraggable) {
          fileDragEventArgs.cancel = true
          break
        }
      }
    }
    return fileDragEventArgs
  }

  onFileManagerDragStop(ev: any) {
    const canDrop =
      !ev.target.className.includes('e-fullrow') ||
      (ev.target.hasAttribute('droppable') && ev.target.getAttribute('droppable') === 'true')

    if (!canDrop) {
      ev.cancel = true
    }
    return ev
  }

  onFileManagerDragging(ev: any) {
    const helper: HTMLElement | null = this.$el.querySelector('.e-fe-clone')
    const fileManager: HTMLElement | null = this.$el.querySelector('#file-manager')

    if (helper && fileManager) {
      const canDrop =
        !ev.target.className.includes('e-fullrow') ||
        (ev.target.hasAttribute('droppable') && ev.target.getAttribute('droppable') === 'true')

      fileManager.className = fileManager.className.replace(' lexon-no-drop', '')

      if (!canDrop) {
        fileManager.className += ' lexon-no-drop'
      }
    }
  }

  showSuccessAlert(action: string, numFiles: number, data: any = []) {
    let message: any = ''

    switch (action) {
      case fileManagerOpers.DELETE:
        const allFolders: boolean = data.every((item: any) => item.isFile === false)
        const foldersPrefixText: string = allFolders ? '_folder' : ''
        const multipleFilesPrefixText: string = numFiles === 1 ? '' : '_plural'
        const dialogText: string = `components.file_manager.success_messages.removed${foldersPrefixText}${multipleFilesPrefixText}`
        const docs = numFiles === 1 ? this.fileManagerInstance.selectedItems[0] : numFiles

        this.showDialogResult(this.$t(dialogText, { docs }), DialogTypes.SUCCESS)
        break
      case fileManagerOpers.MOVE:
        message = this.$tc('components.file_manager.success_messages.moved', numFiles)
        break
      case fileManagerOpers.MOVEFOLDERS:
        message = this.$tc('components.file_manager.success_messages.moved_folder', numFiles)
        break
      case fileManagerOpers.RENAME:
        const messageKey = data.length >= 0 && !data[0].isFile ? '_folder' : ''
        message = this.$t(`components.file_manager.success_messages.renamed${messageKey}`, { name: data[0].name ?? '' })
        break
      case fileManagerOpers.UPDATE:
        message = this.$t('components.file_manager.success_messages.document_updated', { name: data.fileInitial })
        break
      case fileManagerOpers.DETAILS:
        message = this.$t('components.file_manager.success_messages.document_details_updated', {
          name: data.name || data
        })
        break
      case fileManagerOpers.UPDATE_INTERVENER:
        message = this.$t('components.file_manager.success_messages.document_intervener_updated')
        break
      case fileManagerOpers.CREATE_INTERVENER:
        message = this.$t('components.file_manager.success_messages.document_intervener_created')
        break
      case fileManagerOpers.CANCEL_UPLOAD_FOLDERS:
        message = this.$t('components.file_manager.success_messages.cancel_upload_folders')
        break
      case fileManagerOpers.FINISH_UPLOAD_FOLDERS:
        message = this.$t(data.dialogSuccessText, { docs: data.docsString })
        break
      case fileManagerOpers.DOWNLOAD_AS_ZIP:
        message = this.$t('components.file_manager.success_messages.download_as_zip')
        break
      case fileManagerOpers.PUBLISH:
      case fileManagerOpers.PUBLISHFOLDER:
      case fileManagerOpers.UNPUBLISH:
      case fileManagerOpers.UNPUBLISHFOLDER:
        message = this.$t(data.dialogSuccessText, { docs: data.docsString })
        break
      case fileManagerOpers.UPDATE_FOLDER:
        message = this.$t(`components.file_manager.success_messages.updated_folder`, { name: data ?? '' })
        break
    }

    if (message) {
      this.showDialogResult(message, DialogTypes.SUCCESS)
    }
  }

  showErrorAlert(action: string, numFiles: number) {
    let message: any = this.$t('errors.generic_error')
    let dialogType = DialogTypes.ERROR

    switch (action) {
      case fileManagerOpers.DELETE:
        this.showDialogResult(
          this.$tc('components.file_manager.error_messages.cant_delete', numFiles),
          DialogTypes.ERROR
        )
        break
      case fileManagerOpers.MOVE:
        message = this.$tc('components.file_manager.error_messages.cant_move', numFiles)
        break
      case fileManagerOpers.RENAME:
        message = this.$t('components.file_manager.error_messages.cant_rename')
        break
      case fileManagerOpers.UPDATE:
        message = this.$t('components.file_manager.error_messages.cant_update')
        break
      case fileManagerOpers.UPDATE_INTERVENER:
        message = this.$t('components.file_manager.success_messages.cant_update_document_intervener')
        break
      case fileManagerOpers.CREATE_INTERVENER:
        message = this.$t('components.file_manager.success_messages.cant_create_document_intervener')
        break
      case fileManagerOpers.DOWNLOAD_AS_ZIP:
        message = this.$t('components.file_manager.error_messages.cant_download_as_zip')
        break
      case fileManagerOpers.CONVERT_TO_PDF:
        message = this.$t('components.file_manager.error_messages.cant_create_pdf')
        break
      case fileManagerOpers.OPEN_FIRMA_SERVICE:
        message = this.$t('components.file_manager.error_messages.cant_open_firma_service')
        break
      case fileManagerOpers.OPEN_MAIL_SERVICE:
        message = this.$t('components.file_manager.error_messages.cant_open_mail_service')
        break
      case fileManagerOpers.PUBLISH:
        message = this.$tc('components.file_manager.error_messages.cant_publish', numFiles)
        break
      case fileManagerOpers.PUBLISHFOLDER:
        message = this.$tc('components.file_manager.error_messages.cant_publish_folder', numFiles)
        break
      case fileManagerOpers.UNPUBLISH:
        message = this.$tc('components.file_manager.error_messages.cant_unpublish', numFiles)
        break
      case fileManagerOpers.UNPUBLISHFOLDER:
        message = this.$tc('components.file_manager.error_messages.cant_unpublish_folder', numFiles)
        break
    }

    this.showDialogResult(message, dialogType)
  }

  showDialogAction(_action: string, data: any = null) {
    let message: TranslateResult = ''
    let type = DialogTypes.INFO
    let mainButtonText: TranslateResult = ''
    let secondaryButtonText: TranslateResult = ''
    let action: Function | void | null = null

    switch (_action) {
      case fileManagerOpers.SEND_EMAIL:
        type = DialogTypes.WARNING
        message = this.$tc('components.file_manager.error_messages.cant_send_email', data)
        mainButtonText = this.$t('action_buttons.connect')
        secondaryButtonText = this.$t('action_buttons.cancel')
        action = () => {
          setTimeout(() => {
            this.showRefreshDialog()
          })
          window.open('/email', '_blank')
        }
        break
      case SendContextMenuId.PUBLISH:
      case SendContextMenuId.PUBLISHFOLDER:
      case SendContextMenuId.UNPUBLISH:
      case SendContextMenuId.UNPUBLISHFOLDER:
        type = DialogTypes.INFO
        message = this.$t(data.dialogText, { docs: data.docsString })
        mainButtonText = this.$t(data.dialogButtonText)
        secondaryButtonText = this.$t('action_buttons.cancel')
        action = () => {
          this.publishActions(data)
        }
        break
    }

    this.showDialog({
      type,
      message,
      mainButtonText,
      secondaryButtonText,
      action
    })
  }

  showAlertResult(message: any, alertType: AlertsTypes) {
    store.dispatch(
      `${ModuleNamespaces.ALERTS}/showAlert`,
      {
        type: alertType,
        message,
        componentWhereIsRendered: this.alertWhereIsReview
      },
      { root: true }
    )
  }

  showDialogResult(message: any, alertType: DialogTypes) {
    store.dispatch(
      `${ModuleNamespaces.DIALOG}/showDialog`,
      {
        type: alertType,
        message
      },
      { root: true }
    )
  }

  showRefreshDialog() {
    const message: TranslateResult = this.$t('components.file_manager.dialogs.refresh_page_email')
    const type = DialogTypes.INFO
    const mainButtonText: TranslateResult = this.$t('action_buttons.refresh')
    const secondaryButtonText: TranslateResult = this.$t('action_buttons.cancel')
    const action: Function = () => {
      window.location.reload()
    }

    this.showDialog({
      type,
      message,
      mainButtonText,
      secondaryButtonText,
      action
    })
  }

  onCantDropFolders() {
    this.showAlertResult(this.$t('components.file_manager.error_messages.cant_upload_folders'), AlertsTypes.WARNING)
  }

  onCantDropFiles() {
    this.showAlertResult(
      this.$t('components.file_manager.error_messages.cant_upload_files_restrictions'),
      AlertsTypes.WARNING
    )
    this.hideOverlay()
  }

  onCantUploadsFilesHere() {
    this.showAlertResult(this.$t('components.file_manager.advises.cant_upload_files_here'), AlertsTypes.ERROR)
    this.hideOverlay()
  }

  onCantUploadsFoldersHere() {
    this.showAlertResult(this.$t('components.file_manager.advises.cant_upload_folders_here'), AlertsTypes.ERROR)
    this.hideOverlay()
  }

  onPopupOpen(args: any) {
    if ('Error' === args.name) {
      args.popupModule.hide()
    }

    if ('popupOpen' === args.name) {
      const popupElem: HTMLElement | null = document.getElementById(args.popupModule.element.id)
      if (null !== popupElem) {
        if ('Delete' === args.popupName || 'Error' === args.popupName) {
          const header = popupElem.querySelector('.e-dlg-header')
          if (null != header) {
            header.classList.add('lf-dlg-icon')
          }
        } else {
          const parent: HTMLElement | null = popupElem.querySelector('.e-input-group')
          const input = popupElem.querySelector('input')
          const span: HTMLElement | null = document.createElement('span')
          const label: HTMLElement | null = document.createElement('label')
          if (null !== parent) {
            if (null !== input) {
              span.classList.add('lf-clean-field')
              span.addEventListener('click', () => {
                input.value = ''
                span.style.display = 'none'
              })
              parent.appendChild(span)

              input.setAttribute('maxlength', '255')
              input.addEventListener('keyup', () => {
                span.style.display = 'block'
              })
              parent.addEventListener('mouseenter', () => {
                if ('' !== input.value) {
                  span.style.display = 'block'
                }
              })
              parent.addEventListener('mouseleave', () => {
                span.style.display = 'none'
              })
              if ('newname' === input.id) {
                span.style.display = 'none'
              }
              if (null != label) {
                label.innerHTML = this.$t(
                  'components.file_manager.dialogs.' + args.popupName.replace(/\s/g, '_').toLowerCase() + '_label'
                ).toString()
                label.classList.add('lf-dlg-label')
                const spanLabel = document.createElement('span')
                spanLabel.innerHTML = '*'
                label.appendChild(spanLabel)
                const labelParent = parent.parentNode
                if (null != labelParent) {
                  labelParent.prepend(label)
                }
              }

              const errorSpan: HTMLElement | null = popupElem.querySelector('.e-fe-error')
              if (null != errorSpan) {
                errorSpan.addEventListener('DOMSubtreeModified', () => {
                  if ('' === errorSpan.innerHTML) {
                    label.classList.remove('lf-fe-label-error')
                    input.classList.remove('lf-fe-input-error')
                  } else {
                    label.classList.add('lf-fe-label-error')
                    input.classList.add('lf-fe-input-error')
                  }
                })
              }
            }
          }
        }
      }
    }
  }

  onPopUpClose(args: any) {
    this.flagActionInProgress = false
    const popupElem: HTMLElement | null = document.getElementById(args.popupModule.element.id)
    if (null != popupElem) {
      const clearSpan = popupElem.querySelector('.lf-clean-field')
      if (null != clearSpan) {
        clearSpan.remove()
      }
      const clearLabel = popupElem.querySelector('.lf-dlg-label')
      if (null != clearLabel) {
        clearLabel.remove()
      }
    }
  }

  onContextMenuOpen(args: any) {
    const isMultipleFiles = this.fileManagerInstance.getSelectedFiles().length > 1
    const { canSave, canDelete } = this.checkEntityPermissionsGetter(this.permissionsEntity)

    let folderOrDocument = null
    if (args.fileDetails && args.fileDetails[0]) {
      folderOrDocument = args.fileDetails[0]
    }

    const disabledItems = []
    for (const item of args.items) {
      switch (item.id) {
        case this.fileManagerInstance.element.id + '_cm_custom_separator':
          item.id = 'custom_separator'
          break
        case this.fileManagerInstance.element.id + '_cm_open':
          if (args.target.className === 'e-fullrow' && !folderOrDocument.isFile) {
            setTimeout(() => {
              ;(document as any).getElementById('file-manager_cm_open').click()
            })
            disabledItems.push(item.id)
          } else if (isMultipleFiles) {
            item.id = 'hidden-cm-option'
          }
          break
        case this.fileManagerInstance.element.id + '_cm_details':
          if (this.isPortalUser || isMultipleFiles) {
            disabledItems.push(item.id)
            item.id = 'hidden-cm-option'
          }
          break
        case this.fileManagerInstance.element.id + '_cm_edit':
          if (this.isPortalUser || !canSave || isMultipleFiles) {
            item.id = 'hidden-cm-option'
          } else {
            this.createEditMenu(item)
          }
          break
        case this.fileManagerInstance.element.id + '_cm_edit_layout':
          if (this.isPortalUser) {
            item.id = 'hidden-cm-option'
          } else {
            this.createEditLayoutMenu(item, folderOrDocument.isFile)
          }
          break
        case this.fileManagerInstance.element.id + '_cm_edit_multiple':
          if (this.isPortalUser || !isMultipleFiles) {
            item.id = 'hidden-cm-option'
          } else {
            this.createEditMultipleMenu(item, folderOrDocument.isFile)
          }
          break
        case this.fileManagerInstance.element.id + '_cm_send':
          if (this.isPortalUser || this.isDMSUser) {
            item.id = 'hidden-cm-option'
          } else if ((folderOrDocument && !folderOrDocument.canPublish) || !canSave) {
            item.iconCss = Icons.SHARE
            item.text = this.$t('components.file_manager.actions.send.title') as string
            disabledItems.push(item.id)
          } else {
            this.createSendMenu(item)
          }
          break
        case this.fileManagerInstance.element.id + '_cm_delete':
          if (folderOrDocument && !folderOrDocument.canDelete) {
            disabledItems.push(item.id)
          }
          if (this.isPortalUser || !canDelete) {
            disabledItems.push(item.id)
            item.id = 'hidden-cm-option'
          }
          break
        case this.fileManagerInstance.element.id + '_cm_cut':
        case this.fileManagerInstance.element.id + '_cm_copy':
          if (folderOrDocument && !folderOrDocument.canDelete) {
            disabledItems.push(item.id)
          }
          if (this.isPortalUser) {
            disabledItems.push(item.id)
            item.id = 'hidden-cm-option'
          }
          break
        case this.fileManagerInstance.element.id + '_cm_rename':
          if (folderOrDocument && !folderOrDocument.canRename) {
            disabledItems.push(item.id)
          }
          if (this.isPortalUser) {
            disabledItems.push(item.id)
            item.id = 'hidden-cm-option'
          }
          break
        case this.fileManagerInstance.element.id + '_cm_paste':
          if (folderOrDocument && (!folderOrDocument.canCreateDocument || !folderOrDocument.canCreateTemplate)) {
            disabledItems.push(item.id)
          }
          if (this.isPortalUser) {
            disabledItems.push(item.id)
            item.id = 'hidden-cm-option'
          }
          break
        case this.fileManagerInstance.element.id + '_cm_download_zip':
        case this.fileManagerInstance.element.id + '_cm_download_zip_portal':
          item.iconCss = Icons.ZIP
          item.text = this.$t('components.file_manager.actions.context_menu.download_zip')
          if (this.isPortalUser) {
            item.id = 'file-manager_cm_download_zip_portal'
          }
          if (folderOrDocument && !folderOrDocument.canZip) {
            disabledItems.push(item.id)
          }
          if (!isMultipleFiles && folderOrDocument.isFile) {
            item.id = 'hidden-cm-option'
          }
          break
        case this.fileManagerInstance.element.id + '_cm_newfolder':
          item.iconCss = Icons.FOLDER
          item.text = this.$t('components.file_manager.actions.new_folder')
          if (
            (folderOrDocument && !folderOrDocument.canCreateFolder) ||
            (folderOrDocument && !folderOrDocument.basePath.includes('/') && !this.canCreateFoldersOnMainFolder)
          ) {
            disabledItems.push(item.id)
          }
          if (this.isPortalUser) {
            disabledItems.push(item.id)
            item.id = 'hidden-cm-option'
          }
          break
        case this.fileManagerInstance.element.id + '_cm_new_word':
          item.iconCss = Icons.WORD
          item.text = this.$t('components.file_manager.actions.context_menu.new_word_document')
          if (folderOrDocument && !folderOrDocument.canCreateDocument) {
            disabledItems.push(item.id)
          }
          if (this.isPortalUser) {
            disabledItems.push(item.id)
            item.id = 'hidden-cm-option'
          }
          break
        case this.fileManagerInstance.element.id + '_cm_new_excel':
          if (folderOrDocument && !folderOrDocument.canCreateDocument) {
            disabledItems.push(item.id)
          }
          item.iconCss = Icons.EXCEL
          item.text = this.$t('components.file_manager.actions.context_menu.new_excel_document')
          if (this.isPortalUser) {
            disabledItems.push(item.id)
            item.id = 'hidden-cm-option'
          }
          break
        case this.fileManagerInstance.element.id + '_cm_new_ppoint':
          if (folderOrDocument && !folderOrDocument.canCreateDocument) {
            disabledItems.push(item.id)
          }
          item.iconCss = Icons.PPT
          item.text = this.$t('components.file_manager.actions.context_menu.new_ppoint_document')
          if (this.isPortalUser) {
            disabledItems.push(item.id)
            item.id = 'hidden-cm-option'
          }
          break
        case this.fileManagerInstance.element.id + '_cm_from_template':
          if (folderOrDocument && (!folderOrDocument.canCreateDocument || !folderOrDocument.canCreateTemplate)) {
            disabledItems.push(item.id)
          }
          item.iconCss = Icons.TEMPLATE
          item.text = this.$t('components.file_manager.actions.context_menu.from_template_document')
          if (this.isPortalUser || this.isDMSUser) {
            disabledItems.push(item.id)
            item.id = 'hidden-cm-option'
          }
          break
        case this.fileManagerInstance.element.id + '_cm_upload_document':
          if (folderOrDocument && !folderOrDocument.canCreateDocument) {
            disabledItems.push(item.id)
          }
          item.iconCss = Icons.UPLOAD
          item.text = this.$t('components.file_manager.actions.context_menu.upload_lexon_document')
          if (this.isPortalUser) {
            disabledItems.push(item.id)
          }
          break
        case this.fileManagerInstance.element.id + '_cm_upload_external_link':
          if (folderOrDocument && !folderOrDocument.canCreateDocument) {
            disabledItems.push(item.id)
          }
          item.iconCss = Icons.LINK
          item.text = this.$t('components.file_manager.actions.context_menu.upload_external_link')
          if (this.isPortalUser) {
            disabledItems.push(item.id)
          }
          break
        case this.fileManagerInstance.element.id + '_cm_new_template':
          if (folderOrDocument && !folderOrDocument.canCreateTemplate) {
            disabledItems.push(item.id)
          }
          item.iconCss = Icons.DOCUMENTATION
          item.text = this.$t('components.file_manager.actions.new_template')
          if (this.isPortalUser) {
            disabledItems.push(item.id)
            item.id = 'hidden-cm-option'
          }
          break
        case DownloadContextMenuId.DOWNLOAD_AS_PDF:
          const isConvertible = this.isTransformableToPdf(folderOrDocument)
          if ((folderOrDocument && !folderOrDocument.canCreateDocument) || !isConvertible) {
            disabledItems.push(item.id)
          }
          if (this.isPortalUser) {
            disabledItems.push(item.id)
            item.id = 'hidden-cm-option'
          }
          break
        case this.fileManagerInstance.element.id + '_cm_permissions':
          item.iconCss = Icons.OPTIONS
          item.text = this.$t('components.file_manager.actions.permissions')
          if (folderOrDocument && !folderOrDocument.canRename) {
            disabledItems.push(item.id)
          }
          if (this.isPortalUser || isMultipleFiles) {
            disabledItems.push(item.id)
            item.id = 'hidden-cm-option'
          }
          break
        case this.fileManagerInstance.element.id + '_cm_firma':
          if (this.userHasFirma && !this.isPortalUser && !this.isDMSUser && !isMultipleFiles) {
            this.createFirmaMenu(item)
            if (!folderOrDocument.type || !allowedFirmaExtensions.includes(folderOrDocument.type) || !canSave) {
              disabledItems.push(item.id)
            }
          } else {
            item.id = 'hidden-cm-option'
          }
          break
        case this.fileManagerInstance.element.id + '_cm_docsia':
          item.iconCss = Icons.IA
          item.text = this.$t('components.file_manager.actions.context_menu.documentary_analysis')
          const isIAFileExtension = this.isDocsIAFile(folderOrDocument.type)
          if (
            !this.userHasDocsIA ||
            this.isPortalUser ||
            this.isTemplateMode ||
            isMultipleFiles ||
            !isIAFileExtension
          ) {
            // TODO - ¿Mostrar por producto?
            item.id = 'hidden-cm-option'
          }
          break
        case this.fileManagerInstance.element.id + '_cm_customdownload':
          if (folderOrDocument.source === 'URL') {
            disabledItems.push('download')
          }
          if (!isMultipleFiles) {
            this.createDownloadMenu(item)
          } else {
            item.id = 'hidden-cm-option'
          }
          break
        case this.fileManagerInstance.element.id + '_cm_lexnet':
          if (!this.isPortalUser && !this.isDMSUser && !isMultipleFiles) {
            this.createLexnetMenu(item)
            if (!folderOrDocument.type || folderOrDocument.type !== '.pdf') {
              disabledItems.push(item.id)
            }
          } else {
            item.id = 'hidden-cm-option'
          }
          break
        case FirmaContextMenuId.SEND_FIRMA:
          if (folderOrDocument && folderOrDocument.isFile && folderOrDocument.type) {
            if (folderOrDocument.status) {
              const statusArray = folderOrDocument.status.split(',').map((t: string) => parseInt(t, 10))
              if (
                statusArray.includes(FirmaRequestStatus.SENT_TO_FIRMA) ||
                statusArray.includes(FirmaRequestStatus.PENDING_SIGNATURE) ||
                statusArray.includes(FirmaRequestStatus.SIGNED) ||
                statusArray.includes(FirmaRequestStatus.SIGNED_WITH_AUDIT) ||
                statusArray.includes(FirmaRequestStatus.DOCUMENT_CERTIFIED)
              ) {
                disabledItems.push(FirmaContextMenuId.SEND_FIRMA)
                disabledItems.push(FirmaContextMenuId.CERTIFY_DOCUMENT)
              }
            } else if (!folderOrDocument.status) {
              disabledItems.push(FirmaContextMenuId.MONITORING_REVIEW)
            }
          }
          break
        case EditContextMenuId.CUT:
        case EditContextMenuId.PASTE:
          if (folderOrDocument && !folderOrDocument.canDelete) {
            disabledItems.push(EditContextMenuId.CUT)
            disabledItems.push(EditContextMenuId.COPY)
          }
          if (folderOrDocument && !folderOrDocument.canRename) {
            disabledItems.push(EditContextMenuId.RENAME)
          }
          if (folderOrDocument && (!folderOrDocument.canCreateDocument || !folderOrDocument.canCreateTemplate)) {
            disabledItems.push(EditContextMenuId.PASTE)
          }
          if (this.isPortalUser) {
            item.id = 'hidden-cm-option'
          }
          if (this.pasteIsDisable()) {
            disabledItems.push(EditContextMenuId.PASTE)
          }
          break
        case SendContextMenuId.EMAIL:
          if (this.fileManagerInstance.getSelectedFiles().length === 0) {
            disabledItems.push(SendContextMenuId.EMAIL)
            break
          }
          this.fileManagerInstance.getSelectedFiles().forEach((element: any) => {
            if (!element.isFile || element.source === 'URL') {
              disabledItems.push(SendContextMenuId.EMAIL)
            }
          })
          break
        case SendContextMenuId.PUBLISH:
          if (folderOrDocument && !folderOrDocument.canPublish) {
            disabledItems.push(SendContextMenuId.PUBLISH)
            disabledItems.push(SendContextMenuId.UNPUBLISH)
          }
          break
      }
    }

    if (disabledItems.length) {
      this.fileManagerInstance.disableMenuItems(disabledItems)
    }
  }

  pasteIsDisable() {
    const originalPasteButton = document.getElementById('file-manager_cm_paste')
    if (originalPasteButton && originalPasteButton.classList.contains('e-disabled')) {
      return true
    }
    return false
  }

  createEditMenu(item: MenuItemModel) {
    item.iconCss = Icons.EDIT
    item.text = this.$t('components.file_manager.actions.edit.title') as string
    item.id = EditContextMenuId.EDIT
    item.items = [
      {
        text: this.$t('components.file_manager.actions.edit.cut') as string,
        iconCss: Icons.CUT,
        id: EditContextMenuId.CUT
      },
      {
        text: this.$t('components.file_manager.actions.edit.copy') as string,
        iconCss: Icons.COPY,
        id: EditContextMenuId.COPY
      },
      {
        text: this.$t('components.file_manager.actions.edit.paste') as string,
        iconCss: Icons.PASTE,
        id: EditContextMenuId.PASTE
      },
      {
        text: this.$t('components.file_manager.actions.edit.rename') as string,
        iconCss: Icons.RENAME,
        id: EditContextMenuId.RENAME
      }
    ]
  }

  createEditLayoutMenu(item: MenuItemModel, isFile: boolean) {
    item.iconCss = Icons.EDIT
    item.text = this.$t('components.file_manager.actions.edit.title') as string
    item.id = EditContextMenuId.EDIT
    item.items = [
      {
        text: this.$t('components.file_manager.actions.edit.paste') as string,
        iconCss: Icons.PASTE,
        id: EditContextMenuId.PASTE
      }
    ]

    if (isFile) {
      item.items.splice(5, 2)
    }
  }

  createEditMultipleMenu(item: MenuItemModel, isFile: boolean) {
    item.iconCss = Icons.EDIT
    item.text = this.$t('components.file_manager.actions.edit.title') as string
    item.id = EditContextMenuId.EDIT
    item.items = [
      {
        text: this.$t('components.file_manager.actions.edit.cut') as string,
        iconCss: Icons.CUT,
        id: EditContextMenuId.CUT
      },
      {
        text: this.$t('components.file_manager.actions.edit.copy') as string,
        iconCss: Icons.COPY,
        id: EditContextMenuId.COPY
      }
    ]

    if (isFile) {
      item.items.splice(5, 2)
    }
  }

  createSendMenu(item: MenuItemModel) {
    item.iconCss = Icons.SHARE
    item.text = this.$t('components.file_manager.actions.send.title') as string
    item.id = SendContextMenuId.SEND
    item.items = [
      {
        text: this.$t('components.file_manager.actions.send.email') as string,
        iconCss: Icons.ATACH,
        id: SendContextMenuId.EMAIL
      },
      {
        text: this.$t('components.file_manager.actions.send.publish') as string,
        iconCss: Icons.PERIODIC_COM,
        id: SendContextMenuId.PUBLISH
      },
      {
        text: this.$t('components.file_manager.actions.send.unpublish') as string,
        iconCss: Icons.PERIODIC_COM,
        id: SendContextMenuId.UNPUBLISH
      }
    ]
  }

  createDownloadMenu(item: MenuItemModel) {
    const { canSave } = this.checkEntityPermissionsGetter(this.permissionsEntity)

    item.iconCss = Icons.DOWNLOAD
    item.text = this.$t('components.file_manager.actions.context_menu.download') as string
    item.id = DownloadContextMenuId.DOWNLOAD
    item.items = [
      {
        text: this.$t('components.file_manager.actions.context_menu.download_original') as string,
        iconCss: Icons.DOCUMENTATION,
        id: DownloadContextMenuId.DOWNLOAD_ORIGINAL
      },
      ...(canSave
        ? [
            {
              text: this.$t('components.file_manager.actions.context_menu.download_as_pdf') as string,
              iconCss: Icons.PDF,
              id: DownloadContextMenuId.DOWNLOAD_AS_PDF
            }
          ]
        : [])
    ].filter(Boolean)
  }

  createFirmaMenu(item: MenuItemModel) {
    item.iconCss = Icons.SIGNATURE_CERTIFICATE
    item.text = this.$t('components.file_manager.actions.firma.signature_and_certification') as string
    item.id = FirmaContextMenuId.FIRMA
    item.items = [
      {
        text: this.$t('components.file_manager.actions.firma.send_to_firma') as string,
        iconCss: Icons.SIGNATURE,
        id: FirmaContextMenuId.SEND_FIRMA
      },
      {
        text: this.$t('components.file_manager.actions.firma.certify_document') as string,
        iconCss: Icons.CERTIFIED_DOCUMENT,
        id: FirmaContextMenuId.CERTIFY_DOCUMENT
      },
      {
        text: this.$t('components.file_manager.actions.firma.send_certified_email') as string,
        iconCss: Icons.CERTIFIED_EMAIL,
        id: FirmaContextMenuId.SEND_CERTIFIED_EMAIL
      },
      {
        text: this.$t('components.file_manager.actions.firma.send_SMS_email') as string,
        iconCss: Icons.CERTIFIED_SMS,
        id: FirmaContextMenuId.SEND_CERTIFIED_SMS
      },
      {
        text: this.$t('components.file_manager.actions.firma.monitoring_and_audits') as string,
        iconCss: Icons.REVIEW,
        id: FirmaContextMenuId.MONITORING_REVIEW
      }
    ]
  }

  createLexnetMenu(item: MenuItemModel) {
    item.iconCss = Icons.LEXNET
    item.text = this.$t('components.file_manager.actions.lexnet.title') as string
    item.id = LexnetContextMenuId.LEXNET
    item.items = [
      {
        text: this.$t('components.file_manager.actions.lexnet.procedure') as string,
        iconCss: Icons.FORMS,
        id: LexnetContextMenuId.PROCEDURE
      },
      {
        text: this.$t('components.file_manager.actions.lexnet.subject') as string,
        iconCss: Icons.FORMS,
        id: LexnetContextMenuId.SUBJECT
      },
      {
        text: this.$t('components.file_manager.actions.lexnet.execution') as string,
        iconCss: Icons.FORMS,
        id: LexnetContextMenuId.EXECUTION
      },
      // {
      //   separator: true
      // },
      // {
      //   text: this.$t('components.file_manager.actions.lexnet.defense') as string,
      //   iconCss: Icons.FORMS,
      //   id: LexnetContextMenuId.DEFENSE
      // },
      {
        text: this.$t('components.file_manager.actions.lexnet.personation') as string,
        iconCss: Icons.FORMS,
        id: LexnetContextMenuId.PERSONATION
      }
      // {
      //   separator: true
      // },
      // {
      //   text: this.$t('components.file_manager.actions.lexnet.appeal') as string,
      //   iconCss: Icons.FORMS,
      //   id: LexnetContextMenuId.APPEAL
      // }
    ]
  }

  uploaderSelected(args: any) {
    let idx = args.filesData.length - 1

    while (idx >= 0) {
      const _filesData = args.filesData[idx]
      if (!_filesData || (_filesData.name === '' && _filesData.type === '')) {
        args.filesData.splice(idx, 1)
      }
      idx -= 1
    }

    if (args.filesData.length && !this.droppingFolders) {
      this.fileManagerInstance.uploadDialogObj.show()
    }
  }

  initInterface() {
    // this.refreshTokenInterval = setInterval(() =>
    //   store.dispatch(`${ModuleNamespaces.AUTH}/refreshToken`)
    //   , 900 * 1000
    // )
    this.fileManagerInstance.uploadObj.selected = this.uploaderSelected
    this.fetchFileManagerGridConfig().then(() => {
      setTimeout(() => {
        this.setFileManagerGridOrder()
        if (this.filenameSelected) {
          this.selectedItems.push(this.filenameSelected)
        }
      }, 500)
    })
  }

  onGridEvent(ev: Event) {
    let target: HTMLElement | null = null

    if (ev.target) {
      target = ev.target as HTMLElement
    }

    const clickClass = ['e-headercell', 'e-headercelldiv', 'e-headertext', 'e-rhandler', 'e-rhelper', 'e-sortfilterdiv']

    if (target && clickClass.some((item) => target!.classList.contains(item))) {
      this.saveColumnsWidth(ev.target)
      this.saveListConfig()
      setTimeout(() => {
        this.renderDocsIA()
      }, 250)
    }
  }

  renderDocsIA() {
    this.addDocsIATooltipListeners()
    this.addClickHandlerToDocsIAIcon()
  }

  saveColumnsWidth(element: EventTarget | null) {
    const parsedElement = element as HTMLElement

    if (!parsedElement) {
      return
    }

    if (parsedElement.classList.contains('e-rhelper')) {
      const gridHeaderContent = parsedElement.parentNode!.querySelector('.e-gridheader .e-headercontent')
      const columns = gridHeaderContent!.querySelectorAll('.e-headercell')
      const colsData: HeaderColumn[] = Array.from(columns)
        .slice(2)
        .map((item) => ({
          index: Number(item.getAttribute('data-colindex')),
          width: (item as HTMLElement).offsetWidth
        }))

      this.updatedHeaderColumns = colsData
      return
    }

    const parent = parsedElement.parentNode as HTMLElement
    let columnData: HeaderColumn = { index: null, width: null }
    columnData.index = Number(parent.getAttribute('data-colindex'))
    columnData.width = parent.offsetWidth

    if (parent.classList.contains('e-headercelldiv')) {
      const grandParent = parent.parentNode as HTMLElement
      columnData.index = Number(grandParent.getAttribute('data-colindex'))
      columnData.width = grandParent.offsetWidth
    }

    if (parent.classList.contains('e-columnheader')) {
      columnData.index = Number(parsedElement.getAttribute('data-colindex'))
      columnData.width = parsedElement.offsetWidth
    }

    this.updatedHeaderColumns.push(columnData)
  }

  getStatusTemplate(): string {
    const statusTemplate = `
        \${if(isFile)}
          <div class="firma-column">
            \${if(firmaStatus===${FirmaRequestStatus.SENT_TO_FIRMA})}
              <span class=\"firma-status-pending\">${this.$t('components.firma_status.send_to_firma')}</span>
            \${else}
              \${if(firmaStatus===${FirmaRequestStatus.PENDING_SIGNATURE})}
                <span class=\"firma-status-pending\">${this.$t('components.firma_status.pending_signature')}</span>
              \${else}
                \${if(firmaStatus===${FirmaRequestStatus.SIGNED})}
                  <span class=\"firma-status-signed\">${this.$t('components.firma_status.signed')}</span>
                \${else}
                  \${if(firmaStatus===${FirmaRequestStatus.SIGNED_WITH_AUDIT})}
                    <span class=\"firma-status-signed\">${this.$t('components.firma_status.signed_with_audit')}</span>
                  \${else}
                    \${if(firmaStatus===${FirmaRequestStatus.CANCEL})}
                      <span class=\"firma-status-cancel\">${this.$t('components.firma_status.cancel')}</span>
                    \${else}
                      \${if(firmaStatus===${FirmaRequestStatus.DOCUMENT_CERTIFIED})}
                        <span class=\"firma-status-certified\">${this.$t('components.firma_status.certified')}</span>
                      \${else}
                        <span></span>
                      \${/if}
                    \${/if}
                  \${/if}
                \${/if}
              \${/if}
            \${/if}
            \${if(firmaMailOrSms)}
              <span class="firma-certified lf-icon-certificate"></span>
            \${else}
            \${/if}
          </div>
        \${else}
        \${/if}`
    return statusTemplate
  }

  getPublicTemplate(): string {
    const publishTemplate = `
      \${if(public)}
        <div class="public-item">${this.publicStatusText.ok}</div>
      \${else}
        <div class="not-public-item">${this.publicStatusText.ko}</div>
      \${/if}`
    return publishTemplate
  }

  getDocsIATemplate(): string {
    const docsIATemplate = `
      \${if(['.pdf', '.doc', '.docx', '.rtf', '.odt', '.jpg', '.jpeg', '.png', '.txt', '.eml', '.msg'].includes(type))}
         <span class="${Icons.IA} docs-ia-icon" data-attr="\${id}" />
      \${/if}`
    return docsIATemplate
  }

  setTemplateForStatusColumn() {
    if (this.detailsViewSettings && this.detailsViewSettings.columns) {
      this.detailsViewSettings.columns = this.detailsViewSettings.columns.map((column: ColumnModel) => {
        if (column.field === 'status') {
          column.template = this.getStatusTemplate()
        }

        if (column.field === 'public') {
          column.template = this.getPublicTemplate()
        }

        if (this.userHasDocsIA && !this.isPortalUser && column.field === 'docs-ia') {
          column.template = (this as any).getDocsIATemplate()
        }

        return column
      })
    }
  }

  async fetchFileManagerGridConfig() {
    try {
      await this.fetchCurrentListConfiguration(this.listName)
      if (this.folderType === fileManagerFolderType.DOCUMENT) {
        this.detailsViewSettingsDocument = this.gridConfiguration
        this.setSortComparer(this.detailsViewSettingsDocument)
      } else {
        this.detailsViewSettingsAll = this.gridConfiguration
        this.setSortComparer(this.detailsViewSettingsAll)
      }
      if (this.detailsViewSettings && this.detailsViewSettings.openMSOffice) {
        this.setSortComparer(this.detailsViewSettings)
        this.openMSOfficeConfigMode = this.detailsViewSettings.openMSOffice
      }
      this.setTemplateForStatusColumn()
      this.configSafari()
    } catch (error) {}
  }

  setSortComparer(config: any, sort: any = this.sortComparer) {
    const configuration: any = config
    if (configuration && configuration.columns) {
      configuration.columns.forEach((column: any) => {
        if (column && column.hasOwnProperty('sortComparer')) {
          column.sortComparer = sort
        }
      })
    }
    return configuration
  }

  setFileManagerGridOrder() {
    if (
      !this.fileManagerInstance ||
      !this.fileManagerInstance.detailsviewModule ||
      !this.fileManagerInstance.detailsviewModule.gridObj ||
      !this.fileManagerInstance.detailsviewModule.gridObj.sortModule
    ) {
      return
    }

    const userSort: any = this.detailsViewSettings!.columns!.find((item: any) => {
      return item.sortDirection
    })

    if (userSort) {
      this.sortBy = userSort.field
      this.sortOrder = userSort.sortDirection
    }

    this.checkIfRenderDescendingIcon(this.sortOrder)
  }

  async checkIfRenderDescendingIcon(sortDirection: string) {
    await this.$nextTick()
    if (sortDirection === 'Descending') {
      const ascendingIcon = this.$el.querySelector('.e-ascending')
      if (ascendingIcon) {
        ascendingIcon.classList.add('e-descending', 'e-icon-descending')
        ascendingIcon.classList.remove('e-ascending', 'e-icon-ascending')
      }
    }
  }

  onToolbarClick(toolBar: any) {
    if (toolBar.item.id.includes('_tb_details') && toolBar.fileDetails.length) {
      if (1 === toolBar.fileDetails.length) {
        const selected = toolBar.fileDetails[0]
        if (selected.isFile) {
          if (!this.isTemplateMode) {
            this.editMode = selected.source === 'URL' ? EditDocumentMode.linkEdit : EditDocumentMode.uploadEdit
            this.openDocumentDetail(selected.id)
          } else {
            this.saveTemplate(selected)
            this.openTemplateForm()
          }
        }
      } else {
        this.showAlertResult(this.$t('components.file_manager.advises.only_one_file_selected'), AlertsTypes.ERROR)
      }
    }

    if (toolBar.item.id.includes('_tb_actions')) {
      const btn: null | HTMLElement = document.getElementById(toolBar.item.id)
      if (null !== btn) {
        let contextMenuId = 'action-add-document-context-menu'

        if (this.isTemplateMode) {
          contextMenuId = 'action-add-template-context-menu'
        }

        const rect: DOMRect | ClientRect = btn.getBoundingClientRect()
        const elem: any = document.getElementById(contextMenuId)
        if (null !== elem) {
          elem.ej2_instances[0].open(rect.top + 45, rect.left + 20)
        }
      }
    }

    if (toolBar.item.id.includes('_tb_open')) {
      const selectedFileObject = {
        cancel: false,
        module: 'DetailsView',
        name: 'fileOpen',
        fileDetails: toolBar.fileDetails[0]
      }
      this.onFileOpen(selectedFileObject)
    }

    if (toolBar.item.id.includes('_tb_preview')) {
      let id = 0
      let type = DocumentPreviewType.Unknown
      if (toolBar.fileDetails[0] && this.fileManagerInstance.selectedItems.length === 1) {
        id = toolBar.fileDetails[0].id
        type = toolBar.fileDetails[0].type
      }
      this.togglePreview({ id, type } as DocumentPreview)
    }

    if (toolBar.item.id.includes('_tb_configuration')) {
      const btn: null | HTMLElement = document.getElementById(toolBar.item.id)
      if (null !== btn) {
        const contextMenuId = 'action-msoffice-configuration-context-menu'
        const rect: DOMRect | ClientRect = btn.getBoundingClientRect()
        const elem: any = document.getElementById(contextMenuId)
        if (null !== elem) {
          elem.ej2_instances[0].open(rect.top + 45, rect.left + 20)
        }
      }
    }
  }

  onActionAddClick(args: any) {
    switch (args.item.text) {
      case this.$t('components.file_manager.actions.new_folder'):
        this.fileManagerInstance.createFolder()
        break
      case this.$t('components.file_manager.actions.new_word_document'):
        this.setDocumentOnlineAction(documentOnlineActions.NEW)
        this.editMode = EditDocumentMode.wordOnlineNew
        this.openDocumentDetail()
        break
      case this.$t('components.file_manager.actions.new_excel_document'):
        this.setDocumentOnlineAction(documentOnlineActions.NEW)
        this.editMode = EditDocumentMode.excelOnlineNew
        this.openDocumentDetail()
        break
      case this.$t('components.file_manager.actions.new_ppoint_document'):
        this.setDocumentOnlineAction(documentOnlineActions.NEW)
        this.editMode = EditDocumentMode.powerPointOnlineNew
        this.openDocumentDetail()
        break
      case this.$t('components.file_manager.actions.from_template_document'):
        this.showTemplateDialog = this.checkIfOnlyTemplate()
        break
      case this.$t('components.file_manager.actions.upload_lexon_document'):
        this.editMode = EditDocumentMode.uploadNew
        this.openDocumentDetail()
        break
      case this.$t('components.file_manager.actions.upload_external_link'):
        this.editMode = EditDocumentMode.linkNew
        this.openDocumentDetail()
        break
      case this.$t('components.file_manager.actions.new_template'):
        this.editMode = EditDocumentMode.templateNew
        this.openTemplateForm()
        break
    }
  }

  onOpenMSOfficeConfigClick(args: any) {
    switch (args.item.text) {
      case this.$t('components.file_manager.actions.config.local'):
        this.openMSOfficeConfigMode = OpenMSOffice.LOCAL
        break
      case this.$t('components.file_manager.actions.config.wopi'):
        this.openMSOfficeConfigMode = OpenMSOffice.WOPI
        break
      default:
        break
    }
    this.saveListConfig()
  }

  checkIfOnlyTemplate(): boolean {
    if ((this.tree && this.tree.length === 0) || this.tree.length > 1) {
      return true
    }
    const template: ITemplate = this.tree[0]
    if (!template.isDirectory) {
      this.generateTemplateDialog(template)
      return false
    }
    return true
  }

  onToolbarCreate(toolBar: ToolbarCreateEventArgs) {
    if (toolBar.items && Array.isArray(toolBar.items)) {
      toolBar.items.forEach((item: any) => {
        item.align = 'Right'
        item.overflow = 'Show'
        item.cssClass = 'e-tbtn-align'

        if (item.id.includes('_tb_refresh')) {
          item.showTextOn = 'Overflow'
          item.cssClass = 'lf-btn-model1-icon'
        }

        if (item.id.includes('_tb_view')) {
          item.showTextOn = 'Overflow'
          item.cssClass = 'lf-btn-model1-icon'
          item.prefixIcon = 'e-icons e-fe-large e-menu-icon'
        }

        if (item.id.includes('_tb_preview')) {
          item.prefixIcon = 'e-icons lf-icon-visible main-preview-button'
          item.text = this.$t('components.file_manager.toolbar.preview')
          item.tooltipText = ''
          item.cssClass = 'lf-btn-model1 lf-border-left'
        }

        if (item.id.includes('_tb_selection')) {
          item.cssClass = 'lf-selection-item e-tbtn-align lf-btn-model1'
          item.align = 'Left'
        }

        if (item.id.includes('_tb_details')) {
          item.cssClass = 'first-item e-tbtn-align e-overlay lf-btn-model1'
        }

        if (item.id.includes('_tb_actions')) {
          item.text = this.$t('components.file_manager.toolbar.newDocument')
          item.tooltipText = ''
          item.cssClass = 'lf-btn-model3'
        }

        if (item.id.includes('_tb_open')) {
          item.cssClass = `e-tbtn-align e-overlay ${this.checkIfTablet ? '' : 'e-hidden'}`
          item.prefixIcon = 'e-icons e-fe-open'
          item.tooltipText = this.$t('components.file_manager.tooltip.open')
        }

        if (item.id.includes('_tb_configuration')) {
          item.showTextOn = 'Overflow'
          item.cssClass = 'lf-btn-model1-icon'
          item.prefixIcon = 'e-icons lf-icon-office365'
          item.tooltipText = this.$t('components.file_manager.tooltip.edit')
        }
      })
    }
  }

  onBeforeOpenContextMenu() {
    if (!this.cwd || !this.contextMenu) {
      return null
    }

    const contextMenu = this.hasCompanyTemplate
      ? [
          this.$t('components.file_manager.actions.new_folder').toString(),
          this.$t('components.file_manager.actions.new_template').toString(),
          this.$t('components.file_manager.actions.new_word_document').toString(),
          this.$t('components.file_manager.actions.new_excel_document').toString(),
          this.$t('components.file_manager.actions.new_ppoint_document').toString(),
          this.$t('components.file_manager.actions.upload_lexon_document').toString(),
          this.$t('components.file_manager.actions.upload_external_link').toString(),
          this.$t('components.file_manager.actions.from_template_document').toString()
        ]
      : [
          this.$t('components.file_manager.actions.new_folder').toString(),
          this.$t('components.file_manager.actions.new_word_document').toString(),
          this.$t('components.file_manager.actions.new_excel_document').toString(),
          this.$t('components.file_manager.actions.new_ppoint_document').toString(),
          this.$t('components.file_manager.actions.upload_lexon_document').toString(),
          this.$t('components.file_manager.actions.upload_external_link').toString(),
          this.$t('components.file_manager.actions.from_template_document').toString()
        ]

    this.contextMenu.enableItems(contextMenu, true)

    if (!this.cwd.canCreateFolder) {
      this.contextMenu.enableItems([this.$t('components.file_manager.actions.new_folder').toString()], false)
    }

    if (!this.cwd.canCreateTemplate && this.hasCompanyTemplate) {
      this.contextMenu.enableItems(
        [
          this.$t('components.file_manager.actions.new_template').toString(),
          this.$t('components.file_manager.actions.from_template_document').toString()
        ],
        false
      )
    }
    if (!this.cwd.canCreateDocument) {
      this.contextMenu.enableItems(
        [
          this.$t('components.file_manager.actions.new_word_document').toString(),
          this.$t('components.file_manager.actions.new_excel_document').toString(),
          this.$t('components.file_manager.actions.new_ppoint_document').toString(),
          this.$t('components.file_manager.actions.upload_lexon_document').toString(),
          this.$t('components.file_manager.actions.upload_external_link').toString(),
          this.$t('components.file_manager.actions.from_template_document').toString()
        ],
        false
      )
    }
  }

  onBeforeOpenMSOfficeConfigContextMenu(args: any) {
    if (this.openMSOfficeConfigMode === OpenMSOffice.WOPI) {
      args.items[0].iconCss = 'lf-icon-step-first'
      args.items[1].iconCss = 'lf-icon-radio-button-active'
    }
    if (this.openMSOfficeConfigMode === OpenMSOffice.LOCAL) {
      args.items[0].iconCss = 'lf-icon-radio-button-active'
      args.items[1].iconCss = 'lf-icon-step-first'
    }
  }

  onFileSelect(args: any) {
    if (this.lastFileManagerOper !== fileManagerOpers.SEARCH) {
      this.fileManagerInstance.disableToolbarItems(['Open'])
      this.fileManagerInstance.disableToolbarItems(['Details'])
      if (this.fileManagerInstance.getSelectedFiles().length === 1 && args.fileDetails.isFile) {
        this.fileManagerInstance.enableToolbarItems(['Open'])
        this.fileManagerInstance.enableToolbarItems(['Details'])
      }
      if (args.fileDetails && args.fileDetails.length && 1 === args.fileDetails.length && args.fileDetails[0].isFile) {
        this.fileManagerInstance.enableToolbarItems(['Details'])
      }
    } else if (fileManagerOpers.SELECT === args.action && args.fileDetails && 1 === args.fileDetails.length) {
      this.foundFolder = args.fileDetails[0]
    }

    if (fileManagerOpers.SELECT === args.action) {
      this.refreshPreview({
        id: Number(args.fileDetails.id),
        type: args.fileDetails.type
      })
    }

    if (fileManagerOpers.UNSELECT === args.action && 1 === this.fileManagerInstance.selectedItems.length) {
      this.refreshPreview({
        id: Number(this.fileManagerInstance.itemData[0].id),
        type: this.fileManagerInstance.itemData[0].type
      })
    }

    if (fileManagerOpers.UNSELECT === args.action && 0 === this.fileManagerInstance.selectedItems.length) {
      this.refreshPreview({
        id: 0,
        type: DocumentPreviewType.Unknown
      })
    }

    this.checkSignaturitState(args)
  }

  onFileOpen(args: any) {
    this.resetSignaturitStates()
    if ('DetailsView' === args.module && 'fileOpen' === args.name && args.fileDetails && args.fileDetails.isFile) {
      if (['.tif', '.tiff'].includes(args.fileDetails.type.toString().toLowerCase())) {
        args.cancel = true
      }
      this.actionOpenFile(args.fileDetails)
    }
  }

  resetSignaturitStates() {
    this.signPending = []
    this.inSignaturit = []
  }

  checkSignaturitState(args: any) {
    const item = args.fileDetails.length !== undefined ? args.fileDetails[0] : args.fileDetails
    this.firmaStatusDoc = item.firmaStatus
    if (item.isFile && item.firmaStatus !== null) {
      if (item.firmaStatus === FirmaRequestStatus.PENDING_SIGNATURE) {
        fileManagerOpers.SELECT === args.action
          ? this.signPending.push(item.id)
          : (this.signPending = this.signPending.filter((f) => f !== item.id))
      } else if (item.firmaStatus >= FirmaRequestStatus.SENT_TO_FIRMA) {
        fileManagerOpers.SELECT === args.action
          ? this.inSignaturit.push(item.id)
          : (this.inSignaturit = this.inSignaturit.filter((f) => f !== item.id))
      }
    } else if (item.firmaMailOrSms) {
      fileManagerOpers.SELECT === args.action
        ? this.inSignaturit.push(item.id)
        : (this.inSignaturit = this.inSignaturit.filter((f) => f !== item.id))
    }
  }

  onFileManagerBeforeDialogOpen(args: any) {
    if ('Delete' === args.popupName) {
      const items = this.fileManagerInstance.itemData
      const allFolders: boolean = items.every((item: any) => item.isFile === false)
      const itemsLength = this.fileManagerInstance.selectedItems.length
      const foldersPrefixText: string = allFolders ? '_folder' : ''
      const multipleFilesPrefixText: string = itemsLength === 1 ? '' : '_plural'
      const dialogText: string = `components.dialog.file_manager.remove${foldersPrefixText}${multipleFilesPrefixText}`
      const item = itemsLength === 1 ? this.fileManagerInstance.selectedItems[0] : itemsLength

      args.popupModule.element.classList.add('lf-dialog-delete')
      args.popupModule.element.classList.remove('lf-dialog-error')
      args.popupModule.dlgContainer.querySelector('.e-footer-content .e-btn:not(.e-primary)').innerText =
        this.$t('action_buttons.cancel')
      args.popupModule.dlgContainer.querySelector('.e-footer-content .e-primary').innerText =
        this.$t('action_buttons.remove')
      args.popupModule.dlgContainer.querySelector('.e-dlg-header').innerText = this.$t('components.dialog.type.info')
      args.popupModule.dlgContainer.querySelector('.e-dlg-content').innerHTML = args.popupModule.properties.content
      args.popupModule.dlgContainer.querySelector('.e-dlg-content').innerText = this.$t(dialogText, { docs: item })
      if (this.inSignaturit.length > 0) {
        args.popupModule.dlgContainer.querySelector('.e-dlg-content').innerText = this.$t(
          'components.firma_dialogs.warning_delete_in_signaturit'
        )
      }
      if (this.signPending.length > 0) {
        args.popupModule.element.classList.add('lf-dialog-error')
        args.popupModule.element.classList.remove('lf-dialog-delete')
        args.popupModule.dlgContainer.querySelector('.e-dlg-header').innerText = this.$t('components.dialog.type.error')
        args.popupModule.dlgContainer.querySelector('.e-footer-content .e-primary').remove()
        args.popupModule.dlgContainer.querySelector('.e-footer-content .e-btn').innerText =
          this.$t('action_buttons.accept')
        args.popupModule.dlgContainer.querySelector('.e-dlg-content').innerText = this.$t(
          'components.firma_dialogs.warning_delete_sign_pending'
        )
      }
      args.popupModule.element.style.width = '700px'
    } else if ('Error' === args.popupName) {
      args.popupModule.element.classList.add('lf-dialog-error')
      args.popupModule.element.classList.remove('lf-dialog-delete')
      args.popupModule.element.style.width = '700px'
    } else {
      args.popupModule.element.classList.remove('lf-dialog-delete')
      args.popupModule.element.classList.remove('lf-dialog-error')
      args.popupModule.element.style.width = '350px'
    }
  }

  getBasePathFolder(basePath: string): string {
    const parts = basePath.split('/')
    parts.pop()
    return parts.join('/')
  }

  async openFolderDetail(folderId: number) {
    this.idDocument = folderId ? Number(folderId) : 0
    let document = {}

    this.saveFileManagerItem({
      id: folderId,
      isFolder: true
    })

    await this.fetchFolder(folderId)
    await this.$nextTick()
    document = this.document

    this.documentBasePath = this.currentBasePath

    await this.saveSelectedRegisterData({
      selectedRegisterData: document,
      context: ContextName.DOCUMENT
    })
    this.showDocumentDetail = false
    this.showFolderDetail = true
    await this.$nextTick()
    this.everythingLoaded = true
    this.$emit('openForm')
  }

  async openDocumentDetail(documentId = '') {
    this.idDocument = documentId ? Number(documentId) : 0

    this.saveSelectedRegisterId({
      id: documentId,
      context: ContextName.DOCUMENT
    })

    this.saveFileManagerItem({
      id: documentId,
      isFolder: false
    })

    let document = {}

    if (documentId) {
      await this.fetchDocument(documentId)
      await this.$nextTick()
      document = this.document
    } else {
      this.saveDocument(document)
    }

    this.documentBasePath = this.currentBasePath

    await this.saveSelectedRegisterData({
      selectedRegisterData: document,
      context: ContextName.DOCUMENT
    })

    this.showDocumentDetail = true
    await this.$nextTick()
    this.everythingLoaded = true
    this.$emit('openForm')
  }

  onCloseDocumentDetail() {
    if (this.showFolderDetail === true) {
      this.showFolderDetail = false
      if (this.fileManagerInstance.activeModule === 'navigationpane') {
        let parentPath = this.fileManagerInstance.path.split('/')
        parentPath.splice(parentPath.length - 2, 1)
        parentPath = parentPath.join('/')
        this.fileManagerInstance.path = parentPath
      } else {
        this.fileManagerInstance.refresh()
      }
    }
    if (this.showDocumentDetail === true) {
      this.showDocumentDetail = false
      this.fileManagerInstance.refresh()
    }
    this.$emit('closeForm')
  }

  onSaveDocumentOk(file: any) {
    if (file.id) {
      setTimeout(() => {
        this.openMSOfficeConfigMode === OpenMSOffice.WOPI ? this.editDocumentOnline(file) : this.openMSOfficeLocal(file)
      }, 1000)
      this.showSuccessAlert(fileManagerOpers.UPDATE, 1, file)
      this.onCloseDocumentDetail()
    } else {
      this.showSuccessAlert(fileManagerOpers.DETAILS, 1, file)
      this.onCloseDocumentDetail()
    }
  }

  onSaveFolderOk(name: any) {
    this.showSuccessAlert(fileManagerOpers.UPDATE_FOLDER, 1, name)
    this.onCloseDocumentDetail()
  }

  onSaveDocumentFail() {
    this.showErrorAlert(fileManagerOpers.UPDATE, 1)
  }

  onSaveIntervenerOk(isEditMode: boolean) {
    if (isEditMode) {
      this.showSuccessAlert(fileManagerOpers.UPDATE_INTERVENER, 1)
    } else {
      this.showSuccessAlert(fileManagerOpers.CREATE_INTERVENER, 1)
    }
  }

  onSaveIntervenerFail(isEditMode: boolean) {
    if (isEditMode) {
      this.showErrorAlert(fileManagerOpers.UPDATE_INTERVENER, 1)
    } else {
      this.showErrorAlert(fileManagerOpers.CREATE_INTERVENER, 1)
    }
  }

  async onDocumentRemoveAction() {
    this.showDocumentDetail = false
    await this.$nextTick()
    this.fileManagerInstance.deleteFiles(this.fileManagerInstance.selectedItems)
    this.onCloseDocumentDetail()
  }

  onFileManagerCreated() {}

  refresh() {
    this.fileManagerInstance.refresh()
  }

  docsIAIconHover(_event: Event, el: Element) {
    const tooltip = document.querySelector('.lf-custom-tooltip') as HTMLElement
    const elementCoords = el.getBoundingClientRect()
    if (!tooltip) {
      return
    }
    tooltip.style.left = `${elementCoords.left - 86}px`
    tooltip.style.top = `${elementCoords.top - 46}px`
    tooltip.style.display = 'block'
  }

  hideTooltip() {
    const tooltip = document.querySelector('.lf-custom-tooltip') as HTMLElement
    if (!tooltip) {
      return
    }
    tooltip.style.left = '0px'
    tooltip.style.top = '0px'
    tooltip.style.display = 'none'
  }

  addDocsIATooltipListeners() {
    const icons = this.$el.querySelectorAll('.docs-ia-icon')
    icons.forEach((el) => {
      el.addEventListener('mouseover', (event) => this.docsIAIconHover(event, el))
      el.addEventListener('mouseout', this.hideTooltip)
    })
  }

  mounted() {
    this.fileManagerInstance = (this as any).$refs.fileManager.ej2Instances
    this.initInterface()
    if (ContextName.EXPEDIENTS.toString() !== this.context) {
      this.hideStageField()
    }
    this.hideSpinnerLayerAction()
    this.permissionOnShortCuts()
  }

  permissionOnShortCuts() {
    const { canSave, canDelete } = this.checkEntityPermissionsGetter(this.permissionsEntity)
    if (canSave && canDelete) {
      return
    }

    // TODO: Revision 25.1.*
    // this.$refs.fileManager.$el.addEventListener('keydown', (args: KeyboardEvent) => {
    ;(this as any).$refs.fileManager.$el.addEventListener('keydown', (args: KeyboardEvent) => {
      const { ctrlKey, metaKey, code } = args
      const isCtrlKey = ctrlKey || metaKey ? true : code === 'ControlRight' || code === 'ControlLeft'

      const arrayKeyCodes = ['KeyV', 'KeyC', 'KeyX']
      if (!canSave && isCtrlKey && arrayKeyCodes.includes(code)) {
        // TODO: Revision 25.1.*
        // this.$refs.fileManager.clearSelection()
        ;(this as any).$refs.fileManager.clearSelection()
      }

      if (!canDelete && code === 'Delete') {
        // TODO: Revision 25.1.*
        // this.$refs.fileManager.clearSelection()
        ;(this as any).$refs.fileManager.clearSelection()
      }
    })
  }

  created() {
    if (Object.keys(this.$route.query).length > 0 && !this.$route.query.action) {
      if (this.$route.query.fileId) {
        this.openBlobFile(Number(this.$route.query.fileId), false)
      }

      if (this.initialPath && this.initialBasePath) {
        this.path = this.initialPath
      } else if (this.$route.query.path) {
        const path: string = this.$route.query.path.toString()
        this.path = atob(decodeURI(path))
      }

      if (this.$route.query.fileName) {
        this.filenameSelected = this.$route.query.fileName as string
        this.selectedItems.push(this.filenameSelected)
      }

      this.$router.replace({
        ...this.$router.currentRoute,
        params: this.$router.currentRoute.params,
        name: this.$router.currentRoute.name || '',
        query: {}
      })
    }

    this.fetchMaxFileUploadSize()
    this.fetchAllowedExtensions(this.mode)
  }

  beforeDestroy() {
    /* TODO- Este código lo comenté para solucionar la PLEXON-7192 el 18=05/23.
        Aparentemente no es necesario guardar al desmontar.
        ELiminar si finalmente QA no sacá ninguna incidencia */
    // this.saveListConfig()
    clearInterval(this.refreshTokenInterval)
    this.resetParentRegisterId()
    // remove EventListener for permissions
    this.$refs.fileManager.$el.removeEventListener('keydown', () => {})
  }

  saveListConfig() {
    setTimeout(async () => {
      const setting = this.setSortComparer(this.detailsViewSettings, null)
      await this.prepareListConfigToSave(setting)
    })
  }

  setSortAfterChangeValues(data: any) {
    if (typeof data.Config.columns !== 'undefined') {
      const userSort: any = data.Config.columns.find((item: any) => {
        return item.sortDirection
      })

      if (userSort) {
        this.sortBy = userSort.field
        this.sortOrder = userSort.sortDirection
      }
    }
  }

  prepareListConfigToSave(list: any) {
    const gridObj = this.fileManagerInstance.detailsviewModule.gridObj

    if (gridObj && list) {
      list.columns.map((column: any) => {
        if (column.field === gridObj.sortModule.lastSortedCol) {
          column.sortDirection = gridObj.sortModule.direction
        } else {
          delete column.sortDirection
        }
        const columnUser = gridObj.columnModel.find((item: any) => {
          return item.field === column.field
        })

        column.width = columnUser.width || 100
      })

      list.openMSOffice = this.openMSOfficeConfigMode

      // Actualiza el ancho de las columnas al guardar
      if (this.updatedHeaderColumns.length) {
        this.updatedHeaderColumns.forEach((column: HeaderColumn) => {
          if (column.index) {
            const adjustedIndex = column.index - 2
            list.columns[adjustedIndex].width = column.width
          }
        })

        this.updatedHeaderColumns = []
      }

      const dataToSend = {
        IdUser: this.idUser,
        Alias: this.listName,
        Config: list
      }
      this.setSortAfterChangeValues(dataToSend)
      this.saveListConfiguration(dataToSend)
    }
  }

  configSafari() {
    if (isSafari()) {
      this.detailsViewSettings!.columns!.map((column: any) => {
        if ('dateCreated' === column.field || 'dateModified' === column.field) {
          column.type = ''
        }
      })
    }
  }

  hideStageField() {
    const stageField = this.$el.querySelector('#idStage') as HTMLElement | null

    if (null !== stageField) {
      stageField.style.display = 'none'
    }
  }

  async onShowForm() {
    await this.$nextTick()
    this.toggleStageField()
  }

  toggleStageField() {
    if (!this.showDocumentDetail) {
      return true
    }

    if (ContextName.EXPEDIENTS.toString() !== this.context) {
      this.hideStageField()
      return true
    }

    const stageField = this.$el.querySelector('#idStage') as HTMLElement | null

    if (null !== stageField) {
      if (this.expedientHasStagesGetter) {
        stageField.style.display = 'inherit'
      } else {
        stageField.style.display = 'none'
      }
    }
  }

  onDocumentInitForm(formData: any) {
    const isNew = !this.idDocument

    if (isNew) {
      formData[fileManagerFields.STAGE] = this.idStage ? this.idStage : null
    }
  }

  onCancelUploadFolders() {
    this.showSuccessAlert(fileManagerOpers.CANCEL_UPLOAD_FOLDERS, 0)
  }

  onFoldersUploadOk(resume: any) {
    const allFolders: boolean = resume.hasFolders && !resume.hasFiles
    const foldersPrefixText: string = allFolders ? '_folder' : ''
    const itemsLength: number = resume.items.length
    const multipleFilesPrefixText: string = itemsLength === 1 ? '' : '_plural'
    const dialogSuccessText: string = `components.file_manager.success_messages.finish_upload${foldersPrefixText}${multipleFilesPrefixText}`
    const docsString: string = itemsLength === 1 ? resume.items[0] : itemsLength.toString()

    const data: FinishUploadData = {
      docsString,
      dialogSuccessText
    }
    this.showSuccessAlert(fileManagerOpers.FINISH_UPLOAD_FOLDERS, itemsLength, data)
  }

  onDropToZipNotSupported() {
    this.showDialog({
      type: DialogTypes.WARNING,
      message: this.$t('components.file_manager.error_messages.cant_upload_from_zip'),
      hideSecondaryButton: true
    })
    this.fileManagerInstance.refresh()
  }

  downloadAsZipPrompt(itemSel: any) {
    this.openedSaveAsZipDialog = true
    this.zipFolderSelected = [itemSel]
  }

  executeSaveAsZipDialogAction(actionName: string) {
    switch (actionName) {
      case ActionName.CLOSE:
        return (this.openedSaveAsZipDialog = false)
      case ActionName.SAVE:
        return this.saveAsZip()
    }
  }

  async saveAsZip() {
    const name = (this.zipFormData as any).name

    this.openedSaveAsZipDialog = false

    this.showFileManagerSpinner(this.spinnerText.download)

    await this.downloadZipResult({
      files: JSON.stringify(
        this.fileManagerInstance.getSelectedFiles().length > 0
          ? this.fileManagerInstance.getSelectedFiles()
          : this.zipFolderSelected
      ),
      name
    })

    if (this.zipResult && this.zipResult.zip!.length) {
      await this.downloadAsZip(this.zipResult.zip)

      this.hideFileManagerSpinner()

      let downloadFileName = entityToHtml(name)
      downloadFileName = `${downloadFileName}.zip`
      const blobUrl = URL.createObjectURL(this.blobFile)
      createLink(blobUrl, downloadFileName)
    } else {
      this.hideFileManagerSpinner()
    }
    this.setDownloadZipResultMessage(this.zipResult)
  }

  setDownloadZipResultMessage(zipResult: DownloadZipResult | null) {
    if (!zipResult || !zipResult.zip || zipResult.zip === '') {
      this.showErrorAlert(fileManagerOpers.DOWNLOAD_AS_ZIP, 0)
      return
    }

    if (zipResult && 0 === zipResult.wrongFiles.length) {
      this.showSuccessAlert(fileManagerOpers.DOWNLOAD_AS_ZIP, 0)
      return
    }

    if (zipResult && zipResult.wrongFiles.length) {
      let wrongFiles = '<ul class="zip-wrong-files">'
      zipResult.wrongFiles.map((file: string) => {
        wrongFiles += `<li>${file}</li>`
      })
      wrongFiles += '</ul>'

      this.showDialog({
        type: DialogTypes.WARNING,
        message: this.$t('components.file_manager.error_messages.download_zip_errors', { wrongFiles }),
        hideSecondaryButton: true
      })
    }
  }

  closeTemplateDialog() {
    this.showTemplateDialog = false
  }

  async generateTemplateDialog(templateSelected: any) {
    const filter = {
      templateName: templateSelected.templateName,
      idEntityOrigin: templateSelected.idEntity,
      name: templateSelected.name,
      folderTargetPath: this.currentBasePath,
      idEntity: this.idEntity,
      idEntityType: this.idEntityType,
      ...(this.idStage && { idStage: this.idStage })
    }
    const data: any = await this.generateTemplate(filter)
    this.showTemplateDialog = false
    this.refresh()
    if (data && data.id !== null) {
      this.showAlertResult(this.$t('components.file_manager.advises.template_created'), AlertsTypes.SUCCESS)
    } else {
      this.showAlertResult(this.$t('components.file_manager.advises.template_error'), AlertsTypes.ERROR)
    }
  }

  async downloadAsPdf(file: any) {
    this.showFileManagerSpinner(this.spinnerText.receiving)

    await this.downloadFile(file.id)

    if (!this.blobFile) {
      this.hideFileManagerSpinner()
      this.showErrorAlert(fileManagerOpers.CONVERT_TO_PDF, 0)
      return
    }
    const { data } = await new SyncfusionService().exportToPdf(this.blobFile)
    file = this.setupDocumentToInsert(file)
    const blobUrl = URL.createObjectURL(data)
    createLink(blobUrl, file.ArchivoInicial, true)
    await this.insertDocument({
      document: JSON.stringify(file),
      fileData: data
    })
    await this.$nextTick()
    this.fileManagerInstance.refresh()
    this.hideFileManagerSpinner()
  }
  setupDocumentToInsert(file: any): any {
    file.ArchivoInicial = `${file.originalFileName.substr(0, file.originalFileName.lastIndexOf('.'))}.pdf`
    const pos = file.name.lastIndexOf('.')
    file.name = `${file.name.substr(0, pos < 0 ? file.name.length : pos)}.pdf`
    file.fileName = `${file.title} (PDF)`
    file.basePath = this.currentBasePath
    file.id = 0
    return file
  }
  isTransformableToPdf(file: any) {
    return process.env.VUE_APP_SYNCFUSION_PDF_CONVERT_VALID_EXTENSIONS.includes(file.type.toString().toLowerCase())
  }
  isMSOfficeFile(file: any) {
    const msOfficeExtensions = ['.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx']
    return msOfficeExtensions.includes(file.type)
  }
  isDocsIAFile(fileType: string) {
    const docsIAExtensions = ['.pdf', '.doc', '.docx', '.rtf', '.odt', '.jpg', '.jpeg', '.png', '.txt', '.eml', '.msg']
    return docsIAExtensions.includes(fileType)
  }
  showFileManagerSpinner(text: TranslateResult) {
    this.spinnerText.actual = text
    this.fileManagerSpinner = true
  }
  hideFileManagerSpinner() {
    this.fileManagerSpinner = false
  }
  hideOverlay() {
    const dragOverElement = this.fileManagerInstance.element.querySelector('.e-upload-drag-hover')
    if (dragOverElement) {
      dragOverElement.classList.remove('e-upload-drag-hover')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@syncfusion/ej2-base/styles/material.css';
@import '~@syncfusion/ej2-icons/styles/material.css';
@import '~@syncfusion/ej2-inputs/styles/material.css';
@import '~@syncfusion/ej2-popups/styles/material.css';
@import '~@syncfusion/ej2-buttons/styles/material.css';
@import '~@syncfusion/ej2-splitbuttons/styles/material.css';
@import '~@syncfusion/ej2-layouts/styles/material.css';
@import '~@syncfusion/ej2-grids/styles/material.css';
@import '~@syncfusion/ej2-vue-filemanager/styles/material.css';
@import '~@syncfusion/ej2-pdfviewer/styles/material.css';
@import '~@/styles/partials/components/filemanager/main';

@include public;
</style>

<style lang="scss">
@import '~@/styles/partials/components/filemanager/menu';
</style>
