<template>
  <section :class="['cookies-policy-container']">
    <LfHeaderTitleComponent
        :title="title"
        class="header-title"
    />
    <div id="cookies-policy"></div>
  </section>
</template>

<script lang="ts" setup>
import { addBodyJS } from '@/helpers/html'
import LfHeaderTitleComponent from '@/components/HeaderTitle/LfHeaderTitleComponent.vue'
import { onMounted, onBeforeMount } from 'vue'
import { URLS } from '@/router/routes/urlRoutes'
import { useI18n } from 'vue-i18n-composable'
import useRouter from '@/composables/useRouter'

const { t } = useI18n()

// DEFINE CONST  ======================================================================================================
const title = t('views.login.footer.cookies_policy').toString()

// HOOKS ==============================================================================================================
onMounted(() => {
  addBodyJS(process.env.VUE_APP_COOKIEBOT_URL_TEXTS, '#cookies-policy', {
    id: 'CookieDeclaration',
    'type': 'text/javascript',
    async: true
  })
})

onBeforeMount(() =>{
  const router = useRouter()

  if (!process.env.VUE_APP_COOKIEBOT_ID) {
    router.goToRoute({
      name: URLS.NOT_FOUND_PAGE
    })
  }
})
</script>

<style lang="scss" scoped>
.cookies-policy-container {
  width: 100%;
  height: 100%;
  padding: 167px 30px 15px;

  #cookies-policy {
    min-width: 100%;
  }
}

@include tabletAndMore {
  .cookies-policy-container {
    padding-top: $app-bar-height + 14px;
  }
}

@include desktopLarge {
  .cookies-policy-container {
    grid-template-columns: 49% 49%;
  }
}
</style>
