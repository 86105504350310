<template>
  <div class="duplicate-expedient-container">
    <!-- HEADER -->
    <CustomDialogHeaderComponent :headerTitle="texts.title" />

    <!-- FIELDS -->
    <div class="buttons-container">
      <LexonTextFieldComponent
        v-model="expedientName"
        :label="texts.nameLabel"
        id="name"
        name="name"
        validationRules="required"
      />
      <LexonAutocompleteComponent
        id="sections"
        name="sections"
        v-model="selectedSections"
        :propItems="sections"
        :label="texts.sectionsLabel"
        multiple
        chips
        hasRelatedField
        itemName="name"
        itemValue="id"
        validationRules="required"
        :showSelectAll="true"
        :selectAllText="texts.allSections"
      />
    </div>

    <!-- BUTTONS -->
    <CustomDialogActionButtonsComponent :disabledButtons="disabledActionButton" :mainAction="duplicateExpedient" />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'
import store from '@/store/store'
import { useI18n } from 'vue-i18n-composable'
import CustomDialogActionButtonsComponent from '@/components/Dialog/CustomDialog/CustomDialogActionButtonsComponent.vue'
import CustomDialogHeaderComponent from '@/components/Dialog/CustomDialog/CustomDialogHeaderComponent.vue'
import LexonAutocompleteComponent from '@/components/forms/fields/LexonAutocomplete/LexonAutocompleteComponent.vue'
import LexonTextFieldComponent from '@/components/forms/fields/LexonTextField/LexonTextFieldComponent.vue'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'

const { t } = useI18n()

const props = defineProps<{
  componentProps: { id: string; name: string }
}>()

const expedientName = ref(`DUPLICADO - ${props.componentProps.name}`)
const selectedSections = ref([])

const texts = {
  allSections: t('action_buttons.select_all'),
  explanation: t('components.duplicate_expedient.explanation'),
  nameLabel: t('components.duplicate_expedient.name_label'),
  sectionsLabel: t('components.duplicate_expedient.sections_label'),
  title: t('components.duplicate_expedient.title')
}

const sections = computed(() => store.getters[`${ModuleNamespaces.EXPEDIENTS}/getExpedientSectionsToDuplicate`])

const disabledActionButton = computed(() => !selectedSections.value.length || !expedientName.value)

onMounted(() => {
  expedientName.value = `DUPLICADO - ${props.componentProps.name}`
})

const duplicateExpedient = () => {
  const expedientData = {
    id: props.componentProps.id,
    name: expedientName.value,
    sections: selectedSections.value,
    originalName: props.componentProps.name
  }
  store.dispatch(`${ModuleNamespaces.DIALOG}/closeCustomDialog`)
  store.dispatch(`${ModuleNamespaces.EXPEDIENTS}/duplicateExpedient`, expedientData)
}
</script>

<style lang="scss" scoped>
.duplicate-expedient-container {
  width: 100%;
  .explanation {
    font-family: $corporate-font-medium;
    font-size: 20px;
    color: $blue-04;
    text-align: center;
    margin-top: 70px;
  }

  .buttons-container {
    display: block;
    margin-bottom: 10px;

    #sections {
      ::v-deep .v-menu__content.menuable__content__active {
        overflow: hidden;
        padding-bottom: 10px;
      }
    }
  }
}
</style>
