<template lang="pug">
  div(v-show="showGrid"
      :class="['grid-container', { 'is-portal': isPortalUser , 'is-in-tab': isInTab }]")
    header(v-if="showHeader")
      AlertComponent(
        :whereIsRendered="componentWhereIsRenderedAlertComponent"
      )
      LfHeaderTitleComponent(v-if="title" :title="title" class="header-title")
      template(v-if="totals && totals.length")
        v-spacer
        ShowOrHideTotalsButtonComponent(
          :showTotals="showTotals"
          @toggle-totals="toggleTotals"
        )

    div(class="top-slot")
      slot(name="top")
        TotalsContainerComponent(
          v-if="totals && totals.length && showTotals"
          :items="totals"
        )

    ejs-grid(
      ref="grid"
      :class="['grid-table', checkIfToolbarIsHidden, checkIfClickCellDisabled]"
      v-bind="gridProps"
    )
      e-columns
        e-column(
          v-for="(column, index) in parsedColumns"
          :clipMode="getClipMode(column)"
          :key="`column-${index}`"
          :allowFiltering="column.allowFiltering"
          :allowReordering="column.allowReordering"
          :allowResizing="column.allowResizing"
          :allowSorting="column.allowSorting"
          :field="column.field"
          :isPrimaryKey="column.isPrimaryKey ? true : false"
          :filter="parsedFilter(column)"
          :filterModule="column.filterModule"
          :foreignKeyField="column.foreignKeyField"
          :format="column.format"
          :headerTextAlign="column.headerTextAlign"
          :headerText="column.headerText ? column.headerText : column.columnHeader"
          :minWidth="column.minWidth"
          :showInColumnChooser="column.showInColumnChooser"
          :template="getTemplate(column)"
          :customAttributes="getCustomAttributes(column.customAttributes)"
          :type="column.type"
          :visible="column.visible"
          :width="column.width"
          :textAlign="column.textAlign"
          :maxWidth="column.maxWidth"
          :freeze="column.freeze ? column.freeze : null"
          :commands="column.commands"
        )

    ejs-contextmenu(
      id="contextMenuComponent"
      ref="contextMenuComponent"
      class="context-menu-component"
      :items="renderedContextMenuItems"
      :select="contextMenuClickedItem"
      :beforeOpen="onContextMenuBeforeOpen"
    )

    ejs-contextmenu(
      id="customToolbarContextMenuComponent"
      ref="customToolbarContextMenuComponent"
      class="custom-toolbar-context-menu-component"
      :items="customToolbarContextMenuItemsParsed"
      :select="customToolbarContextMenuClickedItem"
    )

    OpenFileComponent(
      ref="openFileComponent"
    )
  </template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import AlertComponent from '@/components/Alert/AlertComponent.vue'
import GridTablesMixin from '@/mixins/GridTablesMixin.vue'
import TotalsContainerComponent from '@/components/Totals/TotalsContainerComponent.vue'
import LfHeaderTitleComponent from '@/components/HeaderTitle/LfHeaderTitleComponent.vue'
import ShowOrHideTotalsButtonComponent from '@/components/Totals/ShowOrHideTotalsButtonComponent.vue'
import { Icons } from '@/icons/icons'
import { URLS } from '@/router/routes/urlRoutes'
import { ContextMenuItem } from '@/store/modules/contextMenu/contextMenuTypes'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Action, Getter } from 'vuex-class'
import { DialogTypes, CustomDialogComponentName } from '@/store/modules/dialog/dialogTypes'
import { ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { ParentCategory } from '@/store/modules/menus/menusTypes'
import { LocaleMessage } from 'vue-i18n'
import { i18n } from '@/plugins/vue-i18n'
import { addTimeToDate } from '@/helpers/dateTime'
import { FilterColumns, SearchSettings } from '@/components/grids/GridTable/types/GridTableTypes'
import { DropDownListPlugin, MultiSelectPlugin } from '@syncfusion/ej2-vue-dropdowns'
import { MultiSelect, CheckBoxSelection } from '@syncfusion/ej2-dropdowns'
import { goToDocumentRoute } from '@/helpers/file'
import { DateRangePickerPlugin } from '@syncfusion/ej2-vue-calendars'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import OpenFileComponent from '@/components/FileManager/OpenFileComponent.vue'
import { DocumentPathInfo } from '@/store/modules/fileManager/fileManagerTypes'
import { createEntityByType } from '@/store/modules/entities/entitiesActions'
import { Entity, entity } from '@/store/modules/entities/entitiesTypes'
import { openLefebvrePdfViewer } from '@/helpers/pdfViewer'
import {
  getCustomFiler,
  getCustomMultiSelectFilter,
  getCustomFilerDuration,
  getCustomFilterDate,
  getCustomBooleanFilter,
  setLastFilters,
  removeLastFilters,
  dateValue,
  setDateValue,
  parsedFilterSettingsFlag,
  setParsedFilterSettingsFlag
} from '@/components/grids/CustomFilters'
import useGridSaveUserConf from '@/composables/useGridSaveUserConf'
import { ActionName, ColumnModel, CommandClickEventArgs, ItemModel } from '../LfGrid/LfGridTypes'
import useContacts from '@/views/contacts/composables/useContacts'
import { MenuEventArgs } from '@syncfusion/ej2-vue-navigations'
import { ClipMode, ColumnChooserSettingsModel, DataStateChangeEventArgs } from '@syncfusion/ej2-vue-grids'
import { OpenMSOfficeFileData } from '@/components/FileManager/types/FileManagerTypes'

Vue.use(DateRangePickerPlugin)
Vue.use(DropDownListPlugin)
MultiSelect.Inject(CheckBoxSelection)
Vue.use(MultiSelectPlugin)

const contextMenuModule = ModuleNamespaces.CONTEXT_MENU
const configurationModule = ModuleNamespaces.CONFIGURATION
const authModule = ModuleNamespaces.AUTH
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER
const formsModule = ModuleNamespaces.FORMS

const { getFilter } = useGridSaveUserConf()
const { openContact } = useContacts()

@Component({
  components: {
    AlertComponent,
    ShowOrHideTotalsButtonComponent,
    TotalsContainerComponent,
    OpenFileComponent,
    LfHeaderTitleComponent
  }
})
export default class GridTableComponent extends mixins(GridTablesMixin) {
  @Prop({
    type: [Array, Object],
    required: false
  })
  itemsDataExport!: object

  @Prop({
    type: Array,
    required: false
  })
  customToolbarContextMenuItems!: ContextMenuItem[]

  @Prop({
    type: Array
  })
  totals!: object[]

  @Prop({
    type: [Number, String]
  })
  archivedFilter!: number

  @Prop({
    type: String,
    required: true
  })
  gridConfiguration!: string

  @Prop({
    type: Boolean,
    default: true
  })
  showHeader!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  showColumnChooser!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  allowGrouping!: boolean

  @Prop({
    type: String,
    required: true
  })
  listName!: string

  @Prop({
    type: Boolean,
    default: false
  })
  usePersistSelection!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  useServerPagination!: boolean

  @Prop({
    type: String,
    required: true
  })
  context!: string

  @Prop({
    type: String
  })
  maintenanceType!: string

  @Prop({
    type: Boolean,
    default: true
  })
  showGrid!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  haveRelatedForm!: boolean

  @Prop({
    type: String
  })
  onClick!: string

  @Prop({
    type: Boolean,
    default: false
  })
  disabledContextMenuBeforeOpen!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  disabledGridCellClicked!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  saveOnClickedCell!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  checkboxSelectColumn!: boolean

  @Prop({
    type: Function
  })
  commandClick!: (args: CommandClickEventArgs) => void

  @Prop({
    type: Object,
    default: () => ({
      ignoreAccent: true,
      operator: 'contains'
    })
  })
  columnChooserSettings!: ColumnChooserSettingsModel

  @Getter('getUserId', { namespace: authModule }) userId: string

  @Getter('getRememberUserCustomConfigurationList', { namespace: configurationModule })
  rememberUserConfig: boolean

  @Getter('getLastListConfiguration', { namespace: configurationModule })
  lastListConfiguration: object

  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterIdFromModule: (context: string) => any

  @Getter('showRelatedForm', { namespace: formsModule }) showRelatedForm: (context: string) => boolean

  @Action('setItemsDependingOnWhichGridTableWeWantToShow', { namespace: contextMenuModule })
  setContextMenuItems: ([]) => void

  @Action('removeRegister', { namespace: selectedRegisterModule }) removeRegisterAction: ({}) => void

  @Action('saveUserCustomConfigurationList', { namespace: configurationModule })
  saveListConfiguration: ({}) => void

  @Action('saveSelectedRegisterId', { namespace: selectedRegisterModule })
  saveSelectedRegisterId: ({}) => void

  // TODO - Eliminar y tipar en grid tables mixin
  // $refs!: {
  //   grid: any,
  //   contextMenuComponent: any,
  //   customToolbarContextMenuComponent: any,
  //   openFileComponent: any
  // }

  gridInstance: any = null
  contextMenuComponent: any = null
  customToolbarContextMenuComponent: any = null

  showTotals = true

  enableLazyLoading = false

  enableStickyHeader = false

  componentWhereIsRenderedAlertComponent = ComponentWhereIsRendered.GRID_TABLE

  dataIsGrouped = false

  searchingText = false

  icon: string = Icons.SEARCH

  searchSettings: SearchSettings = {
    ignoreAccent: true,
    key: ''
  }

  searchButton: HTMLElement | null = null

  searchField: HTMLInputElement | null = null

  cleanInputSearchFieldIcon: HTMLInputElement | null = null

  filterButtonActive = false

  lastState: any = {
    skip: 0,
    take: this.parsedPagination.pageSize,
    sorted: this.parsedSortSettings.columns
  }
  customContextMenuItems = null

  @Watch('lastListConfiguration')
  lastListConfigurationChange(data: any) {
    if (data.filterSettings) {
      setLastFilters(data.filterSettings)
    }
  }

  @Watch('filterButtonActive')
  onFilterChange() {
    const filterBtn = this.$el.querySelector('.lf-clean-filter-button')
    if (filterBtn) {
      if (this.filterButtonActive) {
        filterBtn.classList.add('active')
      } else {
        filterBtn.classList.remove('active')
      }
    }
  }

  @Watch('dataIsGrouped')
  async disableOrEnableToolbarItems() {
    await this.$nextTick()
    const toolbarModule = (this as any).$refs.grid.ej2Instances.toolbarModule
    if (this.dataIsGrouped) {
      toolbarModule.enableItems(['print', 'export'], false)
    } else {
      toolbarModule.enableItems(['print', 'export'], true)
    }
    setTimeout(() => {
      this.createFilterTooltips()
    }, 200)
  }

  @Watch('itemsDataExport')
  exportExcelServerSide() {
    const grid = this.gridElement
    const title = this.excelExportedFileName || this.title
    const excelExportProperties = {
      fileName: `${title}.xlsx`,
      dataSource: this.itemsDataExport
    }
    this.showActionsColumn(this.showFirstColumn)
    grid.excelExport(excelExportProperties)
  }

  @Emit('gridTableFilter')
  filterDataEvent() {
    const search = this.gridInstance?.searchSettings?.key
    const filter = this.getFilterOptions()
    return { filter, search }
  }

  @Emit('rectifyCustomerInvoiceEvent')
  emitRectifyCustomerInvoiceEvent() {
    return this.selectedRegister.id
  }

  get persistSelection() {
    return this.usePersistSelection || !this.useServerPagination
  }

  get selectionOptions() {
    if (this.checkboxSelectColumn) {
      return {
        persistSelection: this.persistSelection,
        enableToggle: true,
        checkboxOnly: true
      }
    }
    return {}
  }

  get temporaryRouteName() {
    return [
      URLS.CUSTOMER_BAKDRAFTS,
      URLS.CUSTOMER_INVOICES,
      URLS.EXPEDIENTS,
      URLS.MAINTENANCE,
      URLS.MAINTENANCE_ACTIONS,
      URLS.MAINTENANCE_CONTACTS,
      URLS.MAINTENANCE_DOCUMENTS,
      URLS.MAINTENANCE_EXPEDIENTS,
      URLS.MAINTENANCE_INVOICES,
      URLS.ORDER_FORMS,
      URLS.OTHERS,
      URLS.OWN_ATTORNEYS,
      URLS.OWN_LAWYERS,
      URLS.PROFORMA_INVOICES,
      URLS.PROVIDERS,
      URLS.PROVIDER_BANKDRAFTS,
      URLS.PROVIDER_INVOICES,
      URLS.PROVISIONS,
      URLS.REPORTS,
      URLS.SEARCH
    ].includes(this.$route.name!)
  }

  get isExpedientDetailView() {
    return this.$route.name === `${URLS.EXPEDIENTS}-${URLS.EXPEDIENTS}`
  }

  get isExpedientView() {
    return this.$route.name === `${URLS.EXPEDIENTS}`
  }

  get menuName() {
    return this.$route.path.split('/')[1]
  }

  get gridConf() {
    if (this.gridConfiguration) {
      return JSON.parse(this.gridConfiguration)
    }
    return {
      pageSettings: {}
    }
  }

  get parsedColumns() {
    return this.gridConf.columns
  }

  get parsedPagination() {
    const pageSettings = this.gridConf.pageSettings
    if (!this.rememberUserConfig) {
      pageSettings.currentPage = 1
    }
    return pageSettings
  }

  get parsedSortSettings() {
    return this.gridConf.sortSettings ? { columns: this.gridConf.sortSettings } : {}
  }

  get parsedSearchSettings() {
    if (this.gridConf.searchSettings && this.rememberUserConfig) {
      this.searchSettings.key = this.gridConf.searchSettings
    }
    return this.searchSettings
  }

  get parsedFilterSettings() {
    const filterSettingUser = this.gridConf.filterSettings

    if (filterSettingUser && filterSettingUser.length > 0 && this.rememberUserConfig) {
      const newFilter: any = []
      filterSettingUser.forEach((item: any) => {
        const filter: FilterColumns = {
          field: item.field,
          operator: item.operator,
          predicate: item.predicate,
          value: item.value
        }

        newFilter.push(filter)
      })
      this.filterSettings.columns = newFilter
      setParsedFilterSettingsFlag(true)
      this.filterButtonActive = true
    }

    return this.filterSettings
  }

  get parsedGroupSettings() {
    if (!this.allowGrouping) {
      return null
    }

    return { enableLazyLoading: this.enableLazyLoading }
  }

  get selectedRegisterInfo() {
    let id = ''
    let name = ''
    let dialogText: LocaleMessage = ''

    switch (this.menuParentCategoryName) {
      case ParentCategory.CONTACTS:
        id = this.selectedRegisterId
        ;(name = this.selectedRegister.name), (dialogText = this.$t('components.dialog.contacts_literal'))
        break
      case ParentCategory.ACTIONS:
        id = this.selectedRegisterId
        name = this.selectedRegister.subject
        dialogText = this.$t('components.dialog.actions_literal')
        break
      case ParentCategory.EXPEDIENTS:
        id = this.selectedRegisterId
        name = this.selectedRegister.description
        dialogText = this.$t('components.dialog.expedients_literal')
        break
      case ParentCategory.REPORTS:
        id = this.selectedRegisterId
        name = this.selectedRegister.name
        dialogText = this.$t('components.dialog.reports_literal')
        break
      case ParentCategory.BILLING:
        id = this.selectedRegisterId
        name = this.selectedRegister.numberInvoice || this.selectedRegister.description
        dialogText = this.getInvoicesLiteralName
        break
      case ParentCategory.USERS:
        id = this.selectedRegisterId
        name = this.selectedRegister.name
        dialogText = i18n.t('components.dialog.users_literal')
        break
    }
    return {
      id,
      name,
      dialogText
    }
  }

  get getInvoicesLiteralName() {
    switch (this.listName) {
      case ListNames.CUSTOMER_BANKDRAFTS:
        return this.$t('components.dialog.customers_bankdraft_literal')
      case ListNames.ORDER_FORMS:
        return this.$t('components.dialog.order_form_literal')
      case ListNames.PROVIDER_BANKDRAFTS:
        return this.$t('components.dialog.providers_bankdraft_literal')
      case ListNames.PROVISIONS:
        return this.$t('components.dialog.provisions_literal')
      default:
        return this.$t('components.dialog.invoices_literal')
    }
  }

  get toolbarOptionsParsed() {
    const toolbarItems = this.toolbarOptions as ItemModel[]
    return this.toolbarOptionsFilterButtonCheck(toolbarItems)
  }

  toolbarOptionsFilterButtonCheck(toolbarItems: ItemModel[]) {
    return toolbarItems.map((item: ItemModel) => {
      if (item.id === ActionName.FILTER) {
        return {
          ...item,
          visible: this.hasFilters
        }
      }
      return item
    })
  }

  get hasFilters() {
    return Boolean(this.gridInstance?.filterSettings.columns.length)
  }

  get gridProps(): object {
    return {
      ...this.bothGridsSharedProps,
      actionBegin: this.actionBegin,
      actionComplete: this.gridActionComplete,
      actionFailure: this.gridActionFailure,
      allowGrouping: false, // this.allowGrouping,
      allowTextWrap: false,
      columnDrop: this.saveUserPreferences,
      contextMenuClick: this.contextMenuClickedItem,
      contextMenuOpen: this.onContextMenuBeforeOpen,
      dataStateChange: this.onDataStateChange,
      enableStickyHeader: this.enableStickyHeader,
      filterSettings: this.parsedFilterSettings,
      groupSettings: this.parsedGroupSettings,
      pageSettings: this.parsedPagination,
      recordClick: this.onCellClicked,
      refresh: this.refresh,
      resizeStop: this.saveUserPreferences,
      searchSettings: this.parsedSearchSettings,
      contextMenuItems: this.renderedContextMenuItems,
      showColumnChooser: this.showColumnChooser,
      sortSettings: this.parsedSortSettings,
      toolbar: this.toolbarOptions.length ? this.toolbarOptionsParsed : null,
      toolbarClick: this.clickHandler,
      dataBound: this.dataBound,
      selectionSettings: this.selectionOptions,
      columnChooserSettings: this.columnChooserSettings,
      commandClick: this.onCommandClick
    }
  }

  get selectedRegisterId() {
    const { actionId, id, idExpedient, idInvoice } = this.selectedRegister
    return actionId || id || idExpedient || idInvoice
  }

  get getSelectedEntityName() {
    switch (this.menuParentCategoryName) {
      case ParentCategory.ACTIONS:
        return 'action'
      case ParentCategory.BILLING:
        return this.listName
      default:
        return this.selectedEntityName
    }
  }

  get checkIfClickCellDisabled() {
    return this.disabledGridCellClicked ? 'cell-click-disabled' : ''
  }

  created() {
    this.setContextMenuItems(this.contextMenuItems)
    if (!this.rememberUserConfig) {
      removeLastFilters()
    }
  }

  beforeDestroy() {
    this.gridInstance.enableStickyHeader = false
    this.removeListeners()
  }

  mounted() {
    this.gridInstance = (this as any).$refs.grid.ej2Instances
    this.gridElement = this.$refs.grid
    this.contextMenuComponent = this.$refs.contextMenuComponent
    this.customToolbarContextMenuComponent = this.$refs.customToolbarContextMenuComponent

    this.setGroups()

    this.filterDataEvent()
    this.createFilterTooltips()

    setTimeout(() => {
      this.searchFieldBehaviours()
      this.showOrHideClearButton()
      this.enableStickyHeader = true
    })
  }

  updated() {
    setTimeout(() => {
      if (this.showGrid) {
        this.searchFieldBehaviours()
      }
    })

    if (!this.searchingText) {
      this.calculateScrollbarsWidth()
    }
  }

  refresh() {
    // TODO: Revision 25.1.*
    // // TODO: Revision 25.1.*
    // this.$refs.grid.refresh()
    ;(this as any).$refs.grid.refresh()
  }

  onDataStateChange(state: DataStateChangeEventArgs) {
    if (!this.useServerPagination) {
      return
    }
    const { action } = state as any
    if (action!.requestType === 'refresh' && parsedFilterSettingsFlag) {
      setParsedFilterSettingsFlag(false)
      return
    }

    if (action!.requestType === 'searching') {
      state.skip = 0
      this.gridInstance.pageSettings.currentPage = 1
    }

    if (action!.requestType === 'filtering') {
      state.skip = 0
      this.gridInstance.pageSettings.currentPage = 1
    }

    this.getServerSideData(state)
  }

  getServerSideData(state: any) {
    this.lastState = state
    const pFilter = getFilter(state, this.parsedColumns)
    this.$emit('gridActionChange', JSON.stringify(pFilter))
  }

  parsedFilter(columnConf: any) {
    switch (columnConf.filterModule) {
      case 'customFiler':
        return getCustomFiler()
      case 'customFilerDuration':
        return getCustomFilerDuration()
      case 'customMultiSelectFilter':
        return getCustomMultiSelectFilter(columnConf)
      case 'customFilterDate':
        return getCustomFilterDate(this.gridInstance, this.gridElement)
      case 'customBooleanFilter':
        return getCustomBooleanFilter(columnConf)
    }
    if (columnConf.isCheckboxType) {
      return this.checkboxFilter
    }
    return ''
  }

  toggleTotals() {
    this.showTotals = !this.showTotals
  }

  selectedRegisterRouteData(registerId: number | null = null) {
    const routeName = this.$route.name as string
    return {
      name: `${this.menuName}-${routeName}`,
      params: {
        selectedMenuEntity: this.menuName,
        submenuName: routeName,
        selectedRegisterId: registerId || (this.$t('views.selected_register.new') as string)
      }
    }
  }

  get customToolbarContextMenuItemsParsed() {
    return this.customContextMenuItems || this.customToolbarContextMenuItems
  }

  onCellClicked(args: any) {
    this.selectedRegister = args.rowData
    const currentEntity: Entity = this.getCurrentEntity()
    const columnsLength = this.gridInstance.columns.length

    if (columnsLength - 1 === args.cellIndex) {
      return
    }

    if (this.activeOnClickCell) {
      if (args && args.column.type === 'checkbox') {
        return
      }

      if ((this.isPortalUser && this.isInTab) || this.disabledGridCellClicked) {
        args.cancel = true
      }

      // if (!this.isPortalUser) {
      //   this.saveUserPreferences()
      // }

      if (this.onClick) {
        ;(this as any)[this.onClick]()
        return
      }

      this.$emit('cellClicked', this.selectedRegister, args.cancel)
      if (!args.cancel) {
        if (this.haveRelatedForm) {
          this.$emit('gridAction', {
            type: 'edit',
            register: this.selectedRegister,
            registerId: this.selectedRegisterId
          })
        } else if (
          this.selectedRegister.idEntityType === entity.appointments.type &&
          this.context !== ContextName.EXPEDIENT_AGENDA
        ) {
          const routeData = this.$router.resolve(currentEntity.getRoute())
          window.open(routeData.href, '_blank')
        } else if (this.selectedRegister.personTypeId) {
          openContact(this.selectedRegister.id)
        } else if (this.temporaryRouteName) {
          this.$router.push(currentEntity.getRoute())
        } else if (this.isExpedientDetailView) {
          const { id, idStage } = this.selectedRegister
          this.$emit('openExpedientEconomicDataForm', { id, idStage })
        }
      }
    }
  }

  resizeStop() {
    this.saveUserPreferences()
    this.$nextTick(() => {
      this.hideScroll()
    })
  }

  getCurrentEntity(): Entity {
    const idEntityType: number = this.selectedRegister.idEntityType
      ? Number(this.selectedRegister.idEntityType)
      : this.$route.meta?.entityType
    return this.getEntity(idEntityType, Number(this.selectedRegisterId))
  }

  openCustomToolbarContextMenuComponent(toolBar: any) {
    const btn: null | HTMLElement = document.getElementById(toolBar.item.id)
    if (btn !== null) {
      const rect: ClientRect | DOMRect = btn.getBoundingClientRect()
      const top = rect.top + window.scrollY + 30
      const left = rect.left + 100
      this.customToolbarContextMenuComponent.open(top, left)
    }
  }

  onContextMenuBeforeOpen(args: any) {
    if ((this.isPortalUser && this.isInTab) || this.disabledContextMenuBeforeOpen) {
      args.cancel = true
    }
    // not open contextMenu when click outside a row
    if (args.column === null && args.parentItem === null) {
      args.cancel = true
      return
    }

    // not open contextMenu when click header grid
    if (args.rowInfo && !args.rowInfo.row) {
      args.cancel = true
      return
    }

    const contextMenuGridObject = this.gridInstance.contextMenuModule.contextMenu

    this.$emit('contextMenuBeforeOpen', this.contextMenuComponent, contextMenuGridObject, this.selectedRegister)

    if (args.cancel) {
      return
    }

    this.saveSelectedRegisterId({
      id: this.selectedRegisterId,
      context: this.context
    })

    const { idEntityType, idType } = this.selectedRegister
    const { items } = args
    const contextMenuItems = idEntityType ? this.parsedItemsByPermissions(idEntityType, items, this.context) : items

    if (idType) {
      const canDeleteReport = idType === '0' ? false : true
      this.contextMenuComponent.enableItems([this.$t('components.context_menu.remove')], !!canDeleteReport)
      contextMenuGridObject.enableItems([this.$t('components.context_menu.remove')], !!canDeleteReport)
    }

    this.contextMenuComponent.enableItems(contextMenuItems, false)
    contextMenuGridObject.enableItems(contextMenuItems, false)
  }

  actionBegin(e: any) {
    const savePreferencesRequest = ['searching', 'filtering', 'sorting', 'grouping']
    if (savePreferencesRequest.includes(e.requestType)) {
      this.saveUserPreferences()
    }

    const actions = ['searching', 'filtering', 'grouping', 'refresh']

    if ('clearFilter' === e.action && 'customFilterDate' === e.currentFilterColumn.filterModule) {
      delete dateValue[e.currentFilterColumn.field]
    }

    if (actions.includes(e.requestType)) {
      if (e.action === 'filter' || (e.requestType === 'refresh' && parsedFilterSettingsFlag)) {
        this.filterButtonActive = true
      } else if (e.action === 'clearFilter' && this.gridInstance.filterSettings.columns.length === 0) {
        this.filterButtonActive = false
      }
      this.gridInstance.pageSettings.currentPage = 1
    }

    if (e.requestType === 'filtersearchbegin') {
      this.hideAddCurrentSelectionToFilterOption()
      e.operator = 'contains'
    }

    if (e.requestType === 'grouping') {
      this.frozenCols = 0
      this.enableLazyLoading = true
      this.enableStickyHeader = false
      this.dataIsGrouped = true
    }

    if (e.requestType === 'searching') {
      this.filterDataEvent()
    }

    if (e.requestType === 'filtering') {
      const columnFilter = this.gridInstance
        .getColumns()
        .find((item: any) => item.field === e.currentFilteringColumn && item.filterModule === 'customFilterDate')

      if (columnFilter) {
        this.filterButtonActive = true

        const checkIfFilterExist = this.gridInstance.filterSettings.columns.find(
          (item: any) => item.field === e.currentFilteringColumn && 'lessthanorequal' === item.operator
        )

        if (!checkIfFilterExist) {
          e.columns.push({
            predicate: 'and',
            field: e.currentFilteringColumn,
            operator: 'lessthanorequal',
            value: addTimeToDate(
              dateValue[e.currentFilteringColumn][1],
              1440 + new Date().getTimezoneOffset(),
              'minutes'
            )
          })
        }
      }

      this.filterDataEvent()
    }
  }

  hideAddCurrentSelectionToFilterOption() {
    setTimeout(() => {
      const searchInputContainer = this.$el.querySelector('.e-searchcontainer')
      if (searchInputContainer) {
        const filterOptionContainer = searchInputContainer.querySelectorAll('.e-ftrchk')
        filterOptionContainer.forEach((option) => {
          const optionText = option.querySelector('.e-checkboxfiltertext')
          if (optionText && optionText.textContent === 'Add current selection to filter') {
            ;(option as HTMLElement).style.display = 'none'
          }
        })
      }
    }, 2)
  }

  getTemplate(column: any) {
    const { templateName } = column
    return templateName ? (this as any)[templateName] : false
  }

  gridActionComplete(args: { requestType: string; rows: string | any[]; searchString: string; tableName: string }) {
    const gridActions = ['paging', 'sorting', 'columnstate', 'ungrouping']

    if (args.requestType === 'ungrouping') {
      const agrupationChips = this.$el.querySelectorAll('.e-groupheadercell')

      if (!agrupationChips.length) {
        this.gridInstance.columns.map((column: any, index: number) => {
          if (0 === index) {
            column.visible = true
            Vue.set(this.gridInstance.columns, index, column)
          }
        })
        this.enableLazyLoading = false
        this.dataIsGrouped = false
        setTimeout(() => {
          this.frozenCols = 1
          this.enableStickyHeader = true
        }, 200)
      }
    }

    if (args.requestType === 'grouping') {
      this.enableStickyHeader = true
    }

    if (gridActions.includes(args.requestType)) {
      this.saveUserPreferences()
    }

    if (args.requestType === 'columnstate') {
      this.hideScroll()
    }

    this.getTextColorInYesOrNoColumns()
    this.getTextColorInDateColumns()
    this.hideHeaderTitles()
    this.createFilterTooltips()
  }

  gridActionFailure() {
    this.enableStickyHeader = true
  }

  saveUserPreferences() {
    if (!this.saveOnClickedCell) {
      return false
    }

    this.$nextTick(() => {
      const gridColumns = this.gridInstance.columns

      gridColumns.forEach((column: any) => {
        if (column.template) {
          column.templateName = column.template().template.extendOptions.name
        }

        if (column.headerText) {
          column.columnHeader = column.headerText
        }

        column.filter === '' ? (column.isCheckboxType = false) : (column.isCheckboxType = true)

        const parsedColumn = this.parsedColumns.find((pColumn: any) => pColumn.field === column.field)

        if (parsedColumn) {
          column.filterBooleanItems = parsedColumn.filterBooleanItems
          column.filterModuleSource = parsedColumn.filterModuleSource
        }
      })

      const gridPageSettings = this.gridInstance.pageSettings.properties

      const sortOptions = []

      for (const column of this.gridInstance.properties.sortSettings.properties.columns) {
        if (column.properties) {
          sortOptions.push({ field: column.properties.field, direction: column.properties.direction })
        }
      }

      const searchKey = this.gridInstance.searchSettings.key

      const filterOptions: any = this.getFilterOptions()

      const groupSettings = {
        columns: [],
        enableLazyLoading: false
      }

      if (this.gridInstance.groupSettings.columns.length) {
        groupSettings.columns = this.gridInstance.groupSettings.columns
        groupSettings.enableLazyLoading = true
      }

      const gridConfig = {
        columns: gridColumns,
        pageSettings: gridPageSettings,
        sortSettings: sortOptions,
        searchSettings: searchKey,
        filterSettings: filterOptions,
        groupSettings
      }

      if (this.archivedFilter >= 0) {
        ;(gridConfig as any)['archived'] = this.archivedFilter
      }

      const dataToSend = {
        IdUser: this.userId,
        Alias: this.listName,
        Config: gridConfig
      }

      this.saveListConfiguration(dataToSend)
    })
  }

  getFilterOptions() {
    return this.gridInstance.filterSettings.columns.map(({ field, operator, predicate, value }: any) => {
      if (!field) {
        return
      }
      return {
        field,
        operator,
        predicate,
        value
      }
    })
  }

  removeListeners(): void {
    if (this.searchButton) {
      this.searchButton.removeEventListener('click', this.showOrHideClearButton)
    }

    if (this.searchField) {
      this.searchField.removeEventListener('keypress', this.searchOnEnterKeyBehaviour)
    }

    if (this.searchField) {
      this.searchField.removeEventListener('click', this.cleanInputSearchField)
    }
  }

  async removeRegister() {
    // TODO: Set component properties on router and call to new removeRegisterAction method (fetchRemoveRegister)
    const data: any = {
      parentCategory: this.menuParentCategoryName,
      selectedEntityName: this.getSelectedEntityName,
      idSelectedRegister: this.selectedRegisterInfo.id,
      goToURL: this.$route.name,
      name: this.selectedRegisterInfo.name,
      dialogText: this.selectedRegisterInfo.dialogText
    }
    if (this.maintenanceType) {
      data.selectedEntityName = this.maintenanceType
      data.idSelectedRegister = this.selectedRegisterIdFromModule(this.context)
    }

    await this.removeRegisterAction(data)
    if (this.useServerPagination) {
      this.lastState.take = this.gridInstance.pageSettings.pageSize
      this.getServerSideData(this.lastState)
    }
  }

  contextMenuClickedItem(args: any) {
    this.saveUserPreferences()
    this.$emit('contextMenuClicked', args, this.selectedRegister)
    if (args.cancel) {
      return
    }

    if (args.item) {
      const currentEntity: Entity = this.getCurrentEntity()
      switch (args.item.text) {
        case this.$t('components.context_menu.edit'):
        case this.$t('components.context_menu.look'):
          if (this.haveRelatedForm) {
            this.$emit('gridAction', { type: 'edit', register: this.selectedRegister })
          } else if (this.selectedRegister.idEntityType === entity.contacts.type) {
            openContact(this.selectedRegister.id)
          } else if (this.temporaryRouteName) {
            this.$router.push(currentEntity.getRoute())
          } else if (this.isExpedientDetailView) {
            const { id, idStage } = this.selectedRegister
            this.$emit('openExpedientEconomicDataForm', { id, idStage })
          }
          break
        case this.$t('components.context_menu.remove'):
          if (this.isExpedientDetailView) {
            this.$emit('showDialog', this.selectedRegisterId)
          } else if (this.haveRelatedForm) {
            this.$emit('gridAction', { type: 'remove', register: this.selectedRegister })
          } else {
            args.item.action({
              type: DialogTypes.INFO,
              message: this.$t('components.dialog.remove_register_text', {
                register: this.selectedRegisterInfo.name,
                text: this.isExpedientDetailView
                  ? this.$t('components.expedient_economic_data.economic_data')
                  : this.selectedRegisterInfo.dialogText
              }),
              action: this.removeRegister,
              mainButtonText: this.$t('action_buttons.remove'),
              secondaryButtonText: this.$t('action_buttons.cancel')
            })
          }
          break
        case this.$t('components.context_menu.open_window'):
          if (this.selectedRegister.idEntityType === entity.contacts.type) {
            openContact(this.selectedRegister.id, '_blank')
          } else if (this.selectedRegister.initialFile) {
            this.openFile()
          } else if (this.temporaryRouteName) {
            if (this.isPortalUser && this.context !== ContextName.EXPEDIENTS) {
              return
            }
            window.open(currentEntity.getRoute(), '_blank')
          }
          break
        case this.$t('components.context_menu.see'):
        case this.$t('components.context_menu.open'):
          this.openFile()
          break
        case this.$t('components.context_menu.download'):
          this.downloadFile()
          break
        case this.$t('components.context_menu.go_to'):
          this.goToDocumentRoute()
          break
        case this.$t('components.context_menu.rectify_customer_invoice'):
          args.item.action({
            type: DialogTypes.INFO,
            message: this.$t('components.dialog.rectify_customer_invoice_text', {
              register: this.selectedRegisterInfo.name
            }),
            action: this.emitRectifyCustomerInvoiceEvent
          })
          break
        case this.$t('action_buttons.duplicate_expedient'):
          args.item.action({
            name: CustomDialogComponentName.DUPLICATE_EXPEDIENT,
            props: {
              id: this.selectedRegisterId,
              name: this.selectedRegisterInfo.name
            }
          })
          break
        case this.$t('components.context_menu.see_details'):
          this.$emit('see-details', this.selectedRegister)
          break
        case this.$t('components.context_menu.download_original_document'):
          this.downloadFileById(this.selectedRegister.idDocumentOriginal)
          break
      }
    }
  }

  async goToDocumentRoute() {
    const pathInfo: DocumentPathInfo = await this.fetchDocumentPathInfoAction(this.selectedRegister.id)
    const route = goToDocumentRoute(this.selectedRegister, pathInfo) // helper/file
    window.open(route, '_blank')
  }

  openFile() {
    this.processDocument(this.selectedRegister)
  }

  downloadFile() {
    this.processDocument(this.selectedRegister, true)
  }

  downloadFileById(id: number) {
    this.selectedRegister.id = id
    this.processDocument(this.selectedRegister, true)
  }

  processDocument(document: any, download = false) {
    const file: OpenMSOfficeFileData = {
      id: document.id,
      name: document.name,
      originalFileName: document.initialFile
    }

    const openFileComponent: any = this.$refs.openFileComponent
    if (openFileComponent) {
      if (download) {
        openFileComponent.downloadFile(file)
      } else {
        if ((file as any).originalFileName.split('.').pop().toLowerCase() === 'pdf') {
          openLefebvrePdfViewer(file.id)
        } else {
          openFileComponent.openFileWithOffice(file)
        }
      }
    }
  }

  getEntity(entityType: number, entityId = 0): Entity {
    return createEntityByType(entityType, entityId)
  }

  customToolbarContextMenuClickedItem(args: MenuEventArgs) {
    const { id } = args.item
    if (id === ActionName.PRINT) {
      return this.exportToPDF()
    }
    if (id === ActionName.EXPORT) {
      return this.excelExport()
    }
    this.$emit('customToolbarContextMenuClick', args)
  }

  showOrHideClearButton(): void {
    const text: string | null = this.searchField ? this.searchField!.value : ''
    const hasText = text !== ''

    if (hasText) {
      this.searchingText = true
      this.gridElement.search(text)
    }
  }

  searchOnEnterKeyBehaviour(e: any): void {
    const key = e.which || e.keyCode
    const text: string | null = this.searchField!.value

    if (key === 13) {
      const hasText = text !== ''

      this.searchingText = hasText
      this.gridElement.search(text)
    }
  }

  addListenersToShowOrHideClearButton(): void {
    this.searchButton = this.$el.querySelector('.e-search .e-input-group-icon:not(.e-sicon)')
    this.searchField = this.$el.querySelector('.e-search .e-input')

    if (this.searchButton) {
      this.searchButton.addEventListener('click', this.showOrHideClearButton)
    }

    if (this.searchField) {
      this.searchField.addEventListener('keypress', this.searchOnEnterKeyBehaviour)
    }
  }

  addListenerToCleanSearchIcon(): void {
    this.cleanInputSearchFieldIcon = this.$el.querySelector('.e-sicon')

    if (this.cleanInputSearchFieldIcon) {
      this.cleanInputSearchFieldIcon.addEventListener('click', this.cleanInputSearchField)
    }
  }

  cleanInputSearchField() {
    if (this.searchField && this.searchField.value.length) {
      this.searchingText = false
      this.clearSearch()
      this.searchField.value = ''
      if (this.cleanInputSearchFieldIcon) {
        this.cleanInputSearchFieldIcon.classList.remove('e-clear-icon')
      }
    }
  }

  searchFieldBehaviours(): void {
    this.addListenersToShowOrHideClearButton()
    this.addListenerToCleanSearchIcon()
  }

  clearSearch(): void {
    this.$set(this.gridInstance.searchSettings, 'key', '')
  }

  checkFilter(): void {
    if (this.filterButtonActive) {
      setParsedFilterSettingsFlag(false)
      ;(this as any).$refs.grid.ej2Instances.clearFiltering()
      setDateValue([])
      this.filterButtonActive = false
      this.saveUserPreferences()
      // if (this.useServerPagination) {
      //   delete this.lastState.where
      //   this.getServerSideData(this.lastState)
      // }
      this.filterDataEvent()
    }
  }

  excelExport(): void {
    if (this.useServerPagination) {
      this.lastState.skip = 0
      this.lastState.take = 0
      const pFilter = getFilter(this.lastState, this.parsedColumns)
      this.$emit('gridExportData', JSON.stringify(pFilter))
    } else {
      const grid = this.gridElement
      const title = this.excelExportedFileName || this.title
      const excelExportProperties = {
        fileName: `${title}.xlsx`
      }

      this.showActionsColumn(this.showFirstColumn)
      grid.excelExport(excelExportProperties)
    }
  }

  goToTabsView(selectedRegisterId = 0) {
    const routeData = (this as any).selectedRegisterRouteData(selectedRegisterId)
    this.$router.push(routeData)
  }

  addRegister(): void {
    // Esta lógica es de forma temporal hasta que se quite joomla
    if (this.haveRelatedForm) {
      this.$emit('gridAction', { type: 'add' })
    } else if (this.temporaryRouteName) {
      this.goToTabsView()
    }
  }

  clickHandler(args: any) {
    const target = args.originalEvent.target.closest('button')

    this.$emit('toolbarClicked', args)

    if (args.cancel) {
      return
    }
    const { item } = args
    const { contextMenuItems } = item

    switch (target && target.id) {
      case 'customToolbarContextMenu':
        if (contextMenuItems) {
          this.customContextMenuItems = contextMenuItems
          let rect = target.getBoundingClientRect()
          this.customToolbarContextMenuComponent.open(rect.bottom, rect.right)
        }
        break
      case 'export':
        return this.excelExport()
      case 'print':
        return this.exportToPDF()
      case 'add':
        return this.addRegister()
      case 'customOption':
        return this.openCustomToolbarContextMenuComponent(args)
      case 'clear':
        return this.clearSearch()
      case 'filter':
        return this.checkFilter()
      default:
        return target ? this.$emit('otherGridButtonClicked', target.id) : false
    }
  }

  dataBound(_args: any) {
    this.hideScroll()
  }

  isGrouping() {
    const gridSettings = this.gridConf
    let isGrouping = gridSettings.groupSettings && gridSettings.groupSettings.columns.length

    if (this.gridInstance && this.gridInstance.groupSettings && !this.gridInstance.groupSettings.columns.length) {
      isGrouping = false
    }

    if (isGrouping) {
      this.frozenCols = 0
    }

    return isGrouping
  }

  setGroups() {
    const gridConfiguration = this.gridConf
    if (gridConfiguration.groupSettings && gridConfiguration.groupSettings.columns.length) {
      this.frozenCols = 0
      this.enableLazyLoading = true
      this.dataIsGrouped = true
      this.gridInstance.enableLazyLoading = true
      gridConfiguration.groupSettings.columns.forEach((columName: string) => {
        this.gridInstance.groupColumn(columName)
      })
    }
  }

  calculateScrollbarsWidth() {
    const contentTable = this.$el.querySelector('.e-movableheader .e-table') as HTMLElement
    if (contentTable) {
      const tableWidth = contentTable.style.width
      const frozenScrollBar = this.$el.querySelector('.e-frozenscrollbar') as HTMLElement
      const movableScrollBar = this.$el.querySelector('.e-movablechild') as HTMLElement
      if (frozenScrollBar) {
        frozenScrollBar.style.width = '25px'
      }
      if (movableScrollBar) {
        movableScrollBar.style.width = tableWidth
      }
    }
  }

  onCommandClick(args: CommandClickEventArgs) {
    const { commandColumn, target, rowData } = args
    if (commandColumn.id === ActionName.OPEN_KEBAK_MENU) {
      const id = rowData as any
      this.selectRowById(id)
      const { x, y } = target?.getClientRects()[0] as DOMRect
      this.contextMenuComponent.open(y, x)
      return
    }
    if (this.commandClick) {
      this.commandClick(args)
    }
  }

  selectRowById(id: number) {
    const index = this.parsedColumns.findIndex(({ editType }: any) => editType === 'booleanedit')
    if (index && index !== -1) {
      const index = this.gridElement.getRowIndexByPrimaryKey(id)
      this.gridElement.selectRow(index)
    }
  }

  getClipMode(column: ColumnModel): ClipMode | undefined {
    if (!column.headerText) {
      return
    }
    return 'EllipsisWithTooltip'
  }
}
</script>
<style scoped>
.header-title {
  margin-bottom: 21px;
}
</style>
<style lang="scss">
.multiselect-item > div {
  height: 15px;
  width: 15px;
  margin-right: 8px;
  border-radius: 4px;
}

.multiselect-item {
  display: inline-block;
  text-align: left;
  text-indent: 0px;
}

.multiselect-item > * {
  display: inline-block;
  vertical-align: middle;
}

.multiselect-item .item-text {
  @include ellipsis;
  width: 130px;

  &:first-child {
    width: 153px;
  }
}
</style>
