<template>
  <div class="duplicate-action-container">
    <!-- HEADER -->
    <CustomDialogHeaderComponent :headerTitle="texts.title" />

    <!-- FIELDS -->
    <div ref="nameInput" class="buttons-container">
      <LexonTextFieldComponent
        v-model="actionName"
        :label="texts.nameLabel"
        id="name"
        name="name"
        :validationRules="validationRules"
      />
      <LexonSwitchComponent
        class="switch-field"
        id="classification"
        name="classification"
        :label="texts.classificationLabel"
        v-model="classification"
      />
    </div>

    <!-- BUTTONS -->
    <CustomDialogActionButtonsComponent :disabledButtons="disabledActionButton" :mainAction="duplicateExpedient" />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'
import store from '@/store/store'
import { useI18n } from 'vue-i18n-composable'
import CustomDialogActionButtonsComponent from '@/components/Dialog/CustomDialog/CustomDialogActionButtonsComponent.vue'
import CustomDialogHeaderComponent from '@/components/Dialog/CustomDialog/CustomDialogHeaderComponent.vue'
import LexonSwitchComponent from '@/components/forms/fields/LexonSwitch/LexonSwitchComponent.vue'
import LexonTextFieldComponent from '@/components/forms/fields/LexonTextField/LexonTextFieldComponent.vue'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'

const { t } = useI18n()

const props = defineProps<{
  componentProps: { id: string; name: string }
}>()

const actionName = ref(`DUPLICADO - ${props.componentProps.name}`)
const classification = ref(false)
const maxChar = ref(400)
const validationRules = ref(`required|max:${maxChar.value}`)

const texts = {
  explanation: t('components.duplicate_action.explanation'),
  nameLabel: t('components.duplicate_action.name_label'),
  classificationLabel: t('components.duplicate_action.classification_label'),
  title: t('components.duplicate_action.title')
}

const disabledActionButton = computed(() => {
  return actionName.value && actionName.value.length > maxChar.value
})

onMounted(async () => {
  actionName.value = `DUPLICADO - ${props.componentProps.name}`
})

const duplicateExpedient = () => {
  const expedientData = {
    id: props.componentProps.id,
    name: actionName.value,
    classification: classification.value,
    originalName: props.componentProps.name
  }
  store.dispatch(`${ModuleNamespaces.DIALOG}/closeCustomDialog`)
  store.dispatch(`${ModuleNamespaces.ACTIONS}/duplicateAction`, expedientData)
}
</script>

<style lang="scss" scoped>
.duplicate-action-container {
  width: 100%;
  .explanation {
    font-family: $corporate-font-medium;
    font-size: 20px;
    color: $blue-04;
    text-align: center;
    margin-top: 70px;
  }

  .buttons-container {
    display: block;
    margin-bottom: 10px;

    .switch-field {
      ::v-deep .lf-switch-container {
        height: 20px;
      }
    }
  }
}
</style>
