<template lang="pug">
  span(:class="iconClass") {{data.type}}
</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator'
import { Component } from 'vue-property-decorator'
import { ActionTypes } from '@/store/modules/actions/actionsTypes'
import { Icons } from '@/icons/icons'

@Component({
  name: 'actionType'
})
export default class ActionTypeTemplateComponent extends Vue {
  data: any

  icons = {
    addNew: Icons.ADD,
    actionCall: Icons.CALL,
    actionsLexnet: Icons.LEXNET,
    actionMeeting: Icons.MEETING,
    actionProcedure: Icons.PROCEDURES,
    actionOther: Icons.OTHERS_F,
    actionEmail: Icons.EMAIL,
    actionTask: Icons.TASK,
    actionInternalManagement: Icons.INTERNAL_MANAGEMENT
  }

  get iconClass() {
    switch (this.data.typeId) {
      case ActionTypes.CALLS:
        return this.icons.actionCall
      case ActionTypes.LEXNET:
        return this.icons.actionsLexnet
      case ActionTypes.MEETINGS:
        return this.icons.actionMeeting
      case ActionTypes.PROCEDURES:
        return this.icons.actionProcedure
      case ActionTypes.OTHERS:
        return this.icons.actionOther
      case ActionTypes.EMAILS:
        return this.icons.actionEmail
      case ActionTypes.TASK:
        return this.icons.actionTask
      case ActionTypes.INTERNAL_MANAGEMENT:
        return this.icons.actionInternalManagement
    }
  }
}
</script>

<style lang="scss" scoped>
.e-templatecell {
  span {
    font-family: $corporate-font !important;
    font-size: 14px;
    vertical-align: middle;
    color: $gray-01;
    &:before {
      font-family: $lf-icons-font !important;
      font-size: $icon-size-dense;
      color: $gray-01;
      padding-right: 10px;
      vertical-align: text-bottom;
    }
  }
}
</style>
